import styled from "styled-components";
import { SubText } from "../styles";
import CountUp from "react-countup";
import { userContext } from "../context/userContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ChooseDepositAccountModal from "../pages/ChooseDepositAccountModal";

const AssetTable = ({ title, account, option }) => {
  const { currentPrices } = useContext(userContext);
  const navigate = useNavigate();
  const [depositModal, setDepositModal] = useState(false);
  return (
    <>
      <Wrapper key={title}>
        <ChooseDepositAccountModal open={{ depositModal, setDepositModal }} />
        <SubText className="top">{title}</SubText>
        <table className="desktopTable scrollbar-hidden">
          <thead className="desktopHead">
            <tr className="desktopRow">
              <th className="name">Asset</th>
              <th className="assetName">Name</th>
              <th className="mobileValue">Name</th>
              <th className="value">Value</th>
              <th className="estimate">Estimated</th>
              <th className="currentPrice">Current Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="desktopBody">
            {account.map(
              (account) =>
                account.value > 0 && (
                  <tr key={account.asset} className="desktopRow">
                    <td className="asset">
                      <span className="icon">
                        <img src={`/asseticons/${account.asset}.svg`} alt="" />
                      </span>
                      <p>{account.asset}</p>
                    </td>
                    <td className="assetName">
                      <p>{account.name}</p>
                    </td>

                    <td className="mobile">
                      <CountUp
                        end={currentPrices[account.asset] * account.value}
                        duration={1}
                        delay={0}
                        separator=","
                        prefix="$"
                        decimals="2"
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText bigText"
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                      <CountUp
                        end={account.value}
                        duration={1}
                        delay={0}
                        separator="."
                        decimals="2"
                        suffix={" " + account.asset}
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText  bigText"
                              style={{ color: "#a3a3a3" }}
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                      {/* <p className="gray">
                      {account.value} 
                    </p> */}
                    </td>
                    <td className="value">
                      <CountUp
                        end={account.value}
                        duration={1}
                        delay={0}
                        separator=","
                        suffix={" " + account.asset}
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText bigText"
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                    </td>
                    <td className="estimate">
                      <CountUp
                        end={currentPrices[account.asset] * account.value}
                        duration={1}
                        delay={0}
                        separator=","
                        prefix="$"
                        decimals="2"
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText bigText"
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                    </td>
                    <td className="currentPrice">
                      <p>
                        ${currentPrices[account.asset]}/{account.asset}
                      </p>
                    </td>
                    <td>
                      {option === "Deposit" && (
                        <button
                          className="actionButton"
                          onClick={() => navigate('/deposit')}
                        >
                          <p className="actionText">Deposit</p>
                        </button>
                      )}

                      {option === "Trade" && (
                        <button
                          className="actionButton"
                          onClick={() => navigate("/dashboard")}
                        >
                          <p className="actionText">Trade</p>
                        </button>
                      )}

                      {option === "Withdraw" && (
                        <button
                          className="actionButton"
                          onClick={() => navigate("/withdraw")}
                        >
                          <p className="actionText">Withdraw</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}

            {account.map(
              (account) =>
                !account.value > 0 && (
                  <tr key={account.asset} className="desktopRow">
                    <td className="asset">
                      <span className="icon">
                        <img src={`/asseticons/${account.asset}.svg`} alt="" />
                      </span>
                      <p>{account.asset}</p>
                    </td>
                    <td className="assetName">
                      <p>{account.name}</p>
                    </td>

                    <td className="mobile">
                      <CountUp
                        end={currentPrices[account.asset] * account.value}
                        duration={1}
                        delay={0}
                        separator=","
                        prefix="$"
                        decimals="2"
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText bigText"
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                      <CountUp
                        end={account.value}
                        duration={1}
                        delay={0}
                        separator="."
                        decimals="2"
                        suffix={" " + account.asset}
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText  bigText"
                              style={{ color: "#a3a3a3" }}
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                      {/* <p className="gray">
                      {account.value} 
                    </p> */}
                    </td>
                    <td className="value">
                      <CountUp
                        end={account.value}
                        duration={1}
                        delay={0}
                        separator=","
                        suffix={" " + account.asset}
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText bigText"
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                    </td>
                    <td className="estimate">
                      <CountUp
                        end={currentPrices[account.asset] * account.value}
                        duration={1}
                        delay={0}
                        separator=","
                        prefix="$"
                        decimals="2"
                      >
                        {({ countUpRef }) => (
                          <div>
                            <span
                              className="bottomText bigText"
                              ref={countUpRef}
                            />
                          </div>
                        )}
                      </CountUp>
                    </td>
                    <td className="currentPrice">
                      <p>
                        ${currentPrices[account.asset]}/{account.asset}
                      </p>
                    </td>
                    <td>
                      {option === "Deposit" && (
                        <button
                          className="actionButton"
                          onClick={() => navigate('/deposit')}
                        >
                          <p className="actionText">Deposit</p>
                        </button>
                      )}

                      {option === "Trade" && (
                        <button
                          className="actionButton"
                          onClick={() => navigate("/dashboard")}
                        >
                          <p className="actionText">Trade</p>
                        </button>
                      )}

                      {option === "Withdraw" && (
                        <button
                          className="actionButton"
                          onClick={() => navigate("/withdraw")}
                        >
                          <p className="actionText">Withdraw</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;

  .mobileValue {
    display: none;
  }

  @media screen and (max-width: 800px) {
    .value,
    .estimate,
    .assetName,
    .currentPrice {
      display: none;
    }

    .mobileValue {
      display: flex;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .desktopTable {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    width: 100%;

    th,
    td {
      width: 120px;
      text-align: left;
      box-sizing: border-box;
    }
  }

  .desktopHead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  .desktopBody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .desktopRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }

  .mobileTable {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    width: 100%;

    .mobileBody {
      font-weight: 510;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }

    td,
    th {
      /* background-color: red; */
      box-sizing: border-box;
      display: flex;
      width: 100px;
    }
  }
`;

export default AssetTable;
