import { Menu, SubText } from "../styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import ShowFXDetailsModal from "../modals/ShowFXDetailsModal";
import axios from "axios";
import TradeModal from "../modals/TradeModal";

const TradeTable = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trades, setTrades] = useState([]);
  const { userId } = id;
  const [users, setUsers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [tradeDetails, setTradeDetails] = useState([]);
  const [tradeAsset, setTradeAsset] = useState("");
  const [tradeInfo, setTradeInfo] = useState([]);
  const [tradeStats, setTradeStats] = useState({});
  const [showRegularTradeDetails, setShowRegularTradeDetails] = useState(false);
  const [regDetails, setRegDetails] = useState([]);
  const [regType, setRegType] = useState("");

  useEffect(() => {
    const tradesList = [];
    async function getTrades() {
      const q = query(collection(db, "trades"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        tradesList.push(doc.data());
        setTrades(tradesList);
      });
    }
    getTrades();
  }, []);

  const [closedTrades, setClosedTrades] = useState([]);
  const [pendingTrades, setPendingTrades] = useState([]);

  useEffect(() => {
    const closedTradesList = [];
    const pendingTradesList = [];

    trades.forEach((trade) => {
      switch (trade.status) {
        case "open":
          pendingTradesList.push(trade);
          break;
        case "closed":
          closedTradesList.push(trade);
          break;
        default:
          break;
      }
    });

    setClosedTrades(closedTradesList);
    setPendingTrades(pendingTradesList);
  }, [trades, pendingTrades, closedTrades]);

  useEffect(() => {
    if (!trades) {
      setIsLoading(true);
    }

    if (trades) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [trades]);

  useEffect(() => {
    const userList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
        setUsers(userList);
      });
    }

    getUsers();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  function handleShowDetails(trade) {
    switch (trade.type) {
      case "Forex":
        setTradeInfo(trade);
        showFX(trade);
        break;
      default:
        break;
    }
  }

  async function showFX(trade) {
    const pairsSplit = trade.asset.split("/");
    const Base = pairsSplit[0];
    const Quote = pairsSplit[1];

    const url = `https://api.fastforex.io/fetch-one?from=${Base}&to=${Quote}&api_key=18bfe4aa78-c27909660a-rd9m18`;
    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { result } = response.data;
        let current = Object.values(result);
        showFXDetails(...current, trade, Base, Quote);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function showFXDetails(current, trade, Base, Quote) {
    if (trade.status === "open") {
      if (trade.action === "Buy") {
        let actualEntry;
        let actualPrice;
        let actualTP;
        let actualSL;
        let pips;
        let pnl;
        let info;
        let stats;
        switch (Quote) {
          case "JPY":
            actualEntry = Number(trade.price).toFixed(2);
            actualPrice = Number(current).toFixed(2);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(2)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(2)
              : "Unavailable";
            pips = (actualPrice - actualEntry) * 100;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: Number(pnl).toFixed(2),
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };

            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
          default:
            actualEntry = Number(trade.price).toFixed(4);
            actualPrice = Number(current).toFixed(4);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(4)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(4)
              : "Unavailable";
            pips = (actualPrice - actualEntry) * 10000;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: Number(pnl).toFixed(2),
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };

            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
        }
      }
      if (trade.action === "Sell") {
        let actualEntry;
        let actualPrice;
        let actualTP;
        let actualSL;
        let pips;
        let pnl;
        let info;
        let stats;
        switch (Quote) {
          case "JPY":
            actualEntry = Number(trade.price).toFixed(2);
            actualPrice = Number(current).toFixed(2);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(2)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(2)
              : "Unavailable";
            pips = (actualEntry - actualPrice) * 100;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: trade.closeDetails.pnl ? trade.closeDetails.pnl : 0,
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };

            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
          default:
            actualEntry = Number(trade.price).toFixed(4);
            actualPrice = Number(current).toFixed(4);
            actualSL = trade.details.sl
              ? Number(trade.details.sl).toFixed(4)
              : "Unavailable";
            actualTP = trade.details.tp
              ? Number(trade.details.tp).toFixed(4)
              : "Unavailable";
            pips = (actualEntry - actualPrice) * 10000;
            pnl =
              Number(trade.details.perpip) *
              Number(pips) *
              Number(trade.details.amount);
            info = [
              {
                name: "Entry Price",
                value: actualEntry,
              },
              {
                name: "Asset",
                value: trade.asset,
              },
              {
                name: "Action",
                value: trade.action,
              },
              {
                name: "Current Price",
                value: actualPrice,
              },
              {
                name: "PnL",
                value: Number(pnl).toFixed(2),
              },
              {
                name: "TP",
                value: actualTP,
              },
              {
                name: "SL",
                value: actualSL,
              },
            ];
            stats = {
              actualEntry,
              actualPrice,
              actualTP,
              actualSL,
              pips,
              pnl,
              info,
            };
            setTradeStats(stats);
            setTradeDetails(info);
            setTradeAsset(trade.asset);
            setShowDetails(true);
            break;
        }
      }
    }
    if (trade.status === "closed") {
      const info = [
        {
          name: "Entry Price",
          value: trade.details.entry,
        },
        {
          name: "Asset",
          value: trade.asset,
        },
        {
          name: "Action",
          value: trade.action,
        },
        {
          name: "Close Price",
          value: trade.closeDetails.closePrice,
        },
        {
          name: "PnL",
          value: Number(trade.closeDetails.pnl).toFixed(2)
            ? Number(trade.closeDetails.pnl).toFixed(2)
            : 0,
        },
      ];
      setTradeDetails(info);
      setTradeAsset(trade.asset);
      setShowDetails(true);
    }
  }

  function handleShowRegular(type, details) {
    setRegDetails(details);
    setRegType(type);
    setShowRegularTradeDetails(true);
  }

  return (
    <Wrapper>
      <SubText className="top">Trades</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          {showDetails && (
            <ShowFXDetailsModal
              open={{ showDetails, setShowDetails }}
              info={tradeDetails}
              asset={tradeAsset}
              trade={tradeInfo}
              stats={tradeStats}
            />
          )}

          {showRegularTradeDetails && (
            <TradeModal
              details={regDetails}
              type={regType}
              open={{ showRegularTradeDetails, setShowRegularTradeDetails }}
            />
          )}
          <table className="scrollbar-hidden">
            <thead>
              <tr>
                <th>User</th>
                <th>Ref</th>
                <th>Type</th>
                <th>Action</th>
                <th>Amount</th>
                <th>Asset</th>
                <th>Entry Price</th>
                <th>Date</th>
                <th>Details</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {pendingTrades.map((trade) =>
                trade.type === "Forex" ? (
                  <tr key={trade.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === trade.user && (
                            <div className="name user" key={trade.ref}>
                              {user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={user.photoURL}
                                    alt="user"
                                    className="profile"
                                  />
                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0",
                                        right: "0",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              {!user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <span className="avatar">
                                    {user.firstname.slice(0, 1)}
                                  </span>

                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-4px",
                                        right: "-4px",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              <p>{user.firstname}</p>
                            </div>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() => {
                          handleShowDetails(trade);
                        }}
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "open" && (
                        <button className="approved">
                          <p>Running</p>
                        </button>
                      )}
                    </td>
                  </tr>
                ) : (
                  <tr key={trade.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === trade.user && (
                            <div className="name user" key={trade.ref}>
                              {user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={user.photoURL}
                                    alt="user"
                                    className="profile"
                                  />
                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0",
                                        right: "0",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              {!user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <span className="avatar">
                                    {user.firstname.slice(0, 1)}
                                  </span>

                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-4px",
                                        right: "-4px",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              <p>{user.firstname}</p>
                            </div>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() =>
                          handleShowRegular(trade.type, trade.details)
                        }
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "open" && (
                        <button className="approved">
                          <p>Running</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
              )}
              {closedTrades.map((trade) =>
                trade.type === "Forex" ? (
                  <tr key={trade.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === trade.user && (
                            <div className="name user" key={trade.ref}>
                              {user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={user.photoURL}
                                    alt="user"
                                    className="profile"
                                  />
                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0",
                                        right: "0",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              {!user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <span className="avatar">
                                    {user.firstname.slice(0, 1)}
                                  </span>

                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-4px",
                                        right: "-4px",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              <p>{user.firstname}</p>
                            </div>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() => {
                          handleShowDetails(trade);
                        }}
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "closed" && (
                        <button className="declined">
                          <p>Fufilled</p>
                        </button>
                      )}
                    </td>
                  </tr>
                ) : (
                  <tr key={trade.ref} className="row">
                    <td>
                      {users.map(
                        (user) =>
                          user.id === trade.user && (
                            <div className="name user" key={trade.ref}>
                              {user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={user.photoURL}
                                    alt="user"
                                    className="profile"
                                  />
                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "0",
                                        right: "0",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              {!user.photoURL && (
                                <div style={{ position: "relative" }}>
                                  <span className="avatar">
                                    {user.firstname.slice(0, 1)}
                                  </span>

                                  {user.admin && (
                                    <span
                                      style={{
                                        position: "absolute",
                                        bottom: "-4px",
                                        right: "-4px",
                                      }}
                                    >
                                      <img
                                        src="/extraicons/checkmark.svg"
                                        alt="admin"
                                      />
                                    </span>
                                  )}
                                </div>
                              )}

                              <p>{user.firstname}</p>
                            </div>
                          )
                      )}
                    </td>
                    <td className="ref">
                      <p>{trade.ref}</p>
                    </td>
                    <td>
                      <p>{trade.type}</p>
                    </td>
                    <td>
                      <p className={trade.action === "Buy" ? "green" : "red"}>
                        {trade.action}
                      </p>
                    </td>
                    <td>
                      <p className="green">{trade.amount}</p>
                    </td>
                    <td>
                      <p>{trade.asset}</p>
                    </td>
                    <td>
                      <p className="green">{trade.price} USD</p>
                    </td>
                    <td>
                      <p>{getTime(new Date() - trade.date.toDate())}</p>
                    </td>
                    <td>
                      <button
                        className="actionButton"
                        onClick={() =>
                          handleShowRegular(trade.type, trade.details)
                        }
                      >
                        <p className="actionText">Show</p>
                      </button>
                    </td>
                    <td>
                      {trade.status === "closed" && (
                        <button className="declined">
                          <p>Fufilled</p>
                        </button>
                      )}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default TradeTable;
