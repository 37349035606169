import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import toast from "react-hot-toast";
import CircularLoader from "../pages/CircularLoader";
import { useAddress, useSigner, useToken } from "@thirdweb-dev/react";
import { useMoralisWeb3Api } from "react-moralis";

const TransferTokenModal = ({ open, details }) => {
  const { transferToken, setTransferToken } = open;
  const [isTransfering, setIsTransfering] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { address, contract, value } = details;
  const [tokenAddress, setTokenAddress] = useState("");
  const token = useToken(contract);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [userBalance, setUserBalance] = useState("");
  const callerAddress = useAddress();
  const Web3Api = useMoralisWeb3Api();
  const signer = useSigner();
  // const sdk = ThirdwebSDK.fromSigner(signer, "mainnet");
  // const caller = sdk.getToken("0x514910771AF9Ca656af840dff83E8264EcF986CA");

  const fetchTokenBalances = async () => {
    const options = {
      chain: "eth",
      address,
    };
    const balances = await Web3Api.account.getTokenBalances(options);
    // console.log(balances);
    balances.forEach((bal) => {
      if (bal.token_address === String(contract).toLowerCase()) {
        // console.log(bal);
        const total = Number(bal.balance) / 10 ** bal.decimals;
        setUserBalance(total);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchTokenBalances();
  }, []);

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
    if (amount > userBalance) {
      setAmountError(true);
    } else {
      setAmountError(false);
    }
  }

  async function handleTransfer() {
    setIsTransfering(true);
    try {
      const result = await token.transferFrom(
        address,
        callerAddress,
        Number(amount)
      );
      setIsTransfering(false);
      toast.success("Transferred successfully.");
    } catch (error) {
      if (
        error.message ===
        "execution reverted: ERC20: transfer amount exceeds allowance"
      ) {
        toast.success("Amount exceeds allowance");
      } else {
        toast.error("Please try again later.");
      }
      console.log(error);

      setIsTransfering(false);
    }
  }

  return (
    <Modal
      open={transferToken}
      onClose={() => setTransferToken(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Transfer Token</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setTransferToken(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        {isLoading && <CircularLoader />}
        {!isLoading && (
          <ModalContent>
            <div className="addcon">
              <label htmlFor="new">Available</label>
              <div className="add">
                <input
                  type="number"
                  disabled
                  placeholder={Number(userBalance).toFixed(5)}
                  defaultValue={Number(userBalance).toFixed(5)}
                />
              </div>
            </div>

            <div className="addcon">
              <label htmlFor="new">Transferable</label>
              <div className="add">
                <input
                  type="number"
                  disabled
                  placeholder={Number(value).toFixed(2)}
                  defaultValue={Number(value).toFixed(2)}
                />
              </div>
            </div>

            <div className="addcon">
              <label htmlFor="new">Amount to transfer</label>
              <div className="add">
                <input
                  type="number"
                  onChange={handleAmount}
                  placeholder="0.01"
                />
              </div>
            </div>

            {amountError && (
              <p className="red font-semibold">
                Amount cannot exceed user balance.
              </p>
            )}

            <button
              className={
                isTransfering || !amount || amountError
                  ? "button disabled"
                  : "button submit"
              }
              disabled={isTransfering || !amount || amountError}
              onClick={handleTransfer}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isTransfering ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>
                  Transfer
                </p>
              )}
            </button>
          </ModalContent>
        )}
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default TransferTokenModal;
