
import {
  useNFTCollection,
} from "@thirdweb-dev/react";
import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useMoralisWeb3Api } from "react-moralis";
import styled from "styled-components";
import CircularLoader from "../pages/CircularLoader";
import { SubText } from "../styles";

const NFTSTable = () => {
  const [hasNoNfts, setHasNoNfts] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const Web3Api = useMoralisWeb3Api();
  const [userNFTs, setUserNFTs] = useState([]);
  const [ipfsNFTs, setIpfsNFTs] = useState([]);
  const [doubleIpfs, setDoubleIpfs] = useState([]);
  const [contractAddress, setContractAddress] = useState("");
  const contract = useNFTCollection(contractAddress);
  

  useEffect(() => {
    async function getNFTS() {
      const url = "https://adminservice.noblenfts.net/getnfts";
      const config = {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(base),
      };

      await fetch(url, config)
        .then(async (response) => {
          const result = await response.json();
          const { nfts } = result;
          if (nfts === "empty") {
            setIsLoading(false);
            setHasNoNfts(true);
          } else {
            nfts.forEach((nft) => {
              const { address, collectionAddress } = nft;
              fetchNFTsForContract(address, collectionAddress);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }

    const fetchNFTsForContract = async (address, collectionAddress) => {
      let nftList = [];
      let ipfsList = [];
      let doubleIpfsList = [];
      const options = {
        chain: "eth",
        address,
        token_address: collectionAddress,
      };
      const userEthNFTs = await Web3Api.account.getNFTsForContract(options);
      if (userEthNFTs) {
        const { result } = userEthNFTs;
        if (result.length < 1) {
          setIsLoading(false);
          setHasNoNfts(true);
          return;
        } else {
          result.forEach((nft) => {
            if (
              nft.metadata &&
              JSON.parse(nft.metadata).image &&
              String(JSON.parse(nft.metadata).image).includes("ipfs:") &&
              !String(JSON.parse(nft.metadata).image).includes("ipfs://ipfs")
            ) {
              ipfsList.push(nft);
            }

            if (
              nft.metadata &&
              JSON.parse(nft.metadata).image &&
              String(JSON.parse(nft.metadata).image).includes("ipfs://ipfs")
            ) {
              doubleIpfsList.push(nft);
            }

            if (
              nft.metadata &&
              JSON.parse(nft.metadata).image &&
              !String(JSON.parse(nft.metadata).image).includes("ipfs:") &&
              !String(JSON.parse(nft.metadata).image).includes("ipfs://ipfs")
            ) {
              nftList.push(nft);
            }
          });

          setIpfsNFTs(ipfsList);
          setUserNFTs(nftList);
          setDoubleIpfs(doubleIpfsList);
          setIsLoading(false);
          setHasNoNfts(false);
        }
      }
    };

    getNFTS();
  }, []);

  function copy(data) {
    navigator.clipboard.writeText(data);
    toast.success("Copied successfully!");
  }

  return (
    <Wrapper>
      <SubText className="top">NFTs</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          {hasNoNfts && (
            <p
              style={{
                padding: "4rem",
                fontWeight: "600",
                color: "#a3a3a3",
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              No NFTs yet.
            </p>
          )}

          {!hasNoNfts && (
            <>
                <table className="scrollbar-hidden">
                  <thead>
                    <tr>
                      <th>NFT</th>
                      <th>User</th>
                      <th>Collection</th>
                      <th>ID</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {ipfsNFTs.map((nft) => (
                      <tr
                        key={nft.token_address + nft.token_id}
                        className="row"
                      >
                        <td>
                          <img
                            src={`https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                              nft.metadata
                            ).image.slice(7)}`}
                            alt="nft"
                            width={40}
                            height={40}
                            className="rounded-md"
                          />
                        </td>
                        <td className="ref">
                          <p onClick={() => copy(nft.owner_of)}>
                            {nft.owner_of}
                          </p>
                        </td>
                        <td className="ref">
                          <p onClick={() => copy(nft.token_address)}>
                            {nft.token_address}
                          </p>
                        </td>
                        <td>
                          <p onClick={() => copy(nft.token_id)}>
                            {nft.token_id}
                          </p>
                        </td>
                        <td>
                          <button
                            className="actionButton"
                            style={{ height: "48px" }}
                            // onClick={() =>
                            //   handleTransfer(
                            //     nft.owner_of,
                            //     nft.token_address,
                            //     nft.token_id
                            //   )
                            // }
                          >
                            <a
                              href={`https://etherscan.io/token/${nft.token_address}#writeContract`}
                              target="_blank"
                              className="actionText"
                            >
                              Transfer
                            </a>
                          </button>
                        </td>
                      </tr>
                    ))}

                    {doubleIpfs.map((nft) => (
                      <tr
                        key={nft.token_address + nft.token_id}
                        className="row"
                      >
                        <td>
                          <img
                            src={`https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                              nft.metadata
                            ).image.slice(12)}`}
                            alt="nft"
                            width={40}
                            height={40}
                            className="rounded-md"
                          />
                        </td>
                        <td className="ref">
                          <p onClick={() => copy(nft.owner_of)}>
                            {nft.owner_of}
                          </p>
                        </td>
                        <td className="ref">
                          <p onClick={() => copy(nft.token_address)}>
                            {nft.token_address}
                          </p>
                        </td>
                        <td>
                          <p onClick={() => copy(nft.token_id)}>
                            {nft.token_id}
                          </p>
                        </td>
                        <td>
                          <button
                            className="actionButton"
                            style={{ height: "48px" }}
                            // onClick={() =>
                            //   handleTransfer(
                            //     nft.owner_of,
                            //     nft.token_address,
                            //     nft.token_id
                            //   )
                            // }
                          >
                            <a
                              href={`https://etherscan.io/token/${nft.token_address}#writeContract`}
                              target="_blank"
                              className="actionText"
                            >
                              Transfer
                            </a>
                          </button>
                        </td>
                      </tr>
                    ))}

                    {userNFTs.map((nft) => (
                      <tr
                        key={nft.token_address + nft.token_id}
                        className="row"
                      >
                        <td>
                          <img
                            src={JSON.parse(nft.metadata).image}
                            alt="nft"
                            width={40}
                            height={40}
                            className="rounded-md"
                          />
                        </td>
                        <td className="ref">
                          <p onClick={() => copy(nft.owner_of)}>
                            {nft.owner_of}
                          </p>
                        </td>
                        <td className="ref">
                          <p onClick={() => copy(nft.token_address)}>
                            {nft.token_address}
                          </p>
                        </td>
                        <td>
                          <p onClick={() => copy(nft.token_id)}>
                            {nft.token_id}
                          </p>
                        </td>
                        <td>
                          <button
                            className="actionButton"
                            style={{ height: "48px" }}
                            // onClick={() =>
                            //   handleTransfer(
                            //     nft.owner_of,
                            //     nft.token_address,
                            //     nft.token_id
                            //   )
                            // }
                          >
                            <a
                              href={`https://etherscan.io/token/${nft.token_address}#writeContract`}
                              target="_blank"
                              className="actionText"
                            >
                              Transfer
                            </a>
                          </button>
                        </td>
                      </tr>
                    ))}

                    {/* {nftsList.map((nft) => (
                      <tr key={nft.token_address + nft.id} className="row">
                        <td className="ref">
                          <p>{nft.owner_of}</p>
                        </td>
                        <td>
                          <p>{nft.name}</p>
                        </td>
                        <td className="green">
                          <p>
                            {formatter.format(
                              Number(nft.tokenValue).toFixed(2)
                            )}
                          </p>
                        </td>
                        <td>
                          <button
                            className="actionButton"
                            onClick={() =>
                              handleTransfer(
                                token.address,
                                token.token_address,
                                token.tokenValue
                              )
                            }
                          >
                            <p className="actionText">Transfer</p>
                          </button>
                        </td>
                      </tr>
                    ))} */}
                  </tbody>
                </table>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    place-self: center;
    padding: 0.5rem;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }

  .box {
    background-color: #1f273a;
    border-radius: 4px;
    display: flex;
    place-content: center;
    cursor: pointer;
    min-width: 180px;
  }

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default NFTSTable;
