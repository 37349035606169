import { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText, OutlinedBox } from "../styles";
import styled from "styled-components";
import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { userContext } from "../context/userContext";

const StakeModal = ({ open, name, minimum, maximum, symbol, user }) => {
  const { stake, setStake } = open;
  const [isStaking, setIsStaking] = useState("");
  const [duration, setDuration] = useState(5);
  const [ROI, setROI] = useState(75);
  const [amount, setAmount] = useState("");
  const [minimumError, setMinimumError] = useState(false);
  const [maximumError, setMaximumError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const { accounts, currentPrices } = useContext(userContext);
  const [liveAccounts, setLiveAccounts] = useState([]);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Stock, Crypto, Fiat } = live;

        setBalance(Crypto[symbol]?.value);
        console.log(balance);

        const StockAccount = Object.values(Stock);
        const CryptoAccount = Object.values(Crypto);
        setLiveAccounts([...CryptoAccount, ...StockAccount, Fiat]);
      }
    }
  }, [accounts]);

  useEffect(() => {
    console.log(balance, amount);
  }, [balance, amount]);

  useEffect(() => {
    if (amount) {
      if (amount > balance) {
        setAmountError(true);
      } else {
        setAmountError(false);
      }

      if (amount < minimum) {
        setMinimumError(true);
      } else {
        setMinimumError(false);
      }

      if (amount > maximum) {
        setMaximumError(true);
      } else {
        setMaximumError(false);
      }
    }
  }, [balance, amount]);

  function handleDuration(e) {
    const value = e.target.value;

    function getROI(value) {
      switch (Number(value)) {
        case 10:
          setROI(75);
          break;
        case 20:
          setROI(130);
          break;
        case 30:
          setROI(250);
          break;
        default:
          break;
      }
    }

    if (value) {
      getROI(value);
      setDuration(value);
    } else {
      setDuration("");
    }
  }

  function handleAmount(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(Number(value));
    } else {
      setAmount("");
      setAmountError(false);
      setMaximumError(false);
      setMinimumError(false);
    }
  }

  function handleStaking() {
    setIsStaking(true);
    const { uid } = user;
    decrementAmount(uid);
  }

  // Decrement fiat
  const decrementAmount = async (id) => {
    const q = doc(db, "accounts", id);

    const key = `live.Crypto.${symbol}.value`;

    try {
      await updateDoc(q, {
        [key]: increment(-amount),
      }).then(() => {
        setStaking(id);
      });
    } catch (error) {
      setIsStaking(false);
      toast.error("Please try again later.");
    }
  };

  async function setStaking(id) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "stakings", str), {
      date: serverTimestamp(),
      ref: str,
      status: "open",
      crypto: name,
      symbol: symbol,
      user: id,
      duration,
      daysRan: 0,
      amount: amount,
      roi: ROI,
    })
      .then(() => {
        toast.success("Staked successfully.");
        setIsStaking(false);
      })
      .catch((error) => {
        console.log(error);
        setIsStaking(false);
        toast.error("Staking request could not complete.");
      });
  }

  return (
    <Modal
      open={stake}
      onClose={() => setStake(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Stake {name}</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setStake(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div className="amWrap">
            <label htmlFor="amount">Amount</label>
            <div className="amBx">
              <div className="amTop">
                <input
                  placeholder={minimum}
                  name="amount"
                  type="number"
                  //   ref={cryptoAmountRef}
                  onChange={handleAmount}
                />
                <span className="coinSelector">
                  <img src={`/asseticons/${symbol}.svg`} alt="coin" />
                  <p>{symbol}</p>
                </span>
              </div>

              <div className="captions">
                <span className="balance flex">
                  Balance -
                  {liveAccounts.map(
                    (acc) =>
                      acc.asset === symbol && (
                        <>
                          {acc.value > 0 && (
                            <span key={acc.asset} className="green">
                              {Number(acc.value).toFixed(2)} {symbol}
                            </span>
                          )}
                          {acc.value <= 0 && (
                            <span className="flex gap-1 items-center">
                              <span key={acc.asset} className="red">
                                {Number(acc.value).toFixed(2)} {symbol}
                              </span>
                              <Link to="/deposit">
                                <p className="red underline">Deposit now</p>
                              </Link>
                            </span>
                          )}
                        </>
                      )
                  )}
                </span>
                {amount && (
                  <p className="extra">
                    {/* USD */}
                    <span className="green">
                      {" "}
                      ~$
                      {Math.floor(currentPrices[symbol] * amount)}
                    </span>
                  </p>
                )}
              </div>

              {amountError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  Amount cannot exceed current balance.
                </p>
              )}

              {minimumError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  The minimum staking is {minimum} {""} {symbol}.
                </p>
              )}

              {maximumError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  The maximum staking is {maximum} {""} {symbol}.
                </p>
              )}
            </div>
          </div>

          <div className="selectWrap">
            <label htmlFor="type">Duration(Days)</label>
            <div className="selectBox">
              <div className="selectTop">
                <select name="account" onChange={handleDuration}>
                  <option>10</option>
                  <option>20</option>
                  <option>30</option>
                </select>
                <img src="/extraicons/arrowdown.svg" alt="select" />
              </div>
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">ROI</label>
            <div className="add">
              <input type="number" disabled placeholder={ROI} value={ROI} />
            </div>
          </div>

          <OutlinedBox>
            <p className="infot">
              Staking results in an estimated {ROI / duration}% RPC (Return Per
              Cycle) for {symbol}.
            </p>
          </OutlinedBox>
          <OutlinedBox>
            <p className="infot" style={{ fontSize: "11px" }}>
              Your staking period will end in {duration} days. Your earnings
              will be sent to your live {symbol} account.
            </p>
          </OutlinedBox>

          <button
            className={
              isStaking ||
              !duration ||
              !ROI ||
              !amount ||
              amountError ||
              minimumError ||
              maximumError
                ? "button disabled"
                : "button submit"
            }
            disabled={
              isStaking ||
              !duration ||
              !ROI ||
              !amount ||
              amountError ||
              maximumError ||
              minimumError
            }
            onClick={handleStaking}
            style={{ display: "grid", placeContent: "center" }}
          >
            {isStaking ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                width={24}
                height={24}
              />
            ) : (
              <p style={{ textDecoration: "none", color: "white" }}>Stake</p>
            )}
          </button>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

export default StakeModal;
