import { useEffect, useState } from "react";
import styled from "styled-components";
import { Menu, FeatureBtn, FeatureText } from "../styles";
import CountUp from "react-countup";
import { userContext } from "../context/userContext";
import { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";

const progress = 30;

const ProgressCard = () => {
const {userData} = useContext(userContext)

  return (
    <Wrapper>
      <div className="left">
        <FeatureBtn>
          <img src="sidebaricons/progress.svg" alt="progress" />
        </FeatureBtn>
        <FeatureText>
          <p className="topText smallText">Trading progress</p>
          <CountUp
            end={userData?.tradingProgress}
            duration={1}
            delay={0}
            separator=","
            suffix="%"
          >
            {({ countUpRef }) => (
              <div>
                <span className="bottomText bigText" ref={countUpRef} />
              </div>
            )}
          </CountUp>
        </FeatureText>
      </div>

      <div style={{position: "relative", padding: "0px 12px", width: "100%", backgroundColor: "red", marginTop: "16px", boxSizing: "border-box"}}>
        <div style={{width: "100%", height: "9px", backgroundColor: "#c9c9c9", borderRadius: "8px", position: "absolute", left: "0px", top: "3px"}}></div>
        <div style={{width: userData?.tradingProgress + "%", height: "9px", backgroundColor: "#1199fa", borderRadius: "8px", position: "absolute", left: "0px", top: "3px"}}></div>
      </div>

       
    </Wrapper>
  );
};

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  height: 149px;
  padding: 1rem 10px;
  padding-bottom: 1rem;
  border-radius: 10px;
  background: #131824;
  min-width: 100%;

  .left {
    display: flex;
    gap: 0.5rem;
  }

  .smallText {
    font-size: 13px;
  }

  .bigText {
    font-size: 20px;
  }

  .right {
    position: relative;

    button {
      cursor: pointer;
    }
  }

  .switchMenu {
    background-color: #1f273a;
    padding: 1rem;
    top: 23px;
    font-weight: 600;
    text-transform: capitalize;
    position: absolute;
    top: 30px;
    right: 0;
    border: 1px solid #19202f;
    cursor: pointer;
  }

  .switch {
    border: none;
    text-transform: capitalize;
    font-weight: 600;
    padding: 0.5rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .practice {
    color: #f7931a;
    background-color: #403529;
  }

  .live {
    color: #20bca4;
    background-color: #20383c;
  }

  .title {
    /* font-size: 1rem; */
  }

  .bigText {
    font-size: 20px;
  }
`;

export default ProgressCard;
