import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";

const AddPlanModal = ({ open }) => {
  const { addPlan, setAddPlan } = open;
  const [name, setName] = useState(undefined);
  const [minimum, setMinimum] = useState(undefined);
  const [maximum, setMaximum] = useState(undefined)
  const [roi, setRoi] = useState(undefined)
  const [duration, setDuration] = useState(undefined)
  const [isAdding, setIsAdding] = useState(false);

  function handleName(e) {
    const value = e.target.value;

    if (value) {
      setName(value);
    } else {
      setName("");
    }
  }

  function handleMinimum(e) {
    const value = e.target.value;

    if (value) {
      setMinimum(value);
    } else {
      setMinimum("");
    }
  }

  function handleMaximum(e) {
    const value = e.target.value;

    if (value) {
      setMaximum(value);
    } else {
      setMaximum("");
    }
  }

  function handleROI(e) {
    const value = e.target.value;

    if (value) {
      setRoi(value);
    } else {
      setRoi("");
    }
  }

  function handleDuration(e) {
    const value = e.target.value;

    if (value) {
      setDuration(value);
    } else {
      setDuration("");
    }
  }

  async function handleAdd() {
    setIsAdding(true);

    const q = doc(db, "admin", "plans");
    try {
      await updateDoc(q, {
        [name]: {
          name,
          minimum,
          maximum,
          duration,
          roi,
          details: [
            {
                name: "DURATION",
                value: `${duration} DAYS`
            },
            {
                name: "ROI",
                value: `${roi}%`
            },
            {
                name: "MAXIMUM",
                value: `${maximum}`
            },
          ]
        },
      });
      toast.success("Added successfully.");
      setIsAdding(false);
      setAddPlan(false)
    } catch (error) {
      console.log(error);
      toast.error("Could not add. Please try again later");
      setIsAdding(false);
    }
  }

  async function handleCancel() {
    setAddPlan(false);
  }

  return (
    <Modal
      open={addPlan}
      onClose={() => setAddPlan(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Add New Plan</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setAddPlan(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          {/* <div>
            <label className="block text-sm font-medium text-white">
              Photo
            </label>
            <div className="mt-1 flex items-center">
              <img
                src={
                  newImage
                    ? newImage
                    : picture ||
                      "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"
                }
                alt="profile"
                className="inline-block h-12 w-12 rounded-full overflow-hidden"
              />

              <button
                type="button"
                disabled={isUploading || !name}
                onClick={handleImage}
                className={
                  !name
                    ? "ml-5 bg-gray-800 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white"
                    : "ml-5 bg-[#1199fa] py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white"
                }
              >
                {isUploading && (
                  <span className="flex w-full">
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      width={12}
                      height={12}
                    />
                  </span>
                )}
                {!isUploading && !name && "Add name to change"}
                {!isUploading && name && "Change"}
              </button>

              <input
                type="file"
                onChange={handleImageClick}
                ref={imageUpload}
                style={{
                  opacity: "0",
                  pointerEvents: "none",
                  userSelect: "none",
                  position: "absolute",
                }}
              />
            </div>
          </div> */}

          <div className="addcon">
            <label htmlFor="new">Plan Name</label>
            <div className="add">
              <input
                onChange={(e) => handleName(e)}
                placeholder="Lite"
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Minimum</label>
            <div className="add">
              <input
                onChange={(e) => handleMinimum(e)}
                placeholder="10000"
                type='number'
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Maximum</label>
            <div className="add">
              <input
                onChange={(e) => handleMaximum(e)}
                placeholder="100000"
                type='number'
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">ROI</label>
            <div className="add">
              <input
                onChange={(e) => handleROI(e)}
                placeholder="200"
                type='number'
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Duration (Days) </label>
            <div className="add">
              <input
                onChange={(e) => handleDuration(e)}
                placeholder="10"
                type='number'
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              className={
                !name ||
                !minimum ||
                !maximum ||
                !roi ||
                !duration ||
                isAdding
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !name ||
                !minimum ||
                !maximum ||
                !roi ||
                !duration ||
                isAdding
              }
              onClick={() => handleAdd()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isAdding ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Add</p>
              )}
            </button>

            <button
              onClick={() => handleCancel()}
              style={{
                width: "100%",
                backgroundColor: "#E64B60",
                color: "white",
                textTransform: "uppercase",
                height: "44px",
                display: "grid",
                placeItems: "center",
                fontWeight: "600",
                borderRadius: "8px",
              }}
            >
              Close
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default AddPlanModal;
