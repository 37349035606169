import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { ActionButtons, Card, SubText } from "../styles";
import FixStocks from "../components/fixTrade/Stocks";
import FixForex from "../components/fixTrade/Forex";
import FixCrypto from "../components/fixTrade/Crypto";

const FixTradeModal = ({ open, user }) => {
  const { fixTrade, setFixTrade } = open;
  const [tradeType, setTradeType] = useState("Crypto");
  const tradeTypes = ["Crypto", "Stocks", "Forex"];
  const [action, setAction] = useState("up");
  const [didReset, setDidReset] = useState("");

  const stockassets = [
    { name: "Apple", symbol: "AAPL" },
    { name: "Abbot Labs", symbol: "ABT" },
    { name: "Adobe", symbol: "ADBE" },
    { name: "Analog Devices", symbol: "ADI" },
    { name: "Aethlon Medical", symbol: "AEMD" },
    { name: "American International Group", symbol: "AIG" },
    { name: "AMC Holdings", symbol: "AMC" },
    { name: "AMD", symbol: "AMD" },
    { name: "American Tower", symbol: "AMT" },
    { name: "Amazon", symbol: "AMZN" },
    { name: "Alpha Pro Tech", symbol: "APT" },
    { name: "ASML", symbol: "ASML" },
    { name: "Aterian Inc", symbol: "ATER" },
    { name: "American Express", symbol: "AXP" },

    { name: "Boeing", symbol: "BA" },
    { name: "Alibaba", symbol: "BABA" },
    { name: "Bank of America", symbol: "BAC" },
    { name: "Baidu Inc", symbol: "BAIDU" },
    { name: "Bristol Myers", symbol: "BMY" },

    { name: "Citigroup", symbol: "C" },
    { name: "Caterpillar", symbol: "CAT" },
    { name: "Clear Channel Outdoor", symbol: "CCO" },
    { name: "Camber Energy", symbol: "CEI" },
    { name: "Chewy Inc", symbol: "CHWY" },
    { name: "Colgate-Palmolive", symbol: "CL" },
    { name: "Comcast", symbol: "CMCSA" },
    { name: "Costco", symbol: "COST" },
    { name: "Cardiff Oncology Inc", symbol: "CRDF" },
    { name: "Salesforce Inc", symbol: "CRM" },
    { name: "Cisco", symbol: "CSCO" },
    { name: "Chevron", symbol: "CVX" },

    { name: "Disney", symbol: "DIS" },

    { name: "Ebay", symbol: "EBAY" },

    { name: "Meta Platforms Inc", symbol: "FB" },
    { name: "Fastly Inc", symbol: "FSLY" },

    { name: "General Electric", symbol: "GE" },
    { name: "Gevo Inc", symbol: "GEVO" },
    { name: "General Motors", symbol: "GM" },
    { name: "Google", symbol: "GOOGL" },
    { name: "Goldman Sachs", symbol: "GS" },

    { name: "Home Depot", symbol: "HD" },
    { name: "Honeywell", symbol: "HON" },

    { name: "IBM", symbol: "IBM" },
    { name: "Inmode", symbol: "INMD" },
    { name: "Intel", symbol: "INTC" },

    { name: "Johnson & Johnson", symbol: "JNJ" },
    { name: "JP Morgain", symbol: "JP Morgan" },

    { name: "Coca Cola", symbol: "KO" },

    { name: "Lennar Corporation", symbol: "LEN" },
    { name: "Las vegas Sands", symbol: "LVS" },

    { name: "MasterCard", symbol: "MA" },
    { name: "Mondelez", symbol: "MDLZ" },
    { name: "3M Company", symbol: "MMM" },
    { name: "Monster", symbol: "MNST" },
    { name: "Attria Group", symbol: "MO" },
    { name: "Marin Software", symbol: "MRIN" },
    { name: "Merck", symbol: "MRK" },
    { name: "Morgan Stanley", symbol: "MS" },
    { name: "Microsoft", symbol: "MSFT" },
    { name: "Motorola", symbol: "MSI" },

    { name: "Netflix", symbol: "NFLX" },
    { name: "Nike", symbol: "NKE" },
    { name: "Nvidia", symbol: "NVDA" },
    { name: "Novartis", symbol: "NVS" },

    { name: "Oracle", symbol: "ORCL" },

    { name: "Pepsico", symbol: "PEP" },
    { name: "Pfizer", symbol: "PFE" },
    { name: "Procter & Gamble", symbol: "PG" },
    { name: "PayPal", symbol: "PYPL" },

    { name: "Ferrari", symbol: "RACE" },
    { name: "Rocket Lab", symbol: "RKLB" },
    { name: "Ralph lauren", symbol: "RL" },
    { name: "Rewalk Robotics", symbol: "RWLK" },

    { name: "Starbucks", symbol: "SBUX" },
    { name: "SSR Mining", symbol: "SSRM" },
    { name: "Square", symbol: "SQ" },

    { name: "At&t", symbol: "T" },
    { name: "Teva", symbol: "TEVA" },
    { name: "Toyota Motor", symbol: "TM" },
    { name: "T-Mobile", symbol: "TMUS" },
    { name: "TripAdvisor", symbol: "TRIP" },
    { name: "Tesla", symbol: "TSLA" },
    { name: "TSMC", symbol: "TSM" },
    { name: "Twitter", symbol: "TWTR" },

    { name: "United Health Group", symbol: "UNH" },

    { name: "Visa", symbol: "V" },
    { name: "Verizon", symbol: "VZ" },
    { name: "Wells Fargo", symbol: "WFC" },

    { name: "Walmart", symbol: "WMT" },

    { name: "Exxon Mobil", symbol: "XOM" },
  ];


  const cryptoassets = [
    // { name: "1nch Protocol", symbol: "1INCHUSD" },

    { name: "Aave", symbol: "AAVEUSD" },
    { name: "Cardano", symbol: "ADAUSD" },
    { name: "Algorand", symbol: "ALGOUSD" },
    { name: "Anchor Protocol", symbol: "ANCUSD" },
    { name: "Apecoin", symbol: "APEUSD" },
    { name: "Aurora", symbol: "AURORAUSD" },
    { name: "Avalanche", symbol: "AVAXUSD" },
    { name: "Axie Infinity", symbol: "AXSUSD" },

    { name: "Bitcoin Cash", symbol: "BCHUSD" },
    { name: "Boring DAO", symbol: "BORINGUSD" },
    { name: "Bitcoin", symbol: "BTCUSD" },
    { name: "Bitcoin Gold", symbol: "BTGUSD" },

    { name: "Comdex", symbol: "CMDXUSD" },
    { name: "Cronos", symbol: "CROUSD" },

    { name: "Dai", symbol: "DAIUSD" },
    { name: "Dao Maker", symbol: "DAOUSD" },
    { name: "Dash", symbol: "DASHUSD" },
    { name: "Defi Land", symbol: "DFLUSD" },
    { name: "Polkadot", symbol: "DOTUSD" },
    { name: "Dogecoin", symbol: "DOGEUSD" },

    { name: "Evmos", symbol: "EVMOSUSD" },
    { name: "Ethereum Classic", symbol: "ETCUSD" },
    { name: "Ethereum", symbol: "ETHUSD" },

    { name: "Stepn", symbol: "GMTUSD" },
    { name: "Gochain", symbol: "GOUSD" },
    { name: "The Graph", symbol: "GRTUSD" },
    { name: "Gate", symbol: "GTUSD" },

    { name: "ChainLink", symbol: "LINKUSD" },
    { name: "Link", symbol: "LNUSD" },
    { name: "Litecoin", symbol: "LTCUSD" },

    { name: "Decentraland", symbol: "MANAUSD" },
    { name: "Matic", symbol: "MATICUSD" },

    { name: "Nexo", symbol: "NEXOUSD" },
    { name: "Energi", symbol: "NRGUSD" },

    { name: "Origin Protocol", symbol: "OGNUSD" },
    { name: "Okb", symbol: "OKBUSD" },
    { name: "Optimism", symbol: "OPUSD" },
    { name: "Orion Protocol", symbol: "ORNUSD" },

    { name: "Raini", symbol: "RAINIUSD" },
    { name: "Rarible", symbol: "RARIUSD" },

    { name: "Safepal", symbol: "SFPUSD" },
    { name: "Shiba Inu", symbol: "SHIBUSD" },
    { name: "Step Finance", symbol: "STEPUSD" },
    { name: "Sushi", symbol: "SUSHIUSD" },

    { name: "Wonderland", symbol: "TIMEUSD" },
    { name: "Telos", symbol: "TLOSUSD" },
    { name: "Tron", symbol: "TRXUSD" },

    { name: "Solana", symbol: "SOLUSD" },

    { name: "Uniswap", symbol: "UNIUSD" },
    { name: "USD Coin", symbol: "USDCUSD" },
    { name: "Tether", symbol: "USDTUSD" },

    { name: "VeChain", symbol: "VETUSD" },

    { name: "Wing Finance", symbol: "WINGUSD" },

    { name: "Chain", symbol: "XCNUSD" },
    { name: "Stellar", symbol: "XLMUSD" },
    { name: "Monero", symbol: "XMRUSD" },
    { name: "Proton", symbol: "XPRUSD" },
    { name: "Tezos", symbol: "XTZUSD" },

    { name: "Zcash", symbol: "ZECUSD" },
    { name: "Zignaly", symbol: "ZIGUSD" },
  ];

  const handleTypeChange = (e) => {
    const type = e.target.value;
    switch (type) {
      case "Crypto":
        setTradeType("Crypto");
        break;
      case "Forex":
        setTradeType("Forex");
        break;
      case "Stocks":
        setTradeType("Stocks");
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      open={fixTrade}
      onClose={() => setFixTrade(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Fix a Trade</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setFixTrade(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <ContentWrapper>
            <ActionButtons>
              <button
                value="up"
                className={action === "up" ? "action buy" : "action"}
                onClick={(e) => {
                  setAction(e.target.value);
                }}
              >
                UP
              </button>
              <button
                value="down"
                className={action === "down" ? "action sell" : "action"}
                onClick={(e) => {
                  setAction(e.target.value);
                }}
              >
                DOWN
              </button>
            </ActionButtons>

            <div className="selectWrap">
              <label htmlFor="type">Type</label>
              <div className="selectBox">
                <div className="selectTop">
                  {/* <span> */}
                  <select name="type" onChange={handleTypeChange}>
                    {tradeTypes.map((type) => (
                      <option key={type}>{type}</option>
                    ))}
                  </select>
                  <img src="/extraicons/arrowdown.svg" alt="select" />
                </div>
              </div>
            </div>
            {tradeType === "Crypto" && (
              <FixCrypto action={action} assets={cryptoassets} user={user.id} open={{fixTrade, setFixTrade}}/>
            )}
            {tradeType === "Forex" && (
              <FixForex action={action} didReset={didReset} user={user.id} open={{fixTrade, setFixTrade}}/>
            )}
            {tradeType === "Stocks" && (
              <FixStocks action={action} assets={stockassets} user={user.id} open={{fixTrade, setFixTrade}}/>
            )}
          </ContentWrapper>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 1rem;
  display: grid;
  gap: 0.8rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      background-color: #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

const ModalOption = styled.div``;

export default FixTradeModal;
