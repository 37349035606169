import { Menu, SubText } from "../styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, query, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";
import ShowFXDetailsModal from "../modals/ShowFXDetailsModal";
import axios from "axios";
import TradeModal from "../modals/TradeModal";
import { ClickAwayListener, Grow } from "@mui/material";
import { useRef } from "react";
import toast from "react-hot-toast";
import AddProfitsExpertModal from "../modals/AddProfitsExpertModal";

const TradingTable = ({ id }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [trades, setTrades] = useState([]);
//   const { userId } = id;
  const [users, setUsers] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [tradeDetails, setTradeDetails] = useState([]);
  const [tradeAsset, setTradeAsset] = useState("");
  const [tradeInfo, setTradeInfo] = useState([]);
  const [tradeStats, setTradeStats] = useState({});
  const [showRegularTradeDetails, setShowRegularTradeDetails] = useState(false);
  const [regDetails, setRegDetails] = useState([]);
  const [regType, setRegType] = useState("");

  const [defaultExperts, setDefaultExperts] = useState([])

  const [subsList, setSubsList] = useState([])


  const cardRef = useRef();
  const [action, setAction] = useState("");
  const [activeRef, setActiveRef] = useState("");

  const [profits, setProfits] = useState(0)
  const [addProfits, setAddProfits] = useState(false);
  const [isAddingProfits, setIsAddingProfits] = useState(false);

  const [username, setUsername] = useState('')

  const [exName, setExName] = useState('')

  const [userID, setUserID] = useState('')

  useEffect(() => {
    const tradesList = [];
    async function getTrades() {
      const q = query(collection(db, "trades"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        tradesList.push(doc.data());
        setTrades(tradesList);
      });
    }
    getTrades();
  }, []);

  const [closedTrades, setClosedTrades] = useState([]);
  const [pendingTrades, setPendingTrades] = useState([]);

  useEffect(() => {
    const closedTradesList = [];
    const pendingTradesList = [];

    trades.forEach((trade) => {
      switch (trade.status) {
        case "open":
          pendingTradesList.push(trade);
          break;
        case "closed":
          closedTradesList.push(trade);
          break;
        default:
          break;
      }
    });

    setClosedTrades(closedTradesList);
    setPendingTrades(pendingTradesList);
  }, [trades, pendingTrades, closedTrades]);

  useEffect(() => {
    if (!trades) {
      setIsLoading(true);
    }

    if (trades) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [trades]);

  useEffect(() => {
    const userList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
        setUsers(userList);
      });
    }

    getUsers();
  }, []);


  async function fetchDocs() {
    const docRef = doc(db, "admin", "experts");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log("Document data:", docSnap.data());
      setDefaultExperts(docSnap.data());
    } else {
      console.log("No such document!");
    }
  }

  useEffect(() => {
    async function fetchDocs() {
      const docRef = doc(db, "admin", "experts");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        setDefaultExperts(docSnap.data());
      } else {
        console.log("No such document!");
      }
    }

    fetchDocs();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);


  useEffect(() => {

    const list = []

if(defaultExperts){

    const expertList =  Object.values(defaultExperts)
    
    expertList.forEach(expert => {
        if(expert.subscriberList.length) {
            for(let i =0; i < expert.subscriberList.length; i ++) {

            const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
            const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);
            
            const ref = expert.name.substring(0, 4) + randomOne.toString() + randomTwo.toString();

                const item = {ref, image: expert.picture, name: expert.name, user:expert.subscriberList[i], list: expert.subscriberList }
                list.push(item)
            }
            setSubsList(list)
        }
    })
}
  }, [defaultExperts])


  function handleCopy(ref){
    setActiveRef(ref)
  }

  function handleAction(act, ref, name, list, user){
    setActiveRef('')
    setAction(ref)

    setExName(name)

    if(act === "Add profits") {
        handleAddProfits(user)

        // setTimeout(() => {
        //     setAction('')
        //     setActiveRef('')
        // }, 1500)
    }


    if(act === "Cancel"){
        handleUnsubscribe(name, list, user)
    }
    
  }

  function handleAddProfits(user) {

    setIsAddingProfits(true);
    getProfits()


    users.forEach(name => {
        if(name.id === user){
            setUsername(name.firstname + "" + name.lastname)
        }
    })

    setUserID(user)

    async function getProfits(){
      const docRef = doc(db, "profits", user);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data()
        const {profits} = data;
        setProfits(profits)
        setIsAddingProfits(false)
        setAddProfits(true)
      } else {
        console.log("No such document!");
      }

    
  }
}


  function handleUnsubscribe(name, subscriberList, user) {

    const list = subscriberList.filter((sub) => sub !== user);

    setTimeout(() => {}, 600);

    setUnsubscribe(name, list);
  }

  async function setUnsubscribe(name, list) {
    const ref = doc(db, "admin", "experts");
    const index = `${name}.subscriberList`;

    await updateDoc(ref, {
      [index]: list,
    })
      .then(() => {
        toast.success("Copying canceled successfully");
        setAction('')
        fetchDocs();
      })
      .catch((error) => {
        toast.error("Please try again later");
        setAction('')
      });
  }

  return (
    <Wrapper>
      <SubText className="top">Trades</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          {showDetails && (
            <ShowFXDetailsModal
              open={{ showDetails, setShowDetails }}
              info={tradeDetails}
              asset={tradeAsset}
              trade={tradeInfo}
              stats={tradeStats}
             
            />
          )}

        {addProfits && (
            <AddProfitsExpertModal
              open={{ addProfits, setAddProfits }}
              user={userID}
              name={username}
              expert={exName}
              profits={profits}
              tradeAction={{action, setAction}}
            />
          )}

          {showRegularTradeDetails && (
            <TradeModal
              details={regDetails}
              type={regType}
              open={{ showRegularTradeDetails, setShowRegularTradeDetails }}
            />
          )}
          <table className="scrollbar-hidden">
            <thead>
              <tr>
                <th>Expert</th>
                {/* <th>Name</th> */}
                <th>User</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {subsList.map((sub) =>
                  <tr key={sub.ref} className="row">
                    <td>
                            <div className="name user">
                              {sub.image && (
                                <div style={{ position: "relative" }}>
                                  <img
                                    src={sub.image}
                                    alt="user"
                                    className="profile"
                                  />
                                </div>
                              )}

                              <p>{sub.name}</p>
                            </div>
                    </td>
                    <td className="name">
                      {
                        users.map(user => 
                            user.id === sub.user && (
                                <div className="name">
                                    <p>{user.firstname} {user.lastname}</p>
                                </div>
                            )
                            )
                      }
                    </td>

                    <td>
                    <div style={{ position: "relative" }}>
                          <button
                            disabled={action}
                            ref={cardRef}
                            className="pending"
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              padding: "0.21rem",
                              display: "grid",
                              placeContent: "center",
                              height: "42px",
                            }}
                            onClick={() =>
                              handleCopy(
                                sub.ref
                              )
                            }
                          >
                            {action === sub.ref ? (
                              <span
                                className="flex w-full"
                                style={{ placeContent: "center" }}
                              >
                                <img
                                  src="/svg-loaders/tail-spin.svg"
                                  alt="loading"
                                  width={24}
                                  height={24}
                                />
                              </span>
                            ) : (
                              <p>Copying</p>
                            )}
                          </button>
                          {activeRef === sub.ref && (
                            <>
                              <ClickAwayListener
                                onClickAway={() => setActiveRef("")}
                              >
                                <Grow
                                  in={activeRef === sub.ref}
                                  style={{ transformOrigin: "0 0 0 0" }}
                                  {...(activeRef === sub.ref
                                    ? { timeout: 300 }
                                    : {})}
                                >
                                  <div className="actionMenu">
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "Add profits",
                                          sub.ref,
                                          sub.name,
                                          sub.list,
                                          sub.user
                                        )
                                      }
                                    >
                                      Add profits
                                    </p>
                                    <p
                                      onClick={() =>
                                        handleAction(
                                          "Cancel",
                                          sub.ref,
                                          sub.name,
                                          sub.list,
                                          sub.user
                                        )
                                      }
                                    >
                                      Cancel
                                    </p>
                                  </div>
                                </Grow>
                              </ClickAwayListener>
                            </>
                          )}
                        </div> 
                    </td>


                  </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .actionMenu {
    appearance: none;
    position: absolute;
    left: -100px;
    z-index: 1000;
    top: -100px;
    /* bottom: -40px; */
    border: 0.5px solid #1f273a;
    background: #161c2a;
    border-radius: 8px;
    padding: 0.4rem;
    display: grid;
    gap: 0.5rem;

    p {
      padding: 0.5rem;
      cursor: pointer;
      transition: background-color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    p:hover {
      background-color: #1f273a;
      border-radius: 4px;
    }
  }

  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default TradingTable;
