import { tabs } from "../api/static";
import styled from "styled-components";
import {
  AdvancedRealTimeChart,
  
  MarketOverview,
} from "react-ts-tradingview-widgets";

const ChartContainer = () => {
  return (
    <Chart>
      <MarketOverview
        className="market"
        tabs={tabs}
        colorTheme="dark"
        height={371}
        width="100%"
        isTransparent
      ></MarketOverview>
      {/* <AdvancedRealTimeChart
        theme="dark"
        height={371}
        width="100%"
      ></AdvancedRealTimeChart> */}
    </Chart>
  );
};

const Chart = styled.div`
  grid-area: chart;
  padding-right: 10px;
  width: 100%;
  height: 371px;
`;

export default ChartContainer;
