import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";

const AddSignalModal = ({ open }) => {
  const { addSignal, setAddSignal } = open;
  const [name, setName] = useState(undefined);
  const [price, setPrice] = useState(undefined);
  const [isAdding, setIsAdding] = useState(false)

  function handleName(e) {
    const value = e.target.value;

    if (value) {
      setName(value);
    } else {
      setName("");
    }
  }

  function handlePrice(e) {
    const value = e.target.value;

    if (value) {
      setPrice(value);
    } else {
        setPrice("");
    }
  }

  async function handleAdd() {
    setIsAdding(true);

    const q = doc(db, "admin", "signals");
    try {
      await updateDoc(q, {
        [name]: {
          name,
          price,
        },
      });
      toast.success("Added successfully.");
      setIsAdding(false);
      setAddSignal(false)
    } catch (error) {
      console.log(error);
      toast.error("Could not add. Please try again later");
      setIsAdding(false);
    }
  }

  async function handleCancel() {
    setAddSignal(false);
  }

  return (
    <Modal
      open={addSignal}
      onClose={() => setAddSignal(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Add New Signal</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setAddSignal(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>

          <div className="addcon">
            <label htmlFor="new">Signal Name</label>
            <div className="add">
              <input
                onChange={(e) => handleName(e)}
                placeholder="CD V5"
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Price</label>
            <div className="add">
              <input
                onChange={(e) => handlePrice(e)}
                placeholder="1000"
                type='number'
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              className={
                !name ||
                !price ||
                isAdding
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !name ||
                !price ||
                isAdding
              }
              onClick={() => handleAdd()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isAdding ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Add</p>
              )}
            </button>

            <button
              onClick={() => handleCancel()}
              style={{
                width: "100%",
                backgroundColor: "#E64B60",
                color: "white",
                textTransform: "uppercase",
                height: "44px",
                display: "grid",
                placeItems: "center",
                fontWeight: "600",
                borderRadius: "8px",
              }}
            >
              Close
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default AddSignalModal;
