import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import {
  Dashboard,
  Register,
  Login,
  Deposit,
  Withdraw,
  Trading,
  Manage,
  Buy,
  Assets,
  Verify,
  Forgot,
  Subscribe,
  Profile,
  NFTs,
  Staking,
  CopyExperts,
} from "./pages/index";
import { Toaster } from "react-hot-toast";
import NewRegister from "./pages/NewRegister";
import NewVerify from "./pages/NewVerify";
import Markets from "./pages/Markets";
import Trades from "./pages/Trades";
import Signals from "./pages/Signals";
import SignalDeposit from "./pages/SignalDeposit";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase/firebase";
import { userContext } from "./context/userContext";
import { useContext, useEffect, useState } from "react";

function App() {
  const [user, loading] = useAuthState(auth);
  const { userData } = useContext(userContext);
  const [hasPopup, setHasPopup] = useState(false);
  const [text, setText] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    if (!loading && user && userData) {
      console.log("data is", userData);
      if (userData.popup) {
        setHasPopup(true);
        const { title, text } = userData.popup;
        setText(text);
        setTitle(title);
      }
    }
  }, [user, loading, userData]);
  return (
    <div className="App">
      {hasPopup && (
        <div className="bottom">
          <div
            style={{
              zIndex: "10000",
              position: "absolute",
              bottom: "30px",
              left: "30px",
            }}
          >
            <div
              style={{ color: "white", backgroundColor: "#1f273a" }}
              className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 mt-4"
            >
              <div className="flex-1 min-w-0">
                <span className="absolute inset-0" aria-hidden="true"></span>
                <p className="text-sm font-medium text-white">{title}</p>
                <p className="text-sm text-[#A3A3A3] truncate font-medium">
                  {text}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
          <Route path="/verify" element={<Verify />} />
          <Route path="/buy" element={<Buy />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/register" element={<Register />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/assets" element={<Assets />} />
          <Route path="/withdraw" element={<Withdraw />} />
          <Route path="/withdrawal" element={<Withdraw />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/trade" element={<Trading />} />
          <Route path="/manage" element={<Manage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/copy" element={<CopyExperts />} />
          <Route path="/stake" element={<Staking />} />
          <Route path="/nfts" element={<NFTs />} />
          <Route path="/newregister" element={<NewRegister />} />
          <Route path="/newverify" element={<NewVerify />} />
          <Route path="/markets" element={<Markets />} />
          <Route path="/trades" element={<Trades />} />
          <Route path="/signals" element={<Signals />} />
          <Route path="/signal-deposit" element={<SignalDeposit />} />
          <Route path="/trade/:type/:asset" element={<Trading />} />
          {/* <Route path="/extra/facebook" element={<Facebook />} /> */}
          {/* <Route path="/extra/twitter" element={<Twitter />} /> */}
        </Routes>
      </Router>
      <Toaster />
    </div>
  );
}

export default App;
