import styled from "styled-components";
import { useEffect, useState } from "react";
import { Content, OutlinedBox } from "../styles";
import { useAddress } from "@thirdweb-dev/react";
import CircularLoader from "../pages/CircularLoader";
import TransferNFTModal from "../modals/TransferNFTModal";
import ListNFTModal from "../modals/ListNFTModal";
import StakeNFTModal from "../modals/StakeNFTModal";
import { useMoralisWeb3Api } from "react-moralis";
import ReactImageFallback from "react-image-fallback";

const YourNFTs = ({ listings, market }) => {
  const [hiddenCollectionDesc, setHiddenCollectionDesc] = useState(true);
  const [hiddenCollectionDetails, setHiddenCollectionDetails] = useState(true);
  const [singleNft, setSingleNft] = useState(false);
  const [loader, setLoader] = useState(true);
  const [singleListing, setSingleListing] = useState([]);
  const [userNFTs, setUserNFTs] = useState([]);
  const [noImageNfts, setNoImageNfts] = useState([]);
  const [polygonNFTs, setPolygonNFts] = useState([]);
  const [ipfsNFTs, setIpfsNFTs] = useState([]);
  const [animatedNFTs, setAnimatedNFTs] = useState([]);
  const [bnbNFts, setBnbNFTs] = useState([]);
  const [doubleIpfs, setDoubleIpfs] = useState([]);
  const address = useAddress();
  const [hasNoNFTs, setHasNoNFTS] = useState(false);
  const [stakedNFTs, setStakedNFTs] = useState("");
  const [transferNFT, setTransferNFT] = useState(false);
  const [nftName, setNftName] = useState("");
  const [nftImage, setNftImage] = useState("");
  const [nftID, setNftID] = useState("");
  const [nftContract, setNftContract] = useState("");
  const [listNFT, setListNFT] = useState(false);
  const [stakeNFT, setStakeNFT] = useState(false);

  function handleListing(name, contract, id, image) {
    setNftContract(contract);
    setNftImage(image);
    setNftID(id);
    setNftName(name);
    setListNFT(true);
  }

  function handleTransfer(name, contract, id, image) {
    setNftContract(contract);
    setNftImage(image);
    setNftID(id);
    setNftName(name);

    setTransferNFT(true);
  }

  function handleStake(name, contract, id, image) {
    setNftContract(contract);
    setNftImage(image);
    setNftID(id);
    setNftName(name);
    setStakeNFT(true);
  }

  const Web3Api = useMoralisWeb3Api();

  useEffect(() => {
    async function fetchNFTS() {
      try {
        let nftList = [];
        let ipfsList = [];
        let doubleIpfsList = [];
        const userEthNFTs = await Web3Api.account.getNFTs({
          chain: "eth",
          address,
        });
        if (userEthNFTs) {
          const { result } = userEthNFTs;
          if (result.length < 1) {
            setHasNoNFTS(true);
          } else {
            result.forEach((nft) => {
              if (
                nft.metadata &&
                JSON.parse(nft.metadata).image &&
                String(JSON.parse(nft.metadata).image).includes("ipfs:") &&
                !String(JSON.parse(nft.metadata).image).includes("ipfs://ipfs")
              ) {
                ipfsList.push(nft);
              }

              if (
                nft.metadata &&
                JSON.parse(nft.metadata).image &&
                String(JSON.parse(nft.metadata).image).includes("ipfs://ipfs")
              ) {
                doubleIpfsList.push(nft);
              }

              if (
                nft.metadata &&
                JSON.parse(nft.metadata).image &&
                !String(JSON.parse(nft.metadata).image).includes("ipfs:") &&
                !String(JSON.parse(nft.metadata).image).includes("ipfs://ipfs")
              ) {
                nftList.push(nft);
              }
            });
            setIpfsNFTs(ipfsList);
            setUserNFTs(nftList);
            setDoubleIpfs(doubleIpfsList);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchNFTS();
  }, []);

  useEffect(() => {
    if (userNFTs) {
      setLoader(false);
    }
  }, [userNFTs]);

  return hasNoNFTs ? (
    <Content className="contentWallet">
      <div className="walletConnect">
        <OutlinedBox>
          <p className="infot">
            You currently have no NFTs on your address. Kindly visit the
            marketplace to purchase
          </p>
        </OutlinedBox>
      </div>
    </Content>
  ) : (
    <>
      {loader ? (
        <CircularLoader />
      ) : (
        <>
          {transferNFT && (
            <TransferNFTModal
              open={{ transferNFT, setTransferNFT }}
              name={nftName}
              image={nftImage}
              contract={nftContract}
              id={nftID}
            />
          )}
          {listNFT && (
            <ListNFTModal
              marketplace={market}
              open={{ listNFT, setListNFT }}
              name={nftName}
              image={nftImage}
              contract={nftContract}
              id={nftID}
            />
          )}
          {stakeNFT && (
            <StakeNFTModal
              marketplace={market}
              open={{ stakeNFT, setStakeNFT }}
              name={nftName}
              image={nftImage}
              contract={nftContract}
              id={nftID}
            />
          )}
          {userNFTs.map((nft) => (
            <Wrapper
              style={{ paddingBottom: "2rem", cursor: "pointer" }}
              key={nft.token_address + nft.token_id}
            >
              <img
                src={JSON.parse(nft.metadata).image}
                alt="asset"
                className="assetImage"
              />

              <div className="bottom">
                <div className="assetTitle">
                  <div className="cardLeft">
                    <div className="collectionTitle">
                      <p className="assetDecription fontBold">
                        {JSON.parse(nft.metadata).name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="options">
                <button
                  className="actionButton"
                  onClick={() =>
                    handleListing(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      JSON.parse(nft.metadata).image
                    )
                  }
                >
                  <p className="actionText">List</p>
                </button>
                <button
                  className="actionButton"
                  onClick={() =>
                    handleStake(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      JSON.parse(nft.metadata).image
                    )
                  }
                >
                  <p className="actionText">Stake</p>
                </button>
                <button
                  className="actionButton"
                  onClick={() =>
                    handleTransfer(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      JSON.parse(nft.metadata).image
                    )
                  }
                >
                  <p className="actionText">Transfer</p>
                </button>
              </div>
            </Wrapper>
          ))}
          {ipfsNFTs.map((nft) => (
            <Wrapper
              style={{ paddingBottom: "2rem", cursor: "pointer" }}
              key={nft.token_address + nft.token_id}
            >
              <ReactImageFallback
                src={`https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                  nft.metadata
                ).image.slice(7)}`}
                fallbackImage="/nfts/azuki.jpeg"
                initialImage="loader.gif"
                alt="asset"
                className="assetImage"
              />
              <div className="bottom">
                <div className="assetTitle">
                  <div className="cardLeft">
                    <div className="collectionTitle">
                      <p className="assetDecription fontBold">
                        {JSON.parse(nft.metadata).name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="options">
                <button
                  className="actionButton"
                  onClick={() =>
                    handleListing(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      `https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                        nft.metadata
                      ).image.slice(7)}`
                    )
                  }
                >
                  <p className="actionText">List</p>
                </button>
                <button
                  className="actionButton"
                  onClick={() =>
                    handleStake(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      `https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                        nft.metadata
                      ).image.slice(7)}`
                    )
                  }
                >
                  <p className="actionText">Stake</p>
                </button>
                <button
                  className="actionButton"
                  onClick={() =>
                    handleTransfer(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      `https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                        nft.metadata
                      ).image.slice(7)}`
                    )
                  }
                >
                  <p className="actionText">Transfer</p>
                </button>
              </div>
            </Wrapper>
          ))}
          {doubleIpfs.map((nft) => (
            <Wrapper
              style={{ paddingBottom: "2rem", cursor: "pointer" }}
              key={nft.token_address + nft.token_id}
            >
              <ReactImageFallback
                src={`https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                  nft.metadata
                ).image.slice(12)}`}
                fallbackImage="/nfts/azuki.jpeg"
                initialImage="loader.gif"
                alt="asset"
                className="assetImage"
              />

              <div className="bottom">
                <div className="assetTitle">
                  <div className="cardLeft">
                    <div className="collectionTitle">
                      <p className="assetDecription fontBold">
                        {JSON.parse(nft.metadata).name}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="options">
                <button
                  className="actionButton"
                  onClick={() =>
                    handleListing(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      `https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                        nft.metadata
                      ).image.slice(12)}`
                    )
                  }
                >
                  <p className="actionText">List</p>
                </button>
                <button
                  className="actionButton"
                  onClick={() =>
                    handleStake(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      `https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                        nft.metadata
                      ).image.slice(12)}`
                    )
                  }
                >
                  <p className="actionText">Stake</p>
                </button>
                <button
                  className="actionButton"
                  onClick={() =>
                    handleTransfer(
                      JSON.parse(nft.metadata).name,
                      nft.token_address,
                      nft.token_id,
                      `https://gateway.ipfscdn.io/ipfs/${JSON.parse(
                        nft.metadata
                      ).image.slice(12)}`
                    )
                  }
                >
                  <p className="actionText">Transfer</p>
                </button>
              </div>
            </Wrapper>
          ))}
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 360px !important;
  height: max-content;
  margin-top: 60px;
  justify-self: center;
  background: #131824;
  border-radius: 8px;

  .options {
    padding: 0.7rem;
    display: flex;
    gap: 0.3rem;
  }

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    place-self: center;
    padding: 0.5rem;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }

  .contentWallet {
    margin-top: 20px;
    height: 100%;
    overflow-x: hidden;
    padding: 20px;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    img {
      margin-top: 20px;
    }
  }

  .walletConnect {
    place-self: center;
    background: #161c2a;
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 8px;
    width: 360px;
    padding: 20px;
    margin-top: 50px;
    display: grid;
    gap: 1rem;
    justify-items: center;

    @media screen and (max-width: 800px) {
      width: 100%;
    }

    button {
      max-width: fit-content;
    }

    .texts {
      text-align: center;

      h1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 29px;
        color: #ffffff;
      }
    }

    .text {
      font-family: "Inter";
      font-style: normal;
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #c9c9c9;
    }
  }

  .traitsContainer {
    display: grid;
    gap: 0.25rem;
  }

  .traits {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .smallText {
    font-size: 12px;
  }
  .mediumText {
    font-size: 13px;
  }

  .fontSemi {
    font-weight: 600;
  }

  .fontBold {
    font-weight: 700;
  }

  .bottom {
    padding: 1rem;
    display: flex;
    gap: 30px;
  }

  .clickable {
    font-weight: 600;
    color: #1199fa;
    font-size: 14px;
    cursor: pointer;
  }

  .displayNone {
    display: none;
  }

  .collectionImage {
    width: 30px;
    border-radius: 100%;
    object-fit: contain;
  }

  .assetImage {
    width: 100%;
    height: 320px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    object-fit: cover;
  }

  .assetTitle {
    display: flex;
    justify-content: space-between;
  }

  .cardLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-start;
  }

  .cardRight {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 0.25rem;
  }

  .assetPrice {
    display: flex;
    box-sizing: border-box;
    gap: 0.25rem;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    p {
      font-weight: 700;
    }
  }

  .priceIcon {
    height: 16px;
    width: 16px;
  }

  .collectionDetails {
    display: grid;
    gap: 30px;
  }

  .aboutCollection {
    display: grid;
    gap: 0.25rem;
  }

  .title {
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .hidden {
    line-height: 1em;
    max-height: 2em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .aboutCollectionDesc {
  }
  .buttons {
    display: flex;
    gap: 0.5rem;

    .blue {
      color: #1199fa;
    }
  }
`;

export default YourNFTs;
