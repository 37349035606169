import Modal from "@mui/material/Modal";
import {
  BodyText,
  Card,
  OutlinedBox,
  OutlinedButton,
  RedButton,
  TitleText,
} from "../styles";
import styled from "styled-components";
import { db } from "../firebase/firebase";
import { deleteDoc, doc, where } from "firebase/firestore";
import toast from "react-hot-toast";
import { deleteUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const DeleteAccountModal = ({ open, user }) => {
  const { showDeleteAccountModal, setShowDeleteAccountModal } = open;
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [userAccount, setUserAccount] = useState("");

  function deleteUserAuth() {
    deleteUser(user)
      .then(() => {
        toast.success("Deleted account");
        deleteUserData(user);
      })
      .catch((error) => {
        toast.error(error.message);
        setIsDeleting(false);
      });
  }

  async function deleteUserData(user) {
    await deleteDoc(doc(db, "accounts", user.uid))
      .then(() => {
        navigate("/login");
        toast.success("Deleted successfully.");
      })
      .catch((error) => {
        toast.error(error.message);
        setIsDeleting(false);
      });
  }

  function deleteUserAccount() {
    setIsDeleting(true);
    deleteUserAuth();
  }

  return (
    <Modal
      open={showDeleteAccountModal}
      onClose={() => setShowDeleteAccountModal(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <TitleText>Delete your account</TitleText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setShowDeleteAccountModal(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <OutlinedBox>
            <BodyText className="infot">
              <span className="red">
                You have choosen to delete your account.
              </span>
              <br />
              <br />
              <span className="red">
                This action will delete all of your data and cannot be reversed.
              </span>
              <br />
              <br />
              <span className="red">Do you wish to continue?</span>
            </BodyText>
          </OutlinedBox>

          <div style={{ display: "flex", gap: "0.55rem" }}>
            <button
              style={{ backgroundColor: "#e64b60" }}
              className={isDeleting ? "button disabled" : "button submit"}
              disabled={isDeleting}
              onClick={deleteUserAccount}
            >
              {isDeleting ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              ) : (
                <p>Delete</p>
              )}
            </button>
            <OutlinedButton
              style={{ color: "white" }}
              onClick={() => setShowDeleteAccountModal(false)}
            >
              Cancel
            </OutlinedButton>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .box {
    background-color: #1f273a;
    border-radius: 4px;
    display: flex;
    place-content: center;
    cursor: pointer;
  }

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    place-self: center;
    padding: 0.5rem;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default DeleteAccountModal;
