import styled from "styled-components";
import { Header, Sidebar, SubscribeCard } from "../components/index";
import { useContext, useEffect, useState } from "react";
import { TitleText, SubText, Content } from "../styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { auth, db } from "../firebase/firebase";
import { useMediaQuery } from "@mui/material";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import SubscriptionHistory from "../usertables/SubscribeHistory";
import AddPlanModal from "../modals/AddPlanModal";
import { userContext } from "../context/userContext";
import toast from "react-hot-toast";

const Subscribe = () => {
  const { userData } = useContext(userContext);
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 800px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [plans, setPlans] = useState([]);
  const [addPlan, setAddPlan] = useState(false);

  useEffect(() => {
    async function fetchPlans() {
      const docRef = doc(db, "admin", "plans");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPlans(Object.values(docSnap.data()));
        setLoader(false);
      } else {
        console.log("No such document!");
      }
    }

    if (!loading && user) {
      fetchPlans();
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          {addPlan && <AddPlanModal open={{ addPlan, setAddPlan }} />}

          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Subscription"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Subscribe</TitleText>
              <ContentWrapper>
                <SubText>Plans</SubText>

                {userData.admin && (
                  <button
                    className="actionButton"
                    style={{ maxWidth: "max-content" }}
                    onClick={() => setAddPlan(true)}
                  >
                    <p className="actionText">Add new plan</p>
                  </button>
                )}

                <ContentContainer>
                  {plans.map((plan) => (
                    <SubscribeCard planDetails={plan} key={plan.name} />
                  ))}
                </ContentContainer>
              </ContentWrapper>
              <SubscriptionHistory user={user} />
              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  /* overflow: auto; */
`;

const Main = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  overflow-y: auto;

  .content {
    max-width: 100%;
    box-sizing: border-box;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  box-sizing: border-box;
  gap: 80px;
  height: fit-content;
  margin-top: 50px;
  padding-bottom: 60px;
  /* height: 100%; */

  label {
    color: #a3a3a3;
    font-weight: 600;
  }

  .bottomBox {
    display: grid;
    gap: 0.5rem;
  }

  .contentBox {
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    text-align: left;
    border: none;
  }

  .inputBox {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #1f273a;
    background-color: transparent;
    outline: none;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 1rem;
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: auto;
    justify-self: center;
  }
`;

export default Subscribe;
