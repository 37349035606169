import { doc, increment, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";
import {
  BlueButton,
  Card,
  DescBox,
  InfoBox,
  InputText,
  NeutralButton,
  RedButton,
  SubText,
  Switches,
  TitleText,
  DropDownIcon,
  DropDownBox,
  FeatureText,
} from "../styles";
import { countryFlags } from "../static";
import toast from "react-hot-toast";
import CountUp from "react-countup";
import { userContext } from "../context/userContext";
import ModalImage from "react-modal-image";

const Balances = ({ id, user }) => {
  const [accounts, setAccounts] = useState([]);
  const [account, setAccount] = useState([]);
  const [userData, setUserData] = useState([]);
  const [value, setValue] = useState("");
  const firstAsset = useRef();
  const [selectedAsset, setSelectedAsset] = useState("LTC");
  const [action, setAction] = useState("Add");
  const [isModifying, setIsModifying] = useState(false);
  const [assetType, setAssetType] = useState("Crypto");
  const balanceRef = useRef();
  const [fiatTotal, setFiatTotal] = useState("");
  const [stockTotal, setStockTotal] = useState("");
  const [cryptoTotal, setCryptoTotal] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [stockAccount, setStockAccount] = useState([]);
  const [cryptoAccount, setCryptoAccount] = useState([]);
  const { currentPrices } = useContext(userContext);
  const [amountExceedError, setAmountExcedError] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    if (user) {
      setUserData(user);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unsub = onSnapshot(doc(db, "accounts", user.id), (doc) => {
        setAccounts(doc.data());
      });
    }
  }, [user, accounts]);

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Stock, Crypto, Fiat } = live;
        const StockAccount = Object.values(Stock);
        const CryptoAccount = Object.values(Crypto);
        setAccount([...CryptoAccount, ...StockAccount, Fiat]);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (selectedAsset && account) {
      {
        account.forEach((acc) => {
          if (acc.asset === selectedAsset) {
            setBalance(acc.value);
          }
        });
      }
    }
  }, [selectedAsset, account]);

  useEffect(() => {
    function getTotalCrypto() {
      let CryptoTotal = 0;
      for (let i = 0; i < cryptoAccount.length; i++) {
        const price =
          currentPrices[cryptoAccount[i].asset] * cryptoAccount[i].value;
        CryptoTotal = CryptoTotal + price;
      }
      setCryptoTotal(CryptoTotal);
    }

    function getTotalStocks() {
      let StockTotal = 0;
      for (let i = 0; i < stockAccount.length; i++) {
        const price =
          currentPrices[stockAccount[i].asset] * stockAccount[i].value;
        StockTotal = StockTotal + price;
      }
      setStockTotal(StockTotal);
    }

    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Stock, Crypto, Fiat } = live;
        setStockAccount(Object.values(Stock));
        setCryptoAccount(Object.values(Crypto));
        getTotalCrypto();
        setFiatTotal(Fiat.value);
        getTotalStocks();
      }
    }
  }, [
    accounts,
    stockAccount,
    cryptoAccount,
    fiatTotal,
    stockTotal,
    cryptoTotal,
  ]);

  useEffect(() => {
    setTotalBalance(stockTotal + cryptoTotal + fiatTotal);
  }, [stockTotal, cryptoTotal, fiatTotal, totalBalance]);

  const handleIncrement = async (asset, type, amount, user) => {
    const q = doc(db, "accounts", user);
    const key =
      type === "Fiat" ? `live.${type}.value` : `live.${type}.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(amount),
      });

      toast.success("Modified successfully!");
      setIsModifying(false);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDecrement = async (asset, type, amount, user) => {
    const q = doc(db, "accounts", user);
    const key =
      type === "Fiat" ? `live.${type}.value` : `live.${type}.${asset}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(-amount),
      });

      toast.success("Modified successfully!");
      setIsModifying(false);

      reset();
    } catch (error) {
      console.log(error);
    }
  };

  function reset() {
    balanceRef.current.value = "";
    setValue("");
  }

  function handleModify(e) {
    e.preventDefault();
    setIsModifying(true);
    if (action === "Add") {
      handleIncrement(selectedAsset, assetType, Number(value), userData.id);
    }

    if (action === "Remove") {
      handleDecrement(selectedAsset, assetType, Number(value), userData.id);
    }
  }

  useEffect(() => {
    function getAssetType() {
      account.forEach((acc) => {
        if (acc.asset === selectedAsset) {
          setAssetType(acc.type);
        }
      });
    }

    if (selectedAsset) {
      getAssetType(selectedAsset);
    }
  }, [selectedAsset, account]);

  function handleValue(e) {
    const value = e.target.value;
    if (value > 0) {
      setValue(value);
    } else {
      setValue("");
    }

    if (action === "Remove" && value > balance) {
      setAmountExcedError(true);
    } else {
      setAmountExcedError(false);
    }
  }

  function handleActionChange(e) {
    const act = e.target.value;

    setAmountExcedError(false);
    setAction(act);
  }

  return (
    <ThreeColumnLayout>
      {/* <Wrapper> */}
      <div className="cards" style={{ height: "fit-content" }}>
        <Card style={{ height: "fit-content" }}>
          <SubText className="top">User</SubText>

          <div
            className="container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="about">
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "flex-start",
                  margin: "0",
                  padding: "0",
                }}
              >
                {user?.photoURL && (
                  <img
                    src={user?.photoURL}
                    alt="user"
                    style={{
                      pointerEvents: "none",
                      // position: "absolute",
                    }}
                    className="profile"
                  />
                )}

                <ModalImage
                  large={user.photoURL}
                  alt="verification"
                  className="modal"
                />

                {!user.photoURL && (
                  <>
                    {!userData.firstname ? (
                      <img src="/svg-loaders/tail-spin.svg" alt="loading" />
                    ) : (
                      <span className="avatar">
                        {userData.firstname?.slice(0, 1)}
                      </span>
                    )}
                  </>
                )}
              </div>

              <div className="desc">
                <p>
                  {userData.firstname} {userData.lastname}{" "}
                </p>
                <p>{userData.email} </p>
              </div>
            </div>
          </div>
        </Card>

        <Card>
          <SubText className="top">Balances</SubText>
          <div className="body" style={{ padding: "1rem" }}>
            <FeatureText style={{ display: "grid", gap: "0.45rem" }}>
              <p className="blue">Total Balance</p>
              <CountUp
                end={totalBalance}
                duration={1}
                delay={0}
                separator=","
                prefix="$"
              >
                {({ countUpRef }) => (
                  <div>
                    <span
                      style={{ fontSize: "28px", fontWeight: "600" }}
                      ref={countUpRef}
                    />
                  </div>
                )}
              </CountUp>
            </FeatureText>
          </div>
        </Card>

        <Card>
          <SubText className="top">Edit Balance</SubText>
          <div className="actions">
            <DataForm>
              <div
                className="assetSelect"
                style={{
                  boxSizing: "border-box",
                }}
              >
                <label htmlFor="asset">Asset</label>
                <div
                  className="selectBox"
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                >
                  <div className="selectTop">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                        width: "100%",
                        gap: "0.5rem",
                        boxSizing: "border-box",
                        padding: "1rem",
                      }}
                    >
                      {selectedAsset && (
                        <img
                          src={`/asseticons/${selectedAsset}.svg`}
                          alt="icon"
                          width={24}
                          height={24}
                        />
                      )}
                      <select
                        name="asset"
                        style={{
                          padding: "0",
                          width: "100%",
                          boxSizing: "border-box",
                          display: "flex",
                        }}
                        onChange={(e) => setSelectedAsset(e.target.value)}
                      >
                        {account.map((acc) => (
                          <option
                            value={acc.asset}
                            ref={firstAsset}
                            key={acc.asset}
                          >
                            {acc.asset}
                          </option>
                        ))}
                      </select>
                    </div>
                    <img
                      src="/extraicons/arrowdown.svg"
                      alt="select"
                      className="dropDownIcon"
                    />
                    {/* </span> */}
                  </div>
                  {selectedAsset && (
                    <p style={{ fontSize: "14px" }}>
                      Current balance ~ {balance} {selectedAsset}
                    </p>
                  )}
                </div>
              </div>

              <div className="selectWrap">
                <label htmlFor="type">Type</label>
                <div className="selectBox">
                  <div className="selectTop">
                    {/* <span> */}
                    <select name="type" onChange={handleActionChange}>
                      <option>Add</option>
                      <option>Remove</option>
                    </select>
                    <img src="/extraicons/arrowdown.svg" alt="select" />
                    {/* </span> */}
                  </div>
                </div>
              </div>

              <div className="amWrap">
                <label htmlFor="value">Value</label>
                <div className="amBx">
                  <div className="amTop">
                    <input
                      style={{ height: "100%" }}
                      placeholder="0.05"
                      name="value"
                      type="number"
                      onChange={handleValue}
                      ref={balanceRef}
                    />
                    <span className="coinSelector">
                      <img
                        src={`/asseticons/${selectedAsset}.svg`}
                        alt="asset"
                      />
                      <p>{selectedAsset}</p>
                    </span>
                  </div>

                  <div className="captions">
                    {value && (
                      <p className="extra">
                        Total in USD
                        <span className="green">
                          {" "}
                          ~$
                          {currentPrices[selectedAsset] * value}
                        </span>
                      </p>
                    )}
                  </div>

                  {amountExceedError && (
                    <p className="red">
                      You cannot remove more than the current balance.
                    </p>
                  )}
                </div>
              </div>

              <button
                style={{ display: "grid", placeContent: "center" }}
                disabled={
                  !value ||
                  !action ||
                  !selectedAsset ||
                  isModifying ||
                  amountExceedError
                }
                onClick={handleModify}
                className={
                  !value ||
                  !action ||
                  !selectedAsset ||
                  isModifying ||
                  amountExceedError
                    ? "button disabled"
                    : "button submit"
                }
              >
                {isModifying ? (
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                ) : (
                  <p>Modify</p>
                )}
              </button>
            </DataForm>
          </div>
        </Card>
      </div>

      <Card>
        <TitleText className="top" style={{ textTransform: "capitalize" }}>
          Assets
        </TitleText>

        <div className="assets">
          {account.map(
            (acc) =>
              acc.value > 0 && (
                <AssetBox key={acc.asset}>
                  <div className="assetBoxLeft">
                    <img
                      src={`/asseticons/${acc.asset}.svg`}
                      alt="asset-icon"
                    />
                    <p>{acc.asset}</p>
                  </div>
                  <p className="balance">{Number(acc.value).toFixed(2)}</p>
                </AssetBox>
              )
          )}
        </div>
      </Card>
    </ThreeColumnLayout>
  );
};

const ThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: 450px auto;
  grid-template-rows: auto auto;
  box-sizing: border-box;
  gap: 1rem;
  height: fit-content;
  margin-top: 50px;

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .assetSelect {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      background-color: #1f273a;
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      .dropDownIcon {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .assets {
    padding: 1rem;
    display: grid;
    gap: 1rem;
  }

  @media screen and (max-width: 900px) {
    display: grid;
    grid-template-columns: auto;
  }

  .settings {
    padding: 1rem;
    display: grid;
    box-sizing: border-box;
  }

  .cards {
    display: grid;
    gap: 2rem;
  }

  .actions {
    padding: 1rem;
    display: grid;
    gap: 2rem;
  }

  .about {
    display: flex;
    padding: 1rem;
    text-align: left;
    gap: 1rem;
    align-items: center;
    height: max-content;

    .img {
      height: 40px;
      width: 40px;
      background-color: red;
      border-radius: 100%;
    }

    .desc {
      display: grid;
      gap: 0.2rem;
    }
  }
`;

const AssetBox = styled.div`
  display: flex;
  justify-content: space-between;
  background: #1f273a;
  border: 1px solid #3a4867;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 1rem;
  align-items: center;

  .assetBoxLeft {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
`;

const DataForm = styled.form`
  display: grid;
  gap: 1rem;
  max-width: 100%;
  box-sizing: border-box;

  input {
    box-sizing: border-box;
    width: 100%;
  }
`;

const MultiInput = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  div {
    width: 100%;
  }

  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }
`;

const TextInput = styled.div`
  /* max-width: 360px; */
  box-sizing: border-box;
  display: grid;
  gap: 0.5rem;
  max-width: 100%;

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #a3a3a3;
  }

  input {
    display: grid;
    background: #1f273a;
    border: 1px solid #3a4867;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 1rem;
    font-size: 1rem;
    gap: 1rem;
    color: white;
    outline: none;
  }
`;

export default Balances;
