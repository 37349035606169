import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import {
  BlueButton,
  BodyText,
  Box,
  Card,
  InfoBox,
  OutlinedBox,
  OutlinedButton,
  SubText,
  TitleText,
} from "../styles";
import styled from "styled-components";
import toast from "react-hot-toast";

const TradeModal = ({ open, details, type }) => {
  const { showRegularTradeDetails, setShowRegularTradeDetails } = open;

  return (
    <Modal
      open={showRegularTradeDetails}
      onClose={() => setShowRegularTradeDetails(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Trade Info</SubText>

          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setShowRegularTradeDetails(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <BodyText>{type} Trade:</BodyText>
          {details.map((detail) => (
            <OutlinedBox className="box" key={detail.name}>
              <span className="option">
                <p className="blue textBold">{detail.name}:</p>
                <p style={{ fontSize: "14px" }}>{detail.value}</p>
              </span>
            </OutlinedBox>
          ))}

          <br />
          <button
            className="actionButton"
            onClick={() => setShowRegularTradeDetails(false)}
          >
            <p className="actionText">Hide</p>
          </button>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .box {
    border-radius: 4px;
    /* cursor: pointer; */
  }

  .option {
    display: grid;
    gap: 0.35rem;
    align-items: center;
    font-weight: 600;
    color: #c9c9c9;
    font-size: 14px;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default TradeModal;
