import React from "react";
import styled from "styled-components";

const Loader = () => {
  return (
    <Wrapper>
      <svg height="32" width="32" viewBox="0 0 24 24" className="loader">
        <circle opacity=".25" cx="12" cy="12" r="10.2"></circle>
        <circle
          cx="12"
          cy="12"
          r="10.2"
          strokeDasharray="32.044245066615886"
        ></circle>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background-color: #0e121b;
  display: grid;
  place-content: center;

  .loader {
    display: inline-block;
    fill: none;
    stroke: #1199fa;
    stroke-linecap: round;
    stroke-width: 3;
    flex: 0 0 auto;
    pointer-events: none;
    user-select: none;
    vertical-align: middle;
    animation: rotate 1s linear infinite, fade 0.2s ease-in-out;

    @keyframes rotate {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export default Loader;
