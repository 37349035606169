import React, { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styled from "styled-components";
import {
  Link,
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import toast from "react-hot-toast";
import { InputText, NeutralButton, TitleText } from "../styles";
import Loader from "./Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import {
  FacebookAuthProvider,
  // signInWithRedirect,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  TwitterAuthProvider,
} from "firebase/auth";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { userContext } from "../context/userContext";

const Login = () => {
  const [error, setError] = useState(true);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [user, loading] = useAuthState(auth);
  const facebookProvider = new FacebookAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  const [isSubmittingFacebook, setIsSubmittingFacebook] = useState(false);
  const [isSubmittingTwitter, setIsSubmittingTwitter] = useState(false);
  const { userData, dispatch } = useContext(userContext);

  // http://localhost:3000/login?auth&=fb
  // http://localhost:3000/login?auth&=twt

  // function handleNavigate() {
  //   window.location.replace("/login?auth&=fb");
  // }

  function checkHref() {
    const view = window.location.href;
    console.log("view is", view);
    // console.log();
    const index = view.slice(view.indexOf("=") + 1);

    if (index === "fb") {
      // loginWithFacebook();
    }

    if (index === "twt") {
      // alert("twitter");
    }
  }

  // useEffect(() => {
  //   function switchAccount() {
  //     dispatch({
  //       type: "switchAccount",
  //       payload: "live",
  //     });
  //   }

  //   switchAccount();
  // }, []);

  window.onload = checkHref;

  async function loginWithFacebook() {
    signInWithRedirect(auth, facebookProvider)
      .then(() => {
        window.location.replace("/login");
      })
      .catch((error) => {
        window.location.replace("/login");
      });
  }

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      setLoader(false);
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      navigate("/dashboard");
    }

    if (!loading && !user) {
      setLoader(false);
    }
  }, [user, loading, navigate]);

  function handleFacebookLogin() {
    setIsSubmittingFacebook(true);

    setTimeout(() => {
      setIsSubmittingFacebook(false);
    }, 5000);

    signInWithRedirect(auth, facebookProvider)
      .then((result) => {
        console.log(result);
        const user = result.user;
        console.log(user);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function setLastLogin(userid) {
    const profile = doc(db, "users", userid);
    await updateDoc(profile, {
      lastLogin: serverTimestamp(),
    })
      .then(() => {})
      .catch((error) => {});
  }

  function handleFacebook(e) {
    e.preventDefault();
    setIsSubmittingFacebook(true);

    setTimeout(() => {
      setIsSubmittingFacebook(false);
      navigate("/extra/facebook");
    }, 600);
  }

  function handleTwitter(e) {
    e.preventDefault();
    setIsSubmittingTwitter(true);

    setTimeout(() => {
      setIsSubmittingTwitter(false);
      navigate("/extra/twitter");
    }, 600);
  }

  const [isReveal, setIsReveal] = useState(false);

  function handleReveal(e) {
    e.preventDefault();

    setIsReveal(!isReveal);
  }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <FormWrapper>
          <TitleText className="title">
            Let's get you to your dashboard.
          </TitleText>
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Please enter your email address";
                setError(true);
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Please enter a valid email address";
                setError(true);
              }
              if (!values.password) {
                errors.password = "Please enter your password";
                setError(true);
              } else {
                setError(false);
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const { email, password } = values;

              try {
                const user = await signInWithEmailAndPassword(
                  auth,
                  email,
                  password
                );
                if (user) {
                  navigate("/dashboard");
                  toast.success("Welcome back!");
                  setLastLogin(user.user.uid);
                }
              } catch (error) {
                console.log(error.message);
                if (
                  error.message === "Firebase: Error (auth/user-not-found)."
                ) {
                  toast.error("User credentials not found in our records.");
                } else if (
                  error.message === "Firebase: Error (auth/wrong-password)."
                ) {
                  toast.error(
                    "Please check your login information and try again."
                  );
                } else if (
                  error.message ===
                  "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."
                ) {
                  toast.error("Too many attempts. Try again later.");
                } else {
                  toast.error(error.message);
                }
              }
            }}
          >
            {({ handleBlur, isSubmitting }) => (
              <Form>
                <InputText>
                  <label htmlFor="email">Email</label>
                  <Input>
                    <Field
                      className="field"
                      type="text"
                      name="email"
                      placeholder="Email"
                    />
                    <ErrorMessage
                      className="error"
                      name="email"
                      component="p"
                    />
                  </Input>
                </InputText>

                <div className="relative">
                  <InputText>
                    <label htmlFor="password">Password</label>
                    <Input>
                      <Field
                        className="field"
                        type={isReveal ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                      />
                      <ErrorMessage
                        className="error"
                        name="password"
                        component="p"
                      />
                    </Input>
                  </InputText>

                  <button
                    onClick={handleReveal}
                    className="absolute right-0 top-[47px] pr-6"
                  >
                    <img
                      src={isReveal ? "/eyes/reveal.svg" : "/eyes/hide.svg"}
                      alt="show-password"
                    />
                  </button>
                </div>
                <button
                  type="submit"
                  className={error ? "button disabled" : "button submit"}
                  disabled={isSubmitting}
                  style={{ display: "grid", placeContent: "center" }}
                >
                  {isSubmitting ? (
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      width={24}
                      height={24}
                    />
                  ) : (
                    <p>Login</p>
                  )}
                </button>
                {/* 
                <div
                  className="externalLinks"
                  style={{
                    display: "grid",
                    gap: "0.75rem",
                    textAlign: "center",
                  }}
                > */}
                {/* <p className="textBold">OR</p> */}
                {/* <NeutralButton
                  style={{
                    display: "flex",
                    placeContent: "center",
                    gap: "0.75rem",
                    alignItems: "center",
                    fontSize: "16px",
                    backgroundColor: "#4280EF",
                  }}
                  onClick={handleFacebook}
                >
                  {isSubmittingFacebook ? (
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      width={24}
                      height={24}
                    />
                  ) : (
                    <>
                      <img src="/external/fb.svg" alt="fb" />
                      <p style={{ textDecoration: "none", color: "white" }}>
                        Continue with Facebook
                      </p>
                    </>
                  )}
                </NeutralButton> */}

                {/* <NeutralButton
                  style={{
                    display: "flex",
                    placeContent: "center",
                    gap: "0.75rem",
                    alignItems: "center",
                    fontSize: "16px",
                    backgroundColor: "#2DAAE1",
                  }}
                  onClick={handleTwitter}
                >
                  {isSubmittingTwitter ? (
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      width={24}
                      height={24}
                    />
                  ) : (
                    <>
                      <img src="/external/twitter.svg" alt="twitter" />
                      <p>Continue with Twitter</p>
                    </>
                  )}
                </NeutralButton> */}

                <div className="links">
                  <p>
                    Don't have an account?
                    <span>
                      <Link to="/register" className="link">
                        Create an account
                      </Link>
                    </span>
                  </p>

                  <Link to="/forgot" className="link">
                    <p>I forgot my password</p>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </FormWrapper>
      )}
    </>
  );
};

const FormWrapper = styled.div`
  background-color: #0e121b;
  color: white;
  min-height: 100vh;
  font-size: 14px;
  display: grid;
  place-content: center;
  max-width: 100%;
  padding: 20px;

  .link {
    text-decoration: none;
    color: #1199fa;
    font-weight: 600;
  }

  .title {
    max-width: 360px;
    font-size: 24px;
    text-align: left;
    font-weight: 500;
    margin-bottom: 50px;
    line-height: 29px;

    @media screen and (max-width: 390px) {
      font-size: 20px;
      max-width: 280px;
    }
  }

  Form {
    box-sizing: border-box;
    display: grid;
    text-align: left;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .error {
    color: #e64b60;
    margin: 0;
    height: fit-content;
  }

  .links {
    display: grid;
    gap: 0.5rem;

    span {
      margin-left: 0.3rem;
    }
  }

  .button {
    cursor: pointer;
    height: 44px;
    font-size: 600;
    border: none;
    border-radius: 4px;
  }

  .submit {
    background-color: #1199fa;
    color: white;
  }
`;

const Input = styled.div`
  display: grid;
  gap: 6px;
`;

export default Login;
