import React from "react";
import styled from "styled-components";

const PriceView = ({ img, symbol, coin, price, convertPrice }) => {
  return (
    <PriceCard>
      <div className="left">
        <img
          src={symbol === "BTC" ? "asseticons/BTC.svg" : "asseticons/ETH.svg"}
          alt="h"
        />
        <p>{symbol}</p>
      </div>

      <div>
        <p className="price">{price}</p>
      </div>
      <div>
        <p className="price">{convertPrice}</p>
      </div>
      <DepositButton>Deposit</DepositButton>
    </PriceCard>
  );
};

const PriceCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #131824;

  .left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .price {
    width: 100%;
    box-sizing: border-box;
  }

  img {
    width: 25px;
    height: 25px;
  }
`;

const DepositButton = styled.button`
  font-size: 14px;
  border-radius: 0.5rem;
  background-color: #1199fa;
  color: white;
  padding: 7px 25px;
  border: none;
`;

export default PriceView;
