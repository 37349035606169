import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import styled from "styled-components";

const CircularLoader = () => {
  return (
    <Wrapper className="circleWrapper">
      <Box className="circleContainer">
        <CircularProgress className="circle" />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  place-content: center;
  display: flex;
  max-width: 100%;
  /* background-color: green; */
  border-radius: 4px;
  padding: 3rem;

  .circleContainer {
    max-width: fit-content;
    justify-self: center;
    display: flex;
    place-self: center;

    span {
      display: flex;
      width: fit-content;
      height: fit-content;
      place-content: center;
      align-items: center;
      display: flex;
      animation: none;
    }
  }

  .circle {
    height: fit-content;
    position: relative;
    padding: 0;
    margin: 0;
    color: #1199fa;
  }
`;

export default CircularLoader;
