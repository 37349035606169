import styled from "styled-components";
import { Sidebar, Header, MainCard } from "../components/index";
import { useEffect, useState } from "react";
import { SubText, Switches, TitleText } from "../styles";
import { Content } from "../styles";
import AssetTable from "../components/AssetTable";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { useMediaQuery } from "@mui/material";
import CircularLoader from "./CircularLoader";
import toast from "react-hot-toast";
import CountUp from "react-countup";
import axios from "axios";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

const Markets = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const { accounts } = useContext(userContext);
  const { currentAccount, currentPrices } = useContext(userContext);
  const [account, setAccount] = useState(null);
  const [stockAccount, setStockAccount] = useState(null);
  const [fiatAccount, setFiatAccount] = useState(null);
  const [cryptoAccount, setCryptoAccount] = useState(null);
  const [live, setLive] = useState(null);
  const [practice, setPractice] = useState(null);
  const navigate = useNavigate();
  const switchButtons = ["Crypto", "Stocks", "Currencies", "Watchlist"];
  const [activeButton, setActiveButton] = useState("Crypto");
  const { userData } = useContext(userContext);

  const [watchedCurrencies, setWatchedCurrencies] = useState([]);

  const [audPairs, setAudPairs] = useState(undefined);

  const [chfPairs, setChfPairs] = useState(undefined);

  const [eurPairs, setEurPairs] = useState(undefined);

  const [gbpPairs, setGbpPairs] = useState(undefined);

  const [nzdPairs, setNzdPairs] = useState(undefined);

  const [usdPairs, setUsdPairs] = useState(undefined);

  const fxPairs = [
    { name: "AUDCAD", symbol: "AUD/CAD" },
    { name: "AUDNZD", symbol: "AUD/NZD" },
    { name: "AUDJPY", symbol: "AUD/JPY" },
    { name: "AUDUSD", symbol: "AUD/USD" },

    { name: "CHFJPY", symbol: "CHF/JPY" },

    { name: "EURUSD", symbol: "EUR/USD" },
    { name: "EURCAD", symbol: "EUR/CAD" },
    { name: "EURAUD", symbol: "EUR/AUD" },
    { name: "EURJPY", symbol: "EUR/JPY" },
    { name: "EURCHF", symbol: "EUR/CHF" },
    { name: "EURGBP", symbol: "EUR/GBP" },

    { name: "GBPUSD", symbol: "GBP/USD" },
    { name: "GBPCHF", symbol: "GBP/CHF" },
    { name: "GBPJPY", symbol: "GBP/JPY" },

    { name: "NZDUSD", symbol: "NZD/USD" },

    { name: "USDCAD", symbol: "USD/CAD" },
    { name: "USDCHF", symbol: "USD/CHF" },
    { name: "USDGBP", symbol: "USD/GBP" },
    { name: "USDJPY", symbol: "USD/JPY" },
  ];

  async function fetchAudPairs() {
    const url = `https://api.fastforex.io/fetch-multi?from=AUD&to=CAD,NZD,JPY,USD&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { results } = response.data;
        setAudPairs(results);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchChfPairs() {
    const url = `https://api.fastforex.io/fetch-multi?from=CHF&to=JPY&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { results } = response.data;
        setChfPairs(results);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchEurPairs() {
    const url = `https://api.fastforex.io/fetch-multi?from=EUR&to=USD,CAD,AUD,JPY,CHF,GBP&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { results } = response.data;
        setEurPairs(results);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchGbpPairs() {
    const url = `https://api.fastforex.io/fetch-multi?from=GBP&to=USD,CHF,JPY&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { results } = response.data;
        setGbpPairs(results);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchNzdPairs() {
    const url = `https://api.fastforex.io/fetch-multi?from=NZD&to=USD&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { results } = response.data;
        setNzdPairs(results);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchUsdPairs() {
    const url = `https://api.fastforex.io/fetch-multi?from=USD&to=CAD,CHF,GBP,JPY&api_key=fddcbcbb7c-5c1d6d4099-rakaq9`;

    const config = {
      method: "get",
    };

    try {
      const response = await axios.get(url, config);
      if (response) {
        const { results } = response.data;
        setUsdPairs(results);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchAudPairs();
    fetchChfPairs();
    fetchEurPairs();
    fetchGbpPairs();
    fetchNzdPairs();
    fetchUsdPairs();
  }, []);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    // if (!loading && user && !userData.verified) {
    //   navigate("/newverify");
    //   toast.error("Verify your account to continue.");
    // }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      setLive(live);
      setPractice(practice);
    }
  }, [accounts]);

  useEffect(() => {
    if ((live, practice)) {
      setAccount(currentAccount === "live" ? live : practice);
    }

    if (account) {
      const { Crypto, Stock, Fiat } = account;
      setStockAccount(Object.values(Stock));
      setFiatAccount([Fiat]);
      setCryptoAccount(Object.values(Crypto));

      onSnapshot(doc(db, "watching", userData.id), (doc) => {
        // console.log("Current data: ", doc.data());

        const data = doc.data();

        if (data) {
          setWatchedCurrencies(data);
        }
      });
    }
  }, [currentAccount, account, accounts, live, practice]);

  // const incrementAsset = async () => {
  //   const q = doc(db, "accounts", "KLKYLZLJbZQuyNYvrvfkK8jhjNR2");
  //   try {
  //     await updateDoc(q, {
  //       "live.Crypto.BTC.value": increment(10),
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  function handleFavoriteCrypto(account) {
    if (account.watching) {
      removeCryptoFavoritePractice(account);
    } else {
      addCryptoFavoritePractice(account);
    }
  }

  async function removeCryptoFavoritePractice(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `practice.Crypto.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: false,
      }).then(() => {
        removeCryptoFavoriteLive(account);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function removeCryptoFavoriteLive(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `live.Crypto.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: false,
      }).then(() => {
        // toast.success("Removed from Watchlist");
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function addCryptoFavoritePractice(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `practice.Crypto.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: true,
      }).then(() => {
        addCryptoFavoriteLive(account);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function addCryptoFavoriteLive(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `live.Crypto.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: true,
      }).then(() => {
        // toast.success("Added to Watchlist");
      });
    } catch (error) {
      console.log(error);
    }
  }

  function handleFavoriteStock(account) {
    if (account.watching) {
      removeStockFavoritePractice(account);
    } else {
      addStockFavoritePractice(account);
    }
  }

  async function removeStockFavoritePractice(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `practice.Stock.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: false,
      }).then(() => {
        removeStockFavoriteLive(account);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function removeStockFavoriteLive(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `live.Stock.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: false,
      }).then(() => {
        // toast.success("Removed from Watchlist");
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function addStockFavoritePractice(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `practice.Stock.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: true,
      }).then(() => {
        addStockFavoriteLive(account);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function addStockFavoriteLive(account) {
    const q = doc(db, "accounts", userData.id);
    const key = `live.Stock.${account.asset}.watching`;
    try {
      await updateDoc(q, {
        [key]: true,
      }).then(() => {
        // toast.success("Added to Watchlist");
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function removeCurrency(name) {
    const q = doc(db, "watching", userData.id);
    const key = `${name}`;
    try {
      await updateDoc(q, {
        [key]: false,
      }).then(() => {
        // removeStockFavoriteLive(account);
        // toast.success("Removed from Watchlist");
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function addCurrency(name) {
    const q = doc(db, "watching", userData.id);
    const key = `${name}`;
    try {
      await updateDoc(q, {
        [key]: true,
      }).then(() => {
        // removeStockFavoriteLive(account);
        // toast.success("Added to Watchlist");
      });
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Markets"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Markets</TitleText>
              <ContentWrapper>
                {/* <div className="cardContainer">
                  <MainCard />
                </div> */}

                {/* <button onClick={incrementAsset}>increment</button> */}

                <div className="tables">
                  <Switches className="switches">
                    {switchButtons.map((button) => (
                      <button
                        key={button}
                        className={activeButton === button ? "active" : ""}
                        onClick={() => setActiveButton(button)}
                      >
                        {button}
                      </button>
                    ))}
                  </Switches>

                  <TableWrapper>
                    <SubText className="top">{activeButton}</SubText>
                    {activeButton === "Crypto" && (
                      <table className="desktopTable scrollbar-hidden">
                        {!mobile && (
                          <thead className="desktopHead">
                            <tr className="desktopRow">
                              {/* Desktop */}
                              {!mobile && <th className="star">Star</th>}
                              {!mobile && <th className="name">Asset</th>}
                              {!mobile && <th className="assetName">Name</th>}
                              {!mobile && <th className="value">Value</th>}
                              {!mobile && (
                                <th className="currentPrice">Current Price</th>
                              )}
                              {!mobile && <th className="currentPrice"></th>}
                            </tr>
                          </thead>
                        )}
                        <tbody className="desktopBody">
                          {cryptoAccount?.map(
                            (account) =>
                              account.tradable &&
                              account.watching && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      onClick={() =>
                                        navigate(`/trade/Crypto/${account.alt}`)
                                      }
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt={account?.asset}
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {account?.asset}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {account?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                      onClick={() =>
                                        navigate(`/trade/Crypto/${account.alt}`)
                                      }
                                    >
                                      <p>
                                        {currentPrices[account.asset]}
                                        {/* /{account.asset} */}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteCrypto(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteCrypto(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt=""
                                      />
                                      {/* </span> */}
                                      <p>{account.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{account.name}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      <p>{currentPrices[account.asset]}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(
                                            `/trade/Crypto/${account.alt}`
                                          )
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}

                          {cryptoAccount?.map(
                            (account) =>
                              account.tradable &&
                              !account.watching && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      onClick={() =>
                                        navigate(`/trade/Crypto/${account.alt}`)
                                      }
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt={account?.asset}
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {account?.asset}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {account?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                      onClick={() =>
                                        navigate(`/trade/Crypto/${account.alt}`)
                                      }
                                    >
                                      <p>
                                        {currentPrices[account.asset]}
                                        {/* /{account.asset} */}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteCrypto(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteCrypto(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt=""
                                      />
                                      {/* </span> */}
                                      <p>{account.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{account.name}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      <p>{currentPrices[account.asset]}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(
                                            `/trade/Crypto/${account.alt}`
                                          )
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    )}

                    {activeButton === "Stocks" && (
                      <table className="desktopTable scrollbar-hidden">
                        {!mobile && (
                          <thead className="desktopHead">
                            <tr className="desktopRow">
                              {/* Desktop */}
                              {!mobile && <th className="star">Star</th>}
                              {!mobile && <th className="name">Asset</th>}
                              {!mobile && <th className="assetName">Name</th>}
                              {!mobile && <th className="value">Value</th>}
                              {!mobile && (
                                <th className="currentPrice">Current Price</th>
                              )}
                              {!mobile && <th className="currentPrice"></th>}
                            </tr>
                          </thead>
                        )}
                        <tbody className="desktopBody">
                          {stockAccount?.map(
                            (account) =>
                              account.watching && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/trade/Stocks/${account.asset}`
                                        )
                                      }
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt={account?.asset}
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {account?.asset}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {account?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/trade/Stocks/${account.asset}`
                                        )
                                      }
                                    >
                                      <p>
                                        {currentPrices[account.asset]}
                                        {/* /{account.asset} */}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteStock(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteStock(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt=""
                                      />
                                      {/* </span> */}
                                      <p>{account.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{account.name}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      <p>{currentPrices[account.asset]}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(
                                            `/trade/Stocks/${account.asset}`
                                          )
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}

                          {stockAccount?.map(
                            (account) =>
                              !account.watching && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/trade/Stocks/${account.asset}`
                                        )
                                      }
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt={account?.asset}
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {account?.asset}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {account?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/trade/Stocks/${account.asset}`
                                        )
                                      }
                                    >
                                      <p>
                                        {currentPrices[account.asset]}
                                        {/* /{account.asset} */}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteStock(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteStock(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt=""
                                      />
                                      {/* </span> */}
                                      <p>{account.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{account.name}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="value">
                                      <CountUp
                                        end={account.value}
                                        duration={1}
                                        delay={0}
                                        separator=","
                                        suffix={" " + account.asset}
                                      >
                                        {({ countUpRef }) => (
                                          <div>
                                            <span
                                              className="bottomText bigText"
                                              ref={countUpRef}
                                            />
                                          </div>
                                        )}
                                      </CountUp>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      <p>{currentPrices[account.asset]}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(
                                            `/trade/Stocks/${account.asset}`
                                          )
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    )}

                    {activeButton === "Currencies" && (
                      <table className="desktopTable scrollbar-hidden">
                        {!mobile && (
                          <thead className="desktopHead">
                            <tr className="desktopRow">
                              {/* Desktop */}
                              {!mobile && <th className="star">Star</th>}
                              {!mobile && <th className="name">Asset</th>}
                              {!mobile && <th className="assetName">Name</th>}
                              {/* {!mobile && <th className="value">Value</th>} */}
                              {!mobile && (
                                <th className="currentPrice">Current Price</th>
                              )}
                              {!mobile && <th className="currentPrice"></th>}
                            </tr>
                          </thead>
                        )}
                        <tbody className="desktopBody">
                          {fxPairs.map(
                            (pair) =>
                              watchedCurrencies &&
                              watchedCurrencies[pair.name] && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${pair?.name}.svg`}
                                        alt={pair?.symbol}
                                        style={{
                                          width: "40px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {pair?.symbol}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {pair?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                    >
                                      <p>
                                        {pair.name.slice(0, -3) === "AUD" &&
                                          audPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "CHF" &&
                                          chfPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "EUR" &&
                                          eurPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "GBP" &&
                                          gbpPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "NZD" &&
                                          nzdPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "USD" &&
                                          usdPairs[pair.name.slice(3)]}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{ maxWidth: "max-content" }}
                                      onClick={() => removeCurrency(pair.name)}
                                    >
                                      <span className="icon">
                                        <svg
                                          width="20"
                                          className="stroke-[none] hover:stroke-white"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="#FFB266"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            transition: "all 0.3s ease-in-out",
                                          }}
                                        >
                                          <path
                                            d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      className="star"
                                      onClick={() => removeCurrency(pair.name)}
                                    >
                                      <span className="icon">
                                        <span className="icon">
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${pair.name}.svg`}
                                        alt=""
                                        style={{
                                          width: "40px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <p>{pair.symbol}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{pair.name}</p>
                                    </td>
                                  )}

                                  {/* {!mobile && (
                                <td className="value">
                                
                                </td>
                              )} */}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      {pair.name.slice(0, -3) === "AUD" &&
                                        audPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "CHF" &&
                                        chfPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "EUR" &&
                                        eurPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "GBP" &&
                                        gbpPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "NZD" &&
                                        nzdPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "USD" &&
                                        usdPairs[pair.name.slice(3)]}
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(`/trade/Forex/${pair.name}`)
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}

                          {fxPairs.map(
                            (pair) =>
                              !watchedCurrencies ||
                              (!watchedCurrencies[pair.name] && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                      onClick={() =>
                                        navigate(`/trade/Forex/${pair.name}`)
                                      }
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${pair?.name}.svg`}
                                        alt={pair?.symbol}
                                        style={{
                                          width: "40px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {pair?.symbol}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {pair?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                      onClick={() =>
                                        navigate(`/trade/Forex/${pair.name}`)
                                      }
                                    >
                                      <p>
                                        {pair.name.slice(0, -3) === "AUD" &&
                                          audPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "CHF" &&
                                          chfPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "EUR" &&
                                          eurPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "GBP" &&
                                          gbpPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "NZD" &&
                                          nzdPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "USD" &&
                                          usdPairs[pair.name.slice(3)]}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{ maxWidth: "max-content" }}
                                      onClick={() => addCurrency(pair.name)}
                                    >
                                      <span className="icon">
                                        <svg
                                          width="20"
                                          className="stroke-[#a3a3a3] hover:stroke-white"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            transition: "all 0.3s ease-in-out",
                                          }}
                                        >
                                          <path
                                            d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      className="star"
                                      onClick={() => addCurrency(pair.name)}
                                    >
                                      {/* <span className="icon"> */}
                                      <svg
                                        width="20"
                                        className="stroke-[#a3a3a3] hover:stroke-white"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                          transition: "all 0.3s ease-in-out",
                                        }}
                                      >
                                        <path
                                          d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      {/* </span> */}
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      <span className="icon">
                                        <img
                                          src={`/asseticons/${pair.name}.svg`}
                                          alt=""
                                        />
                                      </span>
                                      <p>{pair.symbol}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{pair.name}</p>
                                    </td>
                                  )}

                                  {/* {!mobile && (
                                <td className="value">
                                
                                </td>
                              )} */}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      {pair.name.slice(0, -3) === "AUD" &&
                                        audPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "CHF" &&
                                        chfPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "EUR" &&
                                        eurPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "GBP" &&
                                        gbpPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "NZD" &&
                                        nzdPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "USD" &&
                                        usdPairs[pair.name.slice(3)]}
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(`/trade/Forex/${pair.name}`)
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))
                          )}
                        </tbody>
                      </table>
                    )}

                    {activeButton === "Watchlist" && (
                      <table className="desktopTable scrollbar-hidden">
                        {!mobile && (
                          <thead className="desktopHead">
                            <tr className="desktopRow">
                              {/* Desktop */}
                              {!mobile && <th className="star">Star</th>}
                              {!mobile && <th className="name">Asset</th>}
                              {!mobile && <th className="assetName">Name</th>}
                              {!mobile && (
                                <th className="currentPrice">Current Price</th>
                              )}
                              {!mobile && <th className="currentPrice"></th>}
                            </tr>
                          </thead>
                        )}
                        <tbody className="desktopBody">
                          {cryptoAccount?.map(
                            (account) =>
                              account.tradable &&
                              account.watching && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      onClick={() =>
                                        navigate(`/trade/Crypto/${account.alt}`)
                                      }
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt={account?.asset}
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {account?.asset}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {account?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                      onClick={() =>
                                        navigate(`/trade/Crypto/${account.alt}`)
                                      }
                                    >
                                      <p>
                                        {currentPrices[account.asset]}
                                        {/* /{account.asset} */}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteCrypto(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteCrypto(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt=""
                                      />
                                      {/* </span> */}
                                      <p>{account.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{account.name}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      <p>{currentPrices[account.asset]}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(
                                            `/trade/Crypto/${account.alt}`
                                          )
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}

                          {stockAccount?.map(
                            (account) =>
                              account.watching && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/trade/Stocks/${account.asset}`
                                        )
                                      }
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt={account?.asset}
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {account?.asset}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {account?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/trade/Stocks/${account.asset}`
                                        )
                                      }
                                    >
                                      <p>
                                        {currentPrices[account.asset]}
                                        {/* /{account.asset} */}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteStock(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      style={{
                                        maxWidth: "max-content",
                                        zIndex: "4",
                                      }}
                                      onClick={() =>
                                        handleFavoriteStock(account)
                                      }
                                    >
                                      <span className="icon">
                                        {!account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[#a3a3a3] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}

                                        {account.watching && (
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              // stroke="white"

                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${account?.asset}.svg`}
                                        alt=""
                                      />
                                      {/* </span> */}
                                      <p>{account.asset}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{account.name}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      <p>{currentPrices[account.asset]}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(
                                            `/trade/Stocks/${account.asset}`
                                          )
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}

                          {fxPairs.map(
                            (pair) =>
                              watchedCurrencies &&
                              watchedCurrencies[pair.name] && (
                                <tr
                                  className="desktopRow hover:bg-[#19202f] cursor-pointer"
                                  style={{
                                    width: mobile && "100%",
                                    display: mobile && "flex",
                                    justifyContent: mobile && "space-between",
                                  }}
                                >
                                  {mobile && (
                                    <td
                                      className="flex items-center gap-[8px]"
                                      style={{
                                        maxWidth: "100px",
                                        width: "100%",
                                      }}
                                    >
                                      {/* <span className="icon "> */}
                                      <img
                                        src={`/asseticons/${pair?.name}.svg`}
                                        alt={pair?.symbol}
                                        style={{
                                          width: "40px",
                                          height: "24px",
                                        }}
                                      />
                                      {/* </span> */}
                                      <span className="grid gap-[8px]">
                                        <p className="text-[14px]">
                                          {pair?.symbol}
                                        </p>
                                        <p className="text-[14px] text-[#a3a3a3]">
                                          {pair?.name}
                                        </p>
                                      </span>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{
                                        maxWidth: "40px",
                                        width: "100%",
                                        fontSize: "16px",
                                        justifyItems: "flex-start",
                                      }}
                                    >
                                      <p>
                                        {pair.name.slice(0, -3) === "AUD" &&
                                          audPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "CHF" &&
                                          chfPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "EUR" &&
                                          eurPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "GBP" &&
                                          gbpPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "NZD" &&
                                          nzdPairs[pair.name.slice(3)]}

                                        {pair.name.slice(0, -3) === "USD" &&
                                          usdPairs[pair.name.slice(3)]}
                                      </p>
                                    </td>
                                  )}

                                  {mobile && (
                                    <td
                                      style={{ maxWidth: "max-content" }}
                                      onClick={() => removeCurrency(pair.name)}
                                    >
                                      <span className="icon">
                                        <svg
                                          width="20"
                                          className="stroke-[none] hover:stroke-white"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="#FFB266"
                                          xmlns="http://www.w3.org/2000/svg"
                                          style={{
                                            transition: "all 0.3s ease-in-out",
                                          }}
                                        >
                                          <path
                                            d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </span>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td
                                      className="star"
                                      onClick={() => removeCurrency(pair.name)}
                                    >
                                      <span className="icon">
                                        <span className="icon">
                                          <svg
                                            width="20"
                                            className="stroke-[none] hover:stroke-white"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="#FFB266"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{
                                              transition:
                                                "all 0.3s ease-in-out",
                                            }}
                                          >
                                            <path
                                              d="M8.54499 1.30479C8.58194 1.21465 8.64488 1.13753 8.72579 1.08326C8.8067 1.02898 8.90192 1 8.99935 1C9.09678 1 9.192 1.02898 9.27291 1.08326C9.35382 1.13753 9.41676 1.21465 9.45371 1.30479L11.3105 5.77066C11.3452 5.85422 11.4024 5.92658 11.4756 5.97976C11.5488 6.03295 11.6353 6.0649 11.7255 6.07211L16.547 6.45832C16.983 6.49327 17.1595 7.03763 16.8275 7.32161L13.1541 10.4689C13.0855 10.5277 13.0344 10.6041 13.0063 10.69C12.9783 10.7758 12.9744 10.8677 12.9951 10.9556L14.1179 15.6609C14.1405 15.7553 14.1346 15.8543 14.1009 15.9453C14.0673 16.0364 14.0074 16.1154 13.9289 16.1724C13.8503 16.2295 13.7567 16.2619 13.6597 16.2658C13.5627 16.2696 13.4667 16.2446 13.384 16.1939L9.25537 13.6731C9.17824 13.6261 9.08967 13.6012 8.99935 13.6012C8.90903 13.6012 8.82046 13.6261 8.74333 13.6731L4.61475 16.1948C4.53197 16.2455 4.43602 16.2705 4.33904 16.2666C4.24205 16.2628 4.14837 16.2303 4.06984 16.1733C3.9913 16.1163 3.93142 16.0372 3.89778 15.9462C3.86413 15.8552 3.85821 15.7562 3.88078 15.6618L5.00358 10.9556C5.0244 10.8677 5.02058 10.7758 4.99252 10.6899C4.96446 10.6041 4.91326 10.5276 4.84455 10.4689L1.1712 7.32161C1.09764 7.25835 1.04443 7.17476 1.01825 7.08135C0.99207 6.98793 0.994097 6.88886 1.02407 6.7966C1.05405 6.70433 1.11064 6.62298 1.18673 6.56279C1.26281 6.5026 1.355 6.46625 1.45169 6.45832L6.27317 6.07211C6.36339 6.0649 6.44987 6.03295 6.5231 5.97976C6.59632 5.92658 6.65346 5.85422 6.68822 5.77066L8.54499 1.30567V1.30479Z"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            />
                                          </svg>
                                        </span>
                                      </span>
                                    </td>
                                  )}
                                  {!mobile && (
                                    <td className="asset">
                                      {/* <span className="icon"> */}
                                      <img
                                        src={`/asseticons/${pair.name}.svg`}
                                        alt=""
                                      />
                                      {/* </span> */}
                                      <p>{pair.symbol}</p>
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td className="assetName">
                                      <p>{pair.name}</p>
                                    </td>
                                  )}

                                  {/* {!mobile && (
                                <td className="value">
                                
                                </td>
                              )} */}

                                  {!mobile && (
                                    <td className="currentPrice">
                                      {pair.name.slice(0, -3) === "AUD" &&
                                        audPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "CHF" &&
                                        chfPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "EUR" &&
                                        eurPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "GBP" &&
                                        gbpPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "NZD" &&
                                        nzdPairs[pair.name.slice(3)]}

                                      {pair.name.slice(0, -3) === "USD" &&
                                        usdPairs[pair.name.slice(3)]}
                                    </td>
                                  )}

                                  {!mobile && (
                                    <td>
                                      <button
                                        className="actionButton"
                                        onClick={() =>
                                          navigate(`/trade/Forex/${pair.name}`)
                                        }
                                      >
                                        <p className="actionText">Trade</p>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    )}
                  </TableWrapper>

                  {/* {activeButton === "Trade" && (
                    <>
                      {fiatAccount ? (
                        <AssetTable
                          title="Fiat"
                          account={fiatAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}

                      {cryptoAccount ? (
                        <AssetTable
                          title="Crypto"
                          account={cryptoAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}

                      {stockAccount ? (
                        <AssetTable
                          title="Stocks"
                          account={stockAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}
                    </>
                  )}  */}
                </div>
              </ContentWrapper>
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Main = styled.div`
  width: 100vw;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100vh;

  .content {
    max-width: 100%;
    overflow-x: auto;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 2.5rem;
  margin-top: 50px;
  width: 100%;
  padding-bottom: 2rem;

  .tables {
    width: 100%;
    overflow-x: auto;
  }

  .switches {
    max-width: 280px;
  }

  .cardContainer {
    max-width: 280px;
  }

  .searchContainer {
    max-width: 280px;
  }

  @media screen and (max-width: 800px) {
    .cardContainer {
      max-width: 100%;
    }

    .searchContainer {
      max-width: 100%;
    }
  }

  .card {
    box-sizing: border-box;
    max-width: 100%;
  }

  .bodyText {
    font-size: 18px;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }
`;

const TableWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;

  .mobileValue {
    display: none;
  }

  @media screen and (max-width: 800px) {
    .value,
    .estimate,
    .assetName,
    .currentPrice {
      display: none;
    }

    .mobileValue {
      display: flex;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .desktopTable {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    width: 100%;
    align-items-center;
    
    th,
    td {
      width: 120px;
      text-align: left;
      box-sizing: border-box;
    }

    .star {
      width: 30px;
    }
  }

  .desktopHead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  .desktopBody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  .desktopRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }

  .mobileTable {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    width: 100%;

    .mobileBody {
      font-weight: 510;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
    }

    td,
    th {
      /* background-color: red; */
      box-sizing: border-box;
      display: flex;
      width: 100px;
    }
  }
`;

export default Markets;
