import styled from "styled-components";
import { Header, Sidebar } from "../components/index";
import { useContext, useEffect, useState } from "react";
import { TitleText, BodyText, SubText, Content } from "../styles";
import Loader from "./Loader";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";
import { userContext } from "../context/userContext";
import { doc, onSnapshot } from "firebase/firestore";

const Buy = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(user !== null ? false : true);
  const { userData } = useContext(userContext);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  useEffect(() => {
    if (user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        const data = doc.data();

        if (data && !data.verified) {
          navigate("/newverify");
          toast.error("Verify your account to continue.");
        }
      });
    }
  }, [user]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Buy Bitcoin"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content>
              <TitleText>Buy Bitcoin</TitleText>
              <ContentWrapper>
                <SubText>Links</SubText>
                <SubmitContent>
                  <ContentContainer>
                    <BodyText className="body">
                      Tap on any of the links below to purchase bitcoin from our
                      partners.
                    </BodyText>

                    <div className="inputBox">
                      <a href="https://coinbase.com" target="_blank">
                        Coinbase
                      </a>
                    </div>

                    <div className="inputBox">
                      <a href="https://abra.com" target="_blank">
                        Abra
                      </a>
                    </div>
                    <div className="inputBox">
                      <a href="https://bitcoin.org" target="_blank">
                        Bitcoin
                      </a>
                    </div>

                    <div className="inputBox">
                      <a href="https://moonpay.com" target="_blank">
                        Moonpay
                      </a>
                    </div>

                    <div className="inputBox">
                      <a href="https://blockchain.com" target="_blank">
                        Blockchain
                      </a>
                    </div>
                  </ContentContainer>
                </SubmitContent>
              </ContentWrapper>
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Main = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;

const ContentWrapper = styled.div`
  display: grid;
  box-sizing: border-box;
  gap: 1rem;
  height: fit-content;
  margin-top: 50px;
  width: 100%;

  a {
    text-decoration: none;
    color: white;

    &:hover {
      color: #1199fa;
    }
  }

  .inputBox {
    width: 100%;
    box-sizing: border-box;
    background: #1f273a;
    font-size: 16px;
    outline: none;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 1rem;
    font-size: 16px;
    font-weight: 600;
  }
`;
const ContentContainer = styled.div`
  max-width: 360px;
  margin-top: 20px;
  box-sizing: border-box;
  display: grid;
  gap: 1rem;

  /* .body {
    margin-bottom: 30px;
  } */

  .box {
    background-color: #93a2c3;
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
  }
`;

const SubmitContent = styled.div`
  border-radius: 0.5rem;
  background-color: #161c2a;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  padding: 50px 0;
`;

export default Buy;
