import {  useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";

const PopupModal = ({ open, user }) => {
  const { popup, setPopup } = open;
  const [isUpgrading, setIsUpgrading] = useState(false);

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')

  const [isRemovingPopup, setIsRemovingPopup] = useState(false);
  const [removedPopup, setRemovedPopup] = useState(false);


  function handleTitle(e) {
    const value = e.target.value;

    if (value) {
      setTitle(value);
    } else {
      setTitle(user.popup.title);
    }
  }

  function handleText(e) {
    const value = e.target.value;

    if (value) {
      setText(value);
    } else {
      setText(user.popup.text);
    }
  }


  async function handlePopup () {
    setIsUpgrading(true)

    const q = doc(db, "users", user.id);
    try {
      await updateDoc(q, {
        popup: {
            title,
            text
        },
      });
      toast.success("Sent successfully!");
      setIsUpgrading(false)
      setPopup(false)
    } catch (error) {
      console.log(error);
      toast.error("Could not add pop-up")
    }
  };


  async function handleRemovePopup () {
    setIsRemovingPopup(true)
    const q = doc(db, "users", user.id);
    try {
      await updateDoc(q, {
        popup: false,
      });
      toast.success("Removed successfully!");
      setPopup(false);
      setRemovedPopup(true)
      setIsRemovingPopup(false)
    } catch (error) {
      console.log(error);
      toast.error("Could not remove pop-up")
    }
  };


  return (
    <Modal
      open={popup}
      onClose={() => setPopup(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Send Pop-up</SubText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setPopup(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div className="addcon">
            <label htmlFor="new">Title</label>
            <div className="add">
              <input
                placeholder={"Deposit now"}
                // defaultValue={user?.popup.title ? user.popup.title : "Deposit now"}
                onChange={handleTitle}
              />
            </div>
          </div>

        <div className="addcon">
            <label htmlFor="new">Message</label>
            <div className="add">
              <input
                onChange={handleText}
                placeholder={"Make a deposit"}
                // defaultValue={user?.popup.title ? user.popup.title : "Make a deposit"}
              />
            </div>
          </div>


<div className="flex space-x-4">

<button
            className={isRemovingPopup ? "button disabled" : "button submit"}
            style={{
                // display: "grid",
                // textAlign: "left",
                // placeContent: "center",
                backgroundColor: "#E64B60",
              }}
            disabled={isRemovingPopup}
            onClick={handleRemovePopup}
          >
          {isRemovingPopup ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                style={{
                  padding: "10px",
                  width: "20px",
                  height: "auto",
                }}
              />
            ) : (
              <p style={{ textDecoration: "none", color: "white",maxWidth: "max-content", placeSelf: "center" }}>Delete</p>
            )}
          </button>

          <button
            className={!title || !text ? "button disabled" : "button submit"}
            disabled={isUpgrading || !title || !text}
            onClick={handlePopup}
          
          >
            {isUpgrading ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                style={{
                  padding: "10px",
                  width: "20px",
                  height: "auto",
                }}
              />
            ) : (
              <p style={{ textDecoration: "none", color: "white",maxWidth: "max-content", placeSelf: "center" }}>Send</p>
            )}
          </button>



          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default PopupModal;
