import styled from "styled-components";
import { SubText } from "../styles";

const Table = () => {
  const trades = true;

  return (
    <Wrapper className="scrollbar-hidden">
      <SubText className="top">Order History</SubText>
      {trades ? (
        <table className="scrollbar-hidden">
          <thead>
            <tr>
              <th>Type</th>
              <th>Currency Pair</th>
              <th>Action</th>
              <th>Entry Price</th>
              <th>Stop Loss</th>
              <th>Take Profit</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cryptocurrency</td>
              <td>BTC/USD</td>
              <td>SELL</td>
              <td>+0.20%</td>
              <td>-</td>
              <td style={{ textAlign: "center" }}>{/* <p>-</p> */}-</td>
              <td>Open</td>
            </tr>
            <tr>
              <td>Forex</td>
              <td>EUR/USD</td>
              <td>BUY</td>
              <td>+0.20%</td>
              <td>$42,332</td>
              <td>191,789 ETH</td>
              <td>Pending</td>
            </tr>
            <tr>
              <td>Cryptocurrency</td>
              <td>EUR/USD</td>
              <td>BUY</td>
              <td>+0.20%</td>
              <td>$42,332</td>
              <td>191,789 ETH</td>
              <td>Pending</td>
            </tr>
            <tr>
              <td>Cryptocurrency</td>
              <td>EUR/USD</td>
              <td>BUY</td>
              <td>+0.20%</td>
              <td>$42,332</td>
              <td>191,789 ETH</td>
              <td>Pending</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <p
          style={{
            padding: "4rem",
            fontWeight: "600",
            color: "#a3a3a3",
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          No order history.
        </p>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  /* text-align: center; */

  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    /* overflow-x: auto; */
    /* max-width: 100%; */
    /* overflow-x: auto; */
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }

  th,
  td {
    width: 120px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
`;

export default Table;
