import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Card, DetailBox, TitleText } from "../styles";
import { userContext } from "../context/userContext";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import toast from "react-hot-toast";
import CountUp from "react-countup";
// import EditPlanModal from "../modals/EditPlanModal";
import EditSignalModal from "../modals/EditSignalModal";
import { Link } from "react-router-dom";

const SignalCard = ({ details }) => {
  const { accounts, currentPrices, userData, signalBalance } = useContext(userContext);
  const [amount, setAmount] = useState("");
  const [fiatAccount, setFiatAccount] = useState("");

  const [amountExceedError, setAmountExceedError] = useState(false);
  const [MaxError, setMaxError] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false)


//   const [isSubscribing, setIsSubscribing] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);
  const [user] = useAuthState(auth);
  const [id, setID] = useState("");

  const [editSignal, setEditSignal] = useState(false)

  const [signal, setSignal] = useState(undefined)

  useEffect(() => {
    if (accounts) {
      const { live } = accounts;
      if (live) {
        const { Fiat } = live;
        setFiatAccount(Fiat);
      }
    }
  }, [accounts]);

  useEffect(() => {
    if (amount) {
      if (amount < details.price) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }

      if (amount > details.price) {
        setMaxError(true);
      } else {
        setMaxError(false);
      }

      if (amount > signalBalance) {
        setAmountExceedError(true);
      } else {
        setAmountExceedError(false);
      }
    }
  }, [amount, details]);

  useEffect(() => {
    if (user) {
      setID(user.uid);
    }
  }, [user]);

  function handleAmountChange(e) {
    const value = e.target.value;
    if (value > 0) {
      setAmount(value);
    } else {
      setAmount("");
      setAmountExceedError(false);
      setMaxError(false)
    }
  }

  function handleSub(name, roi, duration) {
    setIsPurchasing(true);
    decrementAmount(name, roi, duration, id, amount);
  }

  // Decrement fiat
  const decrementAmount = async (name, roi, duration, id, amount) => {
    const q = doc(db, "balance", id);
    try {
      await updateDoc(q, {
        signalBalance: increment(-amount),
      }).then(() => {
        setSignalSub(name, id, amount);
      });
    } catch (error) {
      console.log(error);
    }
  };

  function reset () {
    if(amountRef){
        amountRef.current.value = ""
    }
    setAmount(false)
    setMaxError(false)
    setAmountExceedError(false)
  }


  const amountRef = useRef()

  async function setSignalSub(name, id, amount) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      id.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "signals", str), {
      date: serverTimestamp(),
      ref: str,
      status: "success",
      plan: name,
      user: id,
      amount: Number(amount),
    })
      .then(() => {
        toast.success("Purchased successfully.");
        reset();
        setIsPurchasing(false);
      })
      .catch((error) => {
        toast.error("Purchase could not complete.");
      });
  }

  function handleEditPlan(details){
    setSignal(details)
    setEditSignal(true)
  }

  return (
    <Wrapper>

{editSignal && (
            <EditSignalModal
              open={{ editSignal, setEditSignal }}
              plan={signal}
            />
          )}

      <Card className="card">
        <TitleText className="top" style={{alignItems: "center"}}>
          <p>
          {details.name}
          </p>
          
          {userData.admin && (
          <button className="actionButton" onClick={() => handleEditPlan(details)}>
          <p className="actionText">Edit</p>
          </button> 
          )}
        
        </TitleText>
        <div className="content">
          <div className="title">
            <p className="infoTitle">Price</p>
            <CountUp
            end={details.price}
            duration={1}
            delay={0}
            separator=","
            prefix="$"
          >
            {({ countUpRef }) => (
              <div>
                <p className="infoText" ref={countUpRef} />
              </div>
            )}
          </CountUp>

            {/* <p className="infoText">${details.minimum}</p> */}
          </div>

          {/* <div className="detailContainer">
            <p>Details</p>
            <div className="details">
              {details.details.map((detail) => (
                <DetailBox key={detail.name}>
                  <p className="detailTitle">{detail.name}</p>
                  <p className="detailDesc">{detail.value}</p>
                </DetailBox>
              ))}
            </div>
          </div> */}

          <div className="amWrap">
            <label htmlFor="amount">Amount</label>
            <div className="amBx">
              <div className="amTop">
                <input
                  ref={amountRef}
                  placeholder="1000"
                  name="amount"
                  onChange={handleAmountChange}
                  type="number"
                />
              </div>

              <div className="captions">
                {amount && (
                  <p className="balance">
                   Balance - {""}
                    <span>
                      ${signalBalance}
                    </span>
                  </p>
                )}
                {/* {amount && (
                  <p className="extra">
                    USD
                    <span className="green">
                      {" "}
                      ~${currentPrices[fiatAccount.asset] * amount}
                    </span>
                  </p>
                )} */}
              </div>

              {amountExceedError && (
                <p
                  className="warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                    <span className="red">

                  Insufficient balance - {" "}
                  
                  </span>

                   <Link to='/signal-deposit' style={{textDecoration: "underline"}}>Deposit now</Link>
                </p>
              )}

              {/* {subscribeMinError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  The minimum for this plan is {details.minimum}
                </p>
              )} */}

              {/* {MaxError && (
                <p
                  className="red warning textMedium"
                  style={{ marginTop: "1rem" }}
                >
                  The price of this signal is {details.price}
                </p>
              )} */}


            </div>
          </div>

          <button
          style={{display: "grid", placeContent: "center", marginTop: "-27px"}}
            onClick={() =>
              handleSub(details.name, details.roi, details.duration)
            }
            className={
                MaxError ||
              amountExceedError ||
              !amount ||
              isDisabled ||
              isPurchasing
                ? "button disabled"
                : "button submit"
            }
            disabled={
            //   subscribeMaxError ||
            //   subscribeMinError ||
              isDisabled ||
              amountExceedError ||
              !amount ||
              isPurchasing
            }
          >
            {isPurchasing ? (
              <img
                src="/svg-loaders/tail-spin.svg"
                alt="loading"
                width={24}
                height={24}
              />
            ) : (
              <p>Buy</p>
            )}
          </button>
        </div>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: max-content;
  display: flex;
  max-width: 300px;
  width: 100%;

  @media screen and (max-width: 768px) {
   max-width: unset;
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 1rem;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .card {
    background: #131824;
  }

  .infoTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.8);
  }

  .infoText {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #ffffff;
  }

  .content {
    padding: 1rem;
    display: grid;
    gap: 40px;
  }

  .detailContainer {
    display: grid;
    gap: 0.25rem;
  }

  .details {
    display: flex;
    gap: 0.55rem;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
`;

export default SignalCard;
