import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";

const UpgradeAccountModal = ({ open, user }) => {
  const { upgradeAccount, setUpgradeAccount } = open;
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [account, setAccount] = useState("Starter");

  function handleAccount(e) {
    const value = e.target.value;

    if (value) {
      setAccount(value);
    } else {
      setAccount("");
    }
  }

  function handleUpgrade() {
    setIsUpgrading(true);
    handleChange(user.id);
  }

  async function handleChange(id) {
    const selected = doc(db, "users", id);
    await updateDoc(selected, {
      accountType: account,
    })
      .then(() => {
        toast.success("Upgraded Successfully");
        setIsUpgrading(false);
        setUpgradeAccount(false);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setIsUpgrading(false);
      });
  }

  return (
    <Modal
      open={upgradeAccount}
      onClose={() => setUpgradeAccount(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Upgrade Account</SubText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setUpgradeAccount(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <div className="addcon">
            <label htmlFor="new">Client Name</label>
            <div className="add">
              <input
                //   ref={cryptoAddressRef}
                disabled
                placeholder={user?.firstname}
                defaultValue={user?.firstname}
                //   onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>

          {user.accountType && (
            <div className="addcon">
              <label htmlFor="new">Current Account</label>
              <div className="add">
                <input
                  disabled
                  placeholder={user.accountType}
                  defaultValue={user.accountType}
                />
              </div>
            </div>
          )}

          <div className="selectWrap">
            <label htmlFor="type">New Account</label>
            <div className="selectBox">
              <div className="selectTop">
                {/* <span> */}
                <select name="account" onChange={handleAccount}>
                  <option>Starter</option>
                  <option>Premium</option>
                  <option>Platinum</option>
                </select>
                <img src="/extraicons/arrowdown.svg" alt="select" />
                {/* </span> */}
              </div>
            </div>
          </div>
          <button
            className={!account ? "button disabled" : "button submit"}
            disabled={isUpgrading || !account}
            onClick={handleUpgrade}
            style={{
              display: "grid",
              textAlign: "left",
              placeContent: "center",
            }}
          >
            {isUpgrading ? (
              <span className="flex w-full">
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              </span>
            ) : (
              <p style={{ textDecoration: "none", color: "white" }}>Upgrade</p>
            )}
          </button>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default UpgradeAccountModal;
