import Modal from "@mui/material/Modal";
import { Card, InfoBox, OutlinedBox, TitleText } from "../styles";
import styled from "styled-components";
import {
  useMetamask,
  useWalletConnect,
  useCoinbaseWallet,
} from "@thirdweb-dev/react";
import { useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { userContext } from "../context/userContext";

const ConnectWalletModal = ({ open }) => {
  const { connectWallet, setConnectWallet } = open;
  const metamask = useMetamask();
  const wallet = useWalletConnect();
  const coinbase = useCoinbaseWallet();
  const mobile = useMediaQuery("(max-width: 800px)");
  const { dispatch } = useContext(userContext);

  async function connectWithMetamask() {
    await metamask()
      .then(() => {
        dispatch({ type: "setWalletConnect", payload: "metamask" });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  async function connectWithWalletConnect() {
    await wallet()
      .then(() => {
        dispatch({ type: "setWalletConnect", payload: "walletConnect" });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  async function connectWithCoinbase() {
    await coinbase()
      .then(() => {
        dispatch({ type: "setWalletConnect", payload: "coinbase" });
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  return (
    <Modal
      open={connectWallet}
      onClose={() => setConnectWallet(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
    >
      {mobile ? (
        <Card
          style={{
            placeSelf: "center",
            maxWidth: "360px",
          }}
        >
          <div className="top">
            <TitleText>Connect a wallet</TitleText>
            <button
              // className="close"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
              }}
              onClick={() => setConnectWallet(false)}
            >
              <img
                src="/sidebaricons/close.svg"
                alt="close"
                className="close"
                height={10}
                width={10}
              />
            </button>
          </div>
          <ModalContent>
            <InfoBox className="box" onClick={connectWithWalletConnect}>
              <span className="option">
                <img src="/wallets/walletconnect.png" alt="walletconnect" />
                <p>WalletConnect</p>
              </span>
            </InfoBox>
            <InfoBox className="box" onClick={connectWithCoinbase}>
              <span className="option">
                <img
                  src="/wallets/coinbase.png"
                  alt="coinbase"
                  style={{ borderRadius: "100%" }}
                />
                <p>Coinbase Wallet</p>
              </span>
            </InfoBox>

            <OutlinedBox>
              <p className="infot">
                Refresh the page if you're not auto connected.
              </p>
            </OutlinedBox>

            <OutlinedBox>
              <p className="infot">
                Select WalletConnect if you can't see your wallet on the
                options. If it still isn't there, contact us through chat for
                inclusion.
              </p>
            </OutlinedBox>
          </ModalContent>
        </Card>
      ) : (
        <Card
          style={{
            placeSelf: "center",
            maxWidth: "360px",
          }}
        >
          <div className="top">
            <TitleText>Connect a wallet</TitleText>
            <button
              // className="close"
              style={{
                backgroundColor: "transparent",
                border: "none",
                outline: "none",
              }}
              onClick={() => setConnectWallet(false)}
            >
              <img
                src="/sidebaricons/close.svg"
                alt="close"
                className="close"
                height={10}
                width={10}
              />
            </button>
          </div>
          <ModalContent>
            <InfoBox className="box" onClick={connectWithWalletConnect}>
              <span className="option">
                <img src="/wallets/walletconnect.png" alt="walletconnect" />
                <p>WalletConnect</p>
              </span>
            </InfoBox>
            <InfoBox className="box" onClick={connectWithMetamask}>
              <span className="option">
                <img src="/wallets/metamask.webp" alt="metamask" />
                <p>Metamask</p>
              </span>
            </InfoBox>
            <InfoBox className="box" onClick={connectWithCoinbase}>
              <span className="option">
                <img
                  alt="coinbase"
                  style={{ borderRadius: "100%" }}
                  src="/wallets/coinbase.png"
                />
                <p>Coinbase</p>
              </span>
            </InfoBox>
          </ModalContent>
        </Card>
      )}
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .box {
    background-color: #1f273a;
    border-radius: 4px;
    display: flex;
    place-content: center;
    cursor: pointer;
  }

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    place-self: center;
    padding: 0.5rem;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default ConnectWalletModal;
