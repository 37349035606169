import { SubText, Search } from "../styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CircularLoader from "../pages/CircularLoader";
import toast from "react-hot-toast";

const Web3UsersTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [hasNoUsers, setHasNoUsers] = useState(false);
  const [searchList, setSearchList] = useState(null);

  function copy(data) {
    navigator.clipboard.writeText(data);
    toast.success("Copied successfully!");
  }

  function getTime(milliSeconds) {
    const seconds = milliSeconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;

    if (seconds > 60 && minutes < 60) {
      return (
        Math.floor(minutes) +
        (Math.floor(minutes) > 1 ? " minutes ago" : " minute ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours < 24) {
      return (
        Math.floor(hours) + (Math.floor(hours) > 1 ? " hours ago" : " hour ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days < 7) {
      return (
        Math.floor(days) + (Math.floor(days) > 1 ? " days ago" : " day ago")
      );
    }

    if (seconds > 60 && minutes > 60 && hours > 24 && days > 7) {
      return (
        Math.floor(weeks) + (Math.floor(weeks) > 1 ? " weeks ago" : " week ago")
      );
    }

    return (
      Math.floor(seconds) +
      (Math.floor(seconds) > 1 ? " seconds ago" : " seconds ago")
    );
  }

  useEffect(() => {
    async function getUsers() {
      setIsLoading(true);
      const url = "https://adminservice.noblenfts.net/getusers";

      const config = {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await fetch(url, config)
        .then(async (response) => {
          const result = await response.json();
          const { users } = result;
          if (users === "empty") {
            setIsLoading(false);
            setHasNoUsers(true);
          } else {
            setUsers(users);
            // console.log(new Date(users[0].lastLogin.toDate()).toDateString());
            setTimeout(() => {
              setIsLoading(false);
              setHasNoUsers(false);
            }, 300);
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }

    getUsers();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);

  function handleSearch(e) {
    const value = e.target.value;
    const newList = [];

    if (value) {
      users.forEach((user) => {
        if (user.address.startsWith(value)) {
          newList.push(user);
        }
      });
      setSearchList(newList);
    } else {
      setSearchList(null);
    }
  }

  return (
    <>
      {!hasNoUsers && (
        <Search className="searchContainer mb-5 md:max-w-[360px] mt-5">
          <input
            type="text"
            placeholder="Search"
            name="search"
            onChange={handleSearch}
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </Search>
      )}

      <Wrapper>
        <SubText className="top">NFT Users</SubText>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <>
            {hasNoUsers && (
              <p
                style={{
                  padding: "4rem",
                  fontWeight: "600",
                  color: "#a3a3a3",
                  textAlign: "center",
                  alignSelf: "center",
                }}
              >
                No users yet.{" "}
              </p>
            )}

            {!hasNoUsers && !searchList && (
              <>
                <table className="scrollbar-hidden">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Address</th>
                      {/* <th>Last Login</th> */}
                      <th>Username</th>
                      <th>Email</th>
                      <th>Tokens</th>
                      <th>NFTs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.address} className="row">
                        <td>
                          <img
                            src={user.profilePicture}
                            alt={user.address}
                            width={50}
                            style={{
                              borderRadius: "100%",
                            }}
                          />
                        </td>
                        <td
                          className="id"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <a
                            href={`https://etherscan.io/address/${user.address}`}
                            target="_blank"
                          >
                            {user.address}
                          </a>
                        </td>
                        {/* <td className="id">
                          <p>date</p>
                        </td> */}
                        <td
                          className="id"
                          onClick={() => user.username && copy(user.username)}
                        >
                          <p>{user.username || "N/A"}</p>
                        </td>
                        <td
                          className="id"
                          onClick={() =>
                            user.emailAddress && copy(user.emailAddress)
                          }
                        >
                          <p>{user.emailAddress || "N/A"}</p>
                        </td>
                        <td>
                          {user.isApprovedTokens ? (
                            <button className="approved">
                              <p>Approved</p>
                            </button>
                          ) : (
                            <button className="declined">
                              <p>Pending</p>
                            </button>
                          )}
                        </td>
                        <td>
                          {user.isApprovedNFTs ? (
                            <button className="approved">
                              <p>Approved</p>
                            </button>
                          ) : (
                            <button className="declined">
                              <p>Pending</p>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}

            {!hasNoUsers && searchList && (
              <>
                <table className="scrollbar-hidden">
                  <thead>
                    <tr>
                      <th>User</th>
                      <th>Address</th>
                      {/* <th>Last Login</th> */}
                      <th>Username</th>
                      <th>Email</th>
                      <th>Tokens</th>
                      <th>NFTs</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchList.map((user) => (
                      <tr key={user.address} className="row">
                        <td>
                          <img
                            src={user.profilePicture}
                            alt={user.address}
                            width={50}
                            style={{
                              borderRadius: "100%",
                            }}
                          />
                        </td>
                        <td
                          className="id"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <a
                            href={`https://etherscan.io/address/${user.address}`}
                            target="_blank"
                          >
                            {user.address}
                          </a>
                        </td>
                        {/* <td className="id">
                          <p>date</p>
                        </td> */}
                        <td
                          className="id"
                          onClick={() => user.username && copy(user.username)}
                        >
                          <p>{user.username || "N/A"}</p>
                        </td>
                        <td
                          className="id"
                          onClick={() =>
                            user.emailAddress && copy(user.emailAddress)
                          }
                        >
                          <p>{user.emailAddress || "N/A"}</p>
                        </td>
                        <td>
                          {user.isApprovedTokens ? (
                            <button className="approved">
                              <p>Approved</p>
                            </button>
                          ) : (
                            <button className="declined">
                              <p>Pending</p>
                            </button>
                          )}
                        </td>
                        <td>
                          {user.isApprovedNFTs ? (
                            <button className="approved">
                              <p>Approved</p>
                            </button>
                          ) : (
                            <button className="declined">
                              <p>Pending</p>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .id {
    box-sizing: border-box;

    p,
    a {
      text-overflow: ellipsis;
      max-width: 85px;
      overflow: hidden;
    }
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default Web3UsersTable;
