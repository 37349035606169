import { useState } from "react";
import styled from "styled-components";
import { Card, DetailBox } from "../styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";

import StakeModal from "../modals/StakeModal";

const StakingCard = ({ planDetails, name, symbol }) => {
  const [user] = useAuthState(auth);
  const [stake, setStake] = useState(false);
  const [minimum, setMinimum] = useState("");
  const [maximum, setMaximum] = useState("");

  //   pass name, planDetails, symbol

  /**
   *
   * name: "Ethereum",
   * symbol: "ETH",
   * details: [
   * {
   * name: mimimum,
   * value: 3
   * },
   * {
   * name: maximum:
   * value: 32
   * },
   * {
   * name: cycle:
   * value: "Daily"
   * }
   *
   * ]
   */

  function handleStake(details) {
    details.forEach((detail) => {
      if (detail.name === "MINIMUM") {
        setMinimum(detail.value);
      }
      if (detail.name === "MAXIMUM") {
        setMaximum(detail.value);
      }
    });

    setStake(true);
  }

  return (
    <Wrapper>
      {stake && (
        <StakeModal
          open={{ stake, setStake }}
          name={name}
          minimum={minimum}
          maximum={maximum}
          symbol={symbol}
          user={user}
        />
      )}

      <div className="max-w-[max-content]">
        <Card className="card">
          <div className="content">
            <div className="title mt-6 flex space-x-3">
              <p className="text-2xl font-semibold">{name}</p>
              <img src={`asseticons/${symbol}.svg`} alt={symbol} />
            </div>

            <div className="detailContainer">
              <p>Details</p>
              <div className="details">
                {planDetails.details.map((detail) => (
                  <DetailBox key={detail.name}>
                    <p className="detailTitle">{detail.name}</p>
                    <p className="detailDesc">{detail.value}</p>
                  </DetailBox>
                ))}
              </div>
            </div>

            <button
             style={{display: "grid", placeContent: "center"}}
              onClick={(_) => handleStake(planDetails.details)}
              className="button submit mt-12"
            >
              <p>Stake</p>
            </button>
          </div>
        </Card>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  @media screen and (max-width: "800px") {
    width: 100%;
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 1rem;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .card {
    background: #131824;
  }

  .infoTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.8);
  }

  .infoText {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: #ffffff;
  }

  .content {
    padding: 1rem;
    display: grid;
    gap: 40px;
  }

  .detailContainer {
    display: grid;
    gap: 0.25rem;
  }

  .details {
    display: flex;
    gap: 0.55rem;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
`;

export default StakingCard;
