import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { doc, increment, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";
import { useEffect } from "react";
import { userContext } from "../context/userContext";

const AddDepositModal = ({ open, user }) => {
  const { addDeposit, setAddDeposit } = open;
  const [coin, setCoin] = useState('BTC')
  const [isAdding, setIsAdding] = useState(false);
  const [amount, setAmount] = useState(undefined)
  const {currentPrices} = useContext(userContext)

  

  function handleAmountChange(e) {
    const value = e.target.value;

    if (value) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  async function handleAdd() {
    setIsAdding(true)

    const {id} = user;

    handleIncrement(id)
  }

  // Increments the user account balance and calls to send notification
  async function handleIncrement(user) {
    const q = doc(db, "accounts", user);
    const key = `live.Crypto.${coin}.value`;
    try {
      await updateDoc(q, {
        [key]: increment(Number(amount)),
      }).then(() => {
        handleDoc(user)
      });
    } catch (error) {
        console.log(error)
        toast.error("Failed to add. Please try again later")  
        setIsAdding(false)
    }
  }

  async function handleDoc(user) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str = user.substring(0, 4) + randomOne.toString() + randomTwo.toString();

    await setDoc(doc(db, "deposits", str), {
      ref: str,
      user,
      status: "approved",
      date: serverTimestamp(),
      amount: Number(amount),
      type: coin,
      proof: "https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX9896883.jpg",
    })
      .then(() => {
        sendNotification(user)
      })
      .catch((error) => {
        console.log(error)
        toast.error("Failed to add. Please try again later")  
        setIsAdding(false)
      });
  }

   //send approve notification
   async function sendNotification(user) {
    const randomOne = Math.floor(Math.random(100, 999) * 1000 + 1);
    const randomTwo = Math.floor(Math.random(100, 999) * 100 + 1);

    const str =
      user.substring(0, 4) + randomOne.toString() + randomTwo.toString();
    const amountUSD = Number(currentPrices[coin] * amount).toFixed(2);

    await setDoc(doc(db, "notifications", str), {
      date: serverTimestamp(),
      ref: str,
      user,
      title: "Deposit",
      text: "A deposit of " + amount + " " + coin +  " was made into your account.",
    })
      .then(() => {
        setIsAdding(false)
        toast.success("Deposit added successfully")  
        setAddDeposit(false)
      })
      .catch((error) => {
        console.log(error)
        toast.error("Failed to add. Please try again later")  
        setIsAdding(false)
      });
  }

  return (
    <Modal
      open={addDeposit}
      onClose={() => setAddDeposit(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Add Deposit</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setAddDeposit(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          {/* <div>
            <label className="block text-sm font-medium text-white">
              Photo
            </label>
            <div className="mt-1 flex items-center">
              <img
                src={
                  newImage
                    ? newImage
                    : picture ||
                      "https://riverlegacy.org/wp-content/uploads/2021/07/blank-profile-photo.jpeg"
                }
                alt="profile"
                className="inline-block h-12 w-12 rounded-full overflow-hidden"
              />

              <button
                type="button"
                disabled={isUploading || !name}
                onClick={handleImage}
                className={
                  !name
                    ? "ml-5 bg-gray-800 py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white"
                    : "ml-5 bg-[#1199fa] py-2 px-3 rounded-md shadow-sm text-sm leading-4 font-medium text-white"
                }
              >
                {isUploading && (
                  <span className="flex w-full">
                    <img
                      src="/svg-loaders/tail-spin.svg"
                      alt="loading"
                      width={12}
                      height={12}
                    />
                  </span>
                )}
                {!isUploading && !name && "Add name to change"}
                {!isUploading && name && "Change"}
              </button>

              <input
                type="file"
                onChange={handleImageClick}
                ref={imageUpload}
                style={{
                  opacity: "0",
                  pointerEvents: "none",
                  userSelect: "none",
                  position: "absolute",
                }}
              />
            </div>
          </div> */}

          {/* <div className="addcon">
            <label htmlFor="new">Asset</label>
            <div className="add">
              <input
                onChange={(e) => handleName(e)}
                placeholder="BTC"
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Amount</label>
            <div className="add">
              <input
                onChange={(e) => handleName(e)}
                placeholder="1000"
              />
            </div>
          </div> */}

                      <div
                          className="selectWrap"
                          style={{
                            boxSizing: "border-box",
                          }}
                        >
                          <label htmlFor="asset">Asset</label>
                          <div
                            className="selectBox"
                            style={{
                              boxSizing: "border-box",
                              width: "100%",
                            }}
                          >
                            <div className="selectTop">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                  width: "100%",
                                  gap: "0.5rem",
                                  boxSizing: "border-box",
                                  padding: "1rem",
                                }}
                              >
                                <img
                                  src={`/asseticons/${coin}.svg`}
                                  alt="icon"
                                  width={24}
                                  height={24}
                                />
                                <select
                                  name="asset"
                                  style={{
                                    padding: "0",
                                    width: "100%",
                                    boxSizing: "border-box",
                                    display: "flex",
                                  }}
                                  onChange={(e) => setCoin(e.target.value)}
                                >
                                  <option>BTC</option>
                                  <option>ETH</option>
                                  <option>DOGE</option>
                                  <option>USDT</option>
                                </select>
                              </div>
                              <img
                                src="/extraicons/arrowdown.svg"
                                alt="select"
                                className="dropDownIcon"
                              />
                              {/* </span> */}
                            </div>
                          </div>
                        </div>

                        <div className="amWrap">
                          <label htmlFor="amount">Amount</label>
                          <div className="amBx">
                            <div className="amTop">
                              <input
                                placeholder="1000"
                                name="amount"
                                type="text"
                                onChange={handleAmountChange}
                              />
                              <span className="coinSelector">
                                <img
                                  src={`/asseticons/${coin}.svg`}
                                  alt="coin"
                                />
                                <p>{coin}</p>
                              </span>
                            </div>

           
                          </div>
                        </div>


            <button
              className={
                isAdding || !amount || !coin
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                isAdding || !amount || !coin
              }
              onClick={() => handleAdd()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isAdding ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Add</p>
              )}
            </button>

        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default AddDepositModal;
