import styled from "styled-components";
import { Sidebar, Header, MainCard } from "../components/index";
import { useEffect, useState } from "react";
import { Switches, TitleText } from "../styles";
import { Content } from "../styles";
import AssetTable from "../components/AssetTable";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase/firebase";
import { useMediaQuery } from "@mui/material";
import CircularLoader from "./CircularLoader";
import toast from "react-hot-toast";
import { doc, onSnapshot } from "firebase/firestore";

const Assets = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 900px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const [loader, setLoader] = useState(user !== null ? false : true);
  const { accounts } = useContext(userContext);
  const { currentAccount } = useContext(userContext);
  const [account, setAccount] = useState(null);
  const [stockAccount, setStockAccount] = useState(null);
  const [fiatAccount, setFiatAccount] = useState(null);
  const [cryptoAccount, setCryptoAccount] = useState(null);
  const [live, setLive] = useState(null);
  const [practice, setPractice] = useState(null);
  const navigate = useNavigate();
  const switchButtons = ["Deposit", "Withdraw"];
  const [activeButton, setActiveButton] = useState("Deposit");
  const { userData } = useContext(userContext);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    if (accounts) {
      const { live, practice } = accounts;
      setLive(live);
      setPractice(practice);
    }
  }, [accounts]);

  useEffect(() => {
    if ((live, practice)) {
      setAccount(currentAccount === "live" ? live : practice);
    }

    if (account) {
      const { Crypto, Stock, Fiat } = account;
      setStockAccount(Object.values(Stock));
      setFiatAccount([Fiat]);
      setCryptoAccount(Object.values(Crypto));
    }
  }, [currentAccount, account, accounts, live, practice]);

  // const incrementAsset = async () => {
  //   const q = doc(db, "accounts", "KLKYLZLJbZQuyNYvrvfkK8jhjNR2");
  //   try {
  //     await updateDoc(q, {
  //       "live.Crypto.BTC.value": increment(10),
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Assets"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Assets</TitleText>
              <ContentWrapper>
                <div className="cardContainer">
                  <MainCard />
                </div>

                {/* <button onClick={incrementAsset}>increment</button> */}

                <div className="tables">
                  <Switches className="switches">
                    {switchButtons.map((button) => (
                      <button
                        key={button}
                        className={activeButton === button ? "active" : ""}
                        onClick={() => setActiveButton(button)}
                      >
                        {button}
                      </button>
                    ))}
                  </Switches>

                  {activeButton === "Deposit" && (
                    <>
                      {fiatAccount ? (
                        <AssetTable
                          title="Fiat"
                          account={fiatAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}

                      {cryptoAccount ? (
                        <AssetTable
                          title="Crypto"
                          account={cryptoAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}
                    </>
                  )}

                  {activeButton === "Withdraw" && (
                    <>
                      {fiatAccount ? (
                        <AssetTable
                          title="Fiat"
                          account={fiatAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}

                      {cryptoAccount ? (
                        <AssetTable
                          title="Crypto"
                          account={cryptoAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}
                    </>
                  )}

                  {activeButton === "Trade" && (
                    <>
                      {fiatAccount ? (
                        <AssetTable
                          title="Fiat"
                          account={fiatAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}

                      {cryptoAccount ? (
                        <AssetTable
                          title="Crypto"
                          account={cryptoAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}

                      {stockAccount ? (
                        <AssetTable
                          title="Stocks"
                          account={stockAccount}
                          option={activeButton}
                        />
                      ) : (
                        <CircularLoader />
                      )}
                    </>
                  )}
                </div>
              </ContentWrapper>
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const Main = styled.div`
  width: 100vw;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100vh;

  .content {
    max-width: 100%;
    overflow-x: auto;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  gap: 2.5rem;
  margin-top: 50px;
  width: 100%;
  padding-bottom: 2rem;

  .tables {
    width: 100%;
    overflow-x: auto;
  }

  .switches {
    max-width: 280px;
  }

  .cardContainer {
    max-width: 280px;
  }

  .searchContainer {
    max-width: 280px;
  }

  @media screen and (max-width: 800px) {
    .cardContainer {
      max-width: 100%;
    }

    .searchContainer {
      max-width: 100%;
    }
  }

  .card {
    box-sizing: border-box;
    max-width: 100%;
  }

  .bodyText {
    font-size: 18px;
    margin-bottom: 1.5rem;
    padding-left: 1rem;
  }
`;

export default Assets;

// setTimeout()
// function cancelOpenCrypto () {
// fetch prices

// fetch currentPrices

// if currentPrice == closePrice ==> perform action

// } ===> called in time interval

// setTimeout
// function incrementSubscription(){

// }
