import React, {  useState } from "react";
import Modal from "@mui/material/Modal";
import { Card, SubText } from "../styles";
import styled from "styled-components";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";

const EditPlanModal = ({ open, user, plan }) => {
  const { editPlan, setEditPlan } = open;

  const {
    name: plName, 
    duration: plDuration,
    minimum: plMinimum, 
    maximum: plMaximum,
    roi: plROI,
  } = plan



const [name, setName] = useState(plName)
const [duration, setDuration] = useState(plDuration)
const [minimum, setMinimum] = useState(plMinimum)
const [maximum, setMaximum] = useState(plMaximum)
const [roi, setRoi] = useState(plROI)


  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  function handleName(e) {
    const value = e.target.value;

    if (value) {
      setName(value);
    } else {
      setName(plName);
    }
  }


  function handleMinimum(e) {
    const value = e.target.value;

    if (value) {
      setMinimum(value);
    } else {
      setMinimum(plMinimum);
    }
  }

  function handleMaximum(e) {
    const value = e.target.value;

    if (value) {
      setMinimum(value);
    } else {
      setMinimum(plMaximum);
    }
  }

  function handleROI(e) {
    const value = e.target.value;

    if (value) {
      setRoi(value);
    } else {
      setRoi(plROI);
    }
  }

  function handleDuration(e) {
    const value = e.target.value;

    if (value) {
      setDuration(value);
    } else {
      setDuration(plDuration);
    }
  }



  async function handleEdit() {
    setIsEditing(true);

    const q = doc(db, "admin", "plans");
    try {
      await updateDoc(q, {
        [plName]: {
            name,
            minimum,
            maximum,
            duration,
            roi,
            details: [
              {
                  name: "DURATION",
                  value: `${duration} DAYS`
              },
              {
                  name: "ROI",
                  value: `${roi}%`
              },
              {
                  name: "MAXIMUM",
                  value: `${maximum}`
              },
            ]
          },
      });
      toast.success("Updated successfully.");
      setIsEditing(false);
      setEditPlan(false)
    } catch (error) {
      console.log(error);
      toast.error("Could not update. Please try again later");
      setIsEditing(false);
    }
  }

  async function handleDelete() {
    setIsDeleting(true);
    const exRef = doc(db, "admin", "plans");
    await updateDoc(exRef, {
      [name]: deleteField(),
    })
      .then(() => {
        toast.success(
          "Deleted successfully. The changes will update on next refresh."
        );
        setIsDeleting(false);
        setEditPlan(false);
      })
      .catch((error) => {
        toast.error("Failed to delete. Please try again later.");
        setIsDeleting(false);
      });
  }

  return (
    <Modal
      open={editPlan}
      onClose={() => setEditPlan(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Edit {plName} Plan</SubText>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setEditPlan(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
    

          <div className="addcon">
            <label htmlFor="new">Plan Name</label>
            <div className="add">
              <input
                onChange={(e) => handleName(e)}
                placeholder={plName}
                defaultValue={plName}
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Minimum</label>
            <div className="add">
              <input
                onChange={(e) => handleMinimum(e)}
                placeholder={plMinimum}
                defaultValue={plMinimum}
                type='number'
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Maximum</label>
            <div className="add">
              <input
                onChange={(e) => handleMaximum(e)}
                placeholder={plMaximum}
                defaultValue={plMaximum}
                type='number'
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">ROI</label>
            <div className="add">
              <input
                onChange={(e) => handleROI(e)}
                placeholder={plROI}
                defaultValue={plROI}
                type='number'
              />
            </div>
          </div>

          <div className="addcon">
            <label htmlFor="new">Duration (Days)</label>
            <div className="add">
              <input
                onChange={(e) => handleDuration(e)}
                placeholder={plDuration}
                defaultValue={plDuration}
                type='number'
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              className={
                !name ||
                !minimum ||
                !maximum ||
                !roi ||
                !duration ||
                isEditing ||
                isDeleting
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !name ||
                !minimum ||
                !maximum ||
                !roi ||
                !duration ||
                isEditing ||
                isDeleting
              }
              onClick={() => handleEdit()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
              }}
            >
              {isEditing ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Save</p>
              )}
            </button>

            <button
              className={
                !name ||
                !minimum ||
                !maximum ||
                !roi ||
                !duration ||
                isEditing ||
                isDeleting
                  ? "button disabled"
                  : "button submit"
              }
              disabled={
                !name ||
                !minimum ||
                !maximum ||
                !roi ||
                !duration ||
                isEditing ||
                isDeleting
              }
              onClick={() => handleDelete()}
              style={{
                display: "grid",
                textAlign: "left",
                placeContent: "center",
                backgroundColor: "#E64B60",
              }}
            >
              {isDeleting ? (
                <span className="flex w-full">
                  <img
                    src="/svg-loaders/tail-spin.svg"
                    alt="loading"
                    width={24}
                    height={24}
                  />
                </span>
              ) : (
                <p style={{ textDecoration: "none", color: "white" }}>Delete</p>
              )}
            </button>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default EditPlanModal;
