// type
// asset
// show current price and user balance of asset
// set trade
// set profit or loss
// deduct or add balance
// send notification

import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase/firebase";
import toast from "react-hot-toast";
import {
  ActionButtons,
  Card,
  SubText,
  OutlinedBox,
  Switches,
  TitleText,
} from "../styles";
import Crypto from "../components/placeTrade/Crypto";
import Stocks from "../components/placeTrade/Stocks";
import Forex from "../components/placeTrade/Forex";
import { userContext } from "../context/userContext";

const PlaceTradeModal = ({ open, user }) => {
  const { placeTrade, setPlaceTrade } = open;
  const [isUpgrading, setIsUpgrading] = useState(false);
  const [account, setAccount] = useState("Starter");
  const [tradeType, setTradeType] = useState("Crypto");
  const tradeTypes = ["Crypto", "Forex", "Stocks"];
  const [action, setAction] = useState("buy");
  const [didReset, setDidReset] = useState("");

  const stockassets = [
    { name: "Facebook", symbol: "FB" },
    { name: "Apple", symbol: "AAPL", type: "stock" },
    { name: "PayPal", symbol: "PYPL", type: "stock" },
    { name: "Nvidia", symbol: "NVDA" },
    { name: "Amazon", symbol: "AMZN" },
    { name: "Netflix", symbol: "NFLX" },
    { name: "Tesla", symbol: "TSLA" },
    { name: "Microsoft", symbol: "MSFT" },
    { name: "Google", symbol: "GOOGL" },
  ];

  const cryptoassets = [
    { name: "Bitcoin", symbol: "BTCUSD" },
    { name: "Ethereum", symbol: "ETHUSD" },
    { name: "Solana", symbol: "SOLUSD" },
    { name: "Bitcoin Cash", symbol: "BCHUSD" },
    { name: "Litecoin", symbol: "LTCUSD" },
    { name: "Dogecoin", symbol: "DOGEUSD" },
    { name: "Tether", symbol: "USDTUSD" },
    { name: "Matic", symbol: "MATICUSD" },
    { name: "Avalanche", symbol: "AVAXUSD" },
  ];

  const handleTypeChange = (e) => {
    const type = e.target.value;
    switch (type) {
      case "Crypto":
        setTradeType("Crypto");
        break;
      case "Forex":
        setTradeType("Forex");
        break;
      case "Stocks":
        setTradeType("Stocks");
        break;
      default:
        break;
    }
  };

  function handleAccount(e) {
    const value = e.target.value;

    if (value) {
      setAccount(value);
    } else {
      setAccount("");
    }
  }

  function handleUpgrade() {
    setIsUpgrading(true);
    handleChange(user.id);
  }

  async function handleChange(id) {
    const selected = doc(db, "users", id);
    await updateDoc(selected, {
      accountType: account,
    })
      .then(() => {
        toast.success("Upgraded Successfully");
        setIsUpgrading(false);
        setPlaceTrade(false);
      })
      .catch((error) => {
        toast.error(
          "There was a problem with your request.\n \n Please try again later."
        );
        setIsUpgrading(false);
      });
  }

  return (
    <Modal
      open={placeTrade}
      onClose={() => setPlaceTrade(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Place a Trade</SubText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setPlaceTrade(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <ContentWrapper>
            <ActionButtons>
              <button
                value="buy"
                className={action === "buy" ? "action buy" : "action"}
                onClick={(e) => {
                  setAction(e.target.value);
                }}
              >
                BUY
              </button>
              <button
                value="sell"
                className={action === "sell" ? "action sell" : "action"}
                onClick={(e) => {
                  setAction(e.target.value);
                }}
              >
                SELL
              </button>
            </ActionButtons>

            <div className="selectWrap">
              <label htmlFor="type">Type</label>
              <div className="selectBox">
                <div className="selectTop">
                  {/* <span> */}
                  <select name="type" onChange={handleTypeChange}>
                    {tradeTypes.map((type) => (
                      <option key={type}>{type}</option>
                    ))}
                  </select>
                  <img src="/extraicons/arrowdown.svg" alt="select" />
                </div>
              </div>
            </div>
            {tradeType === "Crypto" && (
              <Crypto action={action} assets={cryptoassets} user={user.id} />
            )}
            {tradeType === "Forex" && (
              <Forex action={action} didReset={didReset} user={user.id} />
            )}
            {tradeType === "Stocks" && (
              <Stocks action={action} assets={stockassets} user={user.id} />
            )}
          </ContentWrapper>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #1f273a;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .addcon {
    display: grid;
    gap: 0.5rem;

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .add {
      outline: none;
      border: none;
      background: #1f273a;
      border-radius: 6px;
      width: 100%;
      /* border: 1px solid #3a4867; */

      input {
        display: flex;
        padding: 1rem;
        /* padding-right: 5px; */
        /* padding-left: 13px; */
        /* padding-top: 10px; */
        background-color: transparent;
        border: none;
        outline: none;
        box-sizing: border-box;
        min-width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #fff;
      }
    }
  }

  .amWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .amBx {
      display: grid;
      gap: 0.3rem;
    }

    .amTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      justify-content: space-between;

      .coinSelector {
        display: flex;
        background: #1f273a;
        border-radius: 4px;
        margin: 2px;
        padding: 0.7rem;
        align-items: center;
        gap: 0.5rem;
        width: 100px;
        place-content: center;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
        }

        img {
          width: 16px;
        }
      }

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        height: 19px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding-left: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 1rem;
  display: grid;
  gap: 0.8rem;

  .selectWrap {
    display: grid;
    gap: 0.5rem;

    label {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #a3a3a3;
    }

    .selectBox {
      display: grid;
      gap: 0.3rem;
    }

    .selectTop {
      display: flex;
      align-items: center;
      border: 1px solid #1f273a;
      background-color: #1f273a;
      box-sizing: border-box;
      border-radius: 6px;
      /* padding: 1rem; */
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      select {
        font-family: "Inter";
        box-sizing: border-box;
        font-style: normal;
        font-weight: 600;
        outline: none;
        font-size: 16px;
        line-height: 19px;
        background-color: transparent;
        border: transparent;
        appearance: none;
        z-index: 10;
        width: 100%;
        color: #ffffff;
        padding: 1rem;
      }

      img {
        position: absolute;
        right: 0;
        padding: 1rem;
      }
    }

    .extra {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #ffffff;
    }

    .captions {
      display: flex;
      font-weight: 600;
      justify-content: space-between;

      .balance {
        font-size: 14px;
      }
    }
  }
`;

const ModalOption = styled.div``;

export default PlaceTradeModal;
