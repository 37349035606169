import Modal from "@mui/material/Modal";
import {
  BodyText,
  Card,
  OutlinedBox,
  OutlinedButton,
  RedButton,
  SubText,
  TitleText,
} from "../styles";
import styled from "styled-components";
import { db } from "../firebase/firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { deleteUser } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const DeleteAccountAdmin = ({ open, user }) => {
  const { showDeleteAccountModal, setShowDeleteAccountModal } = open;
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [userAccount, setUserAccount] = useState("");

  async function deleteUserAuth() {
    try {
      await deleteDoc(doc(db, "users", user));
      deleteAccounts();
    } catch (error) {
      toast.error("Please try again later.");
      setIsDeleting(false);
      return;
    }
  }

  // async function deleteAccounts() {
  //   try {
  //     await deleteDoc(doc(db, "accounts", user));
  //     toast.success("Deleted successfully.");
  //     setTimeout(() => {
  //       setShowDeleteAccountModal(false);
  //       navigate("/manage");
  //     }, 1000);
  //   } catch (error) {
  //     toast.error("Please try again later.");
  //   }
  // }

  async function deleteAccounts() {
    try {
      await deleteDoc(doc(db, "accounts", user));
      deleteNotis();
    } catch (error) {
      toast.error("Please try again later.");
      setIsDeleting(false);
      return;
    }
  }

  async function deleteGroup(type, item) {
    try {
      await deleteDoc(doc(db, type, item));
      return;
    } catch (error) {}
  }

  async function deleteNotis() {
    const q = query(collection(db, "notifications"), where("user", "==", user));
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          deleteDeposits();
        } else {
          querySnapshot.forEach(async (doc) => {
            await deleteGroup("notifications", doc.data().ref).then(() => {
              deleteDeposits();
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Please try again later.");
        setIsDeleting(false);
        return;
      });
  }

  async function deleteDeposits() {
    const q = query(collection(db, "deposits"), where("user", "==", user));
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          deleteVerifications();
        } else {
          querySnapshot.forEach(async (doc) => {
            await deleteGroup("deposits", doc.data().ref).then(() => {
              deleteVerifications();
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Please try again later.");
        setIsDeleting(false);
        return;
      });
  }

  async function deleteVerifications() {
    const q = query(collection(db, "verifications"), where("user", "==", user));
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          deleteWithdrawals();
        } else {
          querySnapshot.forEach(async (doc) => {
            await deleteGroup("verifications", doc.data().ref).then(() => {
              deleteWithdrawals();
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Please try again later.");
        setIsDeleting(false);
        return;
      });
  }

  async function deleteWithdrawals() {
    const q = query(collection(db, "withdrawals"), where("user", "==", user));
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          deleteSubscriptions();
        } else {
          querySnapshot.forEach(async (doc) => {
            await deleteGroup("withdrawals", doc.data().ref).then(() => {
              deleteSubscriptions();
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Please try again later.");
        setIsDeleting(false);
        return;
      });
  }

  async function deleteSubscriptions() {
    const q = query(collection(db, "subscriptions"), where("user", "==", user));
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          deleteTrades();
        } else {
          querySnapshot.forEach(async (doc) => {
            await deleteGroup("subscriptions", doc.data().ref).then(() => {
              deleteTrades();
            });
          });
        }
      })
      .catch((error) => {
        toast.error("Please try again later.");
        setIsDeleting(false);
        return;
      });
  }

  async function deleteTrades() {
    const q = query(collection(db, "trades"), where("user", "==", user));
    await getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          toast.success("Deleted successfully.");
          setTimeout(() => {
            setShowDeleteAccountModal(false);
            navigate("/manage");
          }, 1000);
        } else {
          querySnapshot.forEach(async (doc) => {
            await deleteGroup("subscriptions", doc.data().ref).then(() => {
              toast.success("Deleted successfully.");
              setTimeout(() => {
                setShowDeleteAccountModal(false);
                navigate("/manage");
              }, 1000);
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Please try again later.");
        setIsDeleting(false);
        return;
      });
  }

  function deleteUserAccount() {
    setIsDeleting(true);
    deleteUserAuth();
  }

  return (
    <Modal
      open={showDeleteAccountModal}
      onClose={() => setShowDeleteAccountModal(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
      }}
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <SubText>Delete account</SubText>
          <button
            // className="close"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={() => setShowDeleteAccountModal(false)}
          >
            <img
              src="/sidebaricons/close.svg"
              alt="close"
              className="close"
              height={10}
              width={10}
            />
          </button>
        </div>
        <ModalContent>
          <OutlinedBox>
            <BodyText className="infot">
              <span className="red">
                You have choosen to delete this account.
              </span>
              <br />
              <br />
              <span className="red">
                This action will delete all of the account data and cannot be
                reversed.
              </span>
              <br />
              <br />
              <span className="red">Do you wish to continue?</span>
            </BodyText>
          </OutlinedBox>

          <div
            style={{ display: "flex", gap: "0.55rem" }}
            className="items-center"
          >
            <button
              style={{
                backgroundColor: "#e64b60",
                placeContent: "center",
                display: "grid",
              }}
              className={isDeleting ? "button disabled" : "button submit"}
              disabled={isDeleting}
              onClick={deleteUserAccount}
            >
              {isDeleting ? (
                <img
                  src="/svg-loaders/tail-spin.svg"
                  alt="loading"
                  width={24}
                  height={24}
                />
              ) : (
                <p>Delete</p>
              )}
            </button>
            <OutlinedButton
              onClick={() => setShowDeleteAccountModal(false)}
              style={{
                color: "white",
                placeContent: "center",
                display: "grid",
              }}
            >
              Cancel
            </OutlinedButton>
          </div>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .box {
    background-color: #1f273a;
    border-radius: 4px;
    display: flex;
    place-content: center;
    cursor: pointer;
  }

  .option {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-weight: 600;
    place-self: center;
    padding: 0.5rem;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default DeleteAccountAdmin;
