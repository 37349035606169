import styled from "styled-components";

const Search = () => {
  return (
    <Wrapper>
      <input type="text" placeholder="Search" />
      <span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 360px;
  padding: 1rem;
  border: 1px solid #1f273a;
  box-sizing: border-box;
  border-radius: 6px;
  color: white;
  cursor: pointer;

  /* input {
    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
  } */

  /* svg {
    width: 16px;
    height: 16px;
  } */
`;

export default Search;
