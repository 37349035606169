export const tabs = [
  {
    title: "Futures",
    symbols: [
      {
        s: "CME_MINI:ES1!",
        d: "S&P 500",
      },
      {
        s: "CME:6E1!",
        d: "Euro",
      },
      {
        s: "COMEX:GC1!",
        d: "Gold",
      },
      {
        s: "NYMEX:CL1!",
        d: "Crude Oil",
      },
      {
        s: "NYMEX:NG1!",
        d: "Natural Gas",
      },
      {
        s: "CBOT:ZC1!",
        d: "Corn",
      },
    ],
    originalTitle: "Futures",
  },
  {
    title: "Forex",
    symbols: [
      {
        s: "FX:EURUSD",
      },
      {
        s: "FX:GBPUSD",
      },
      {
        s: "FX:USDJPY",
      },
      {
        s: "FX:USDCHF",
      },
      {
        s: "FX:AUDUSD",
      },
      {
        s: "FX:USDCAD",
      },
    ],
    originalTitle: "Forex",
  },
  {
    title: "Crypto",
    symbols: [
      {
        s: "BINANCE:BTCUSDT",
        d: "BTC/USDT",
      },
      {
        s: "BYBIT:ETHUSDT",
        d: "ETH/USDT",
      },
      {
        s: "BINANCE:XRPUSDT",
        d: "XRP/USDT",
      },
      {
        s: "BINANCE:SOLUSDT",
        d: "SOL/USDT",
      },
      {
        s: "BINANCE:DOGEUSDT",
        d: "DOGE/USDT",
      },
      {
        s: "BITSTAMP:ETHUSD",
        d: "ETH/USD",
      },
      {
        s: "COINBASE:BTCUSD",
        d: "BTC/USD",
      },
    ],
  },
  {
    title: "Stock",
    symbols: [
      {
        s: "AMEX:SPY",
        d: "SPY",
      },
      {
        s: "NASDAQ:AAPL",
        d: "AAPL",
      },
      {
        s: "NASDAQ:TSLA",
        d: "TSLA",
      },
      {
        s: "NASDAQ:NVDA",
        d: "NVDA",
      },
      {
        s: "NASDAQ:AMD",
        d: "AMD",
      },
      {
        s: "NASDAQ:AMZN",
        d: "AMZN",
      },
      {
        s: "NYSE:NIO",
        d: "NIO",
      },
    ],
  },
];

export const priceViews = [
  {
    id: 1,
    src: "/images/icons/BTC.webp",
    name: "Bitcoin",
    symbol: "BTC",
    price: "$42,254",
    convertPrice: "0.024 BTC",
  },
  {
    id: 2,
    src: "/images/icons/ETH.webp",
    name: "Ethereum",
    symbol: "ETH",
    price: "$2,422",
    convertPrice: "0.133 ETH",
  },
];

export const tradeCards = [
  {
    name: "Pair",
    value: "BTC/USD",
  },
  {
    name: "Lot Size",
    value: "0.00",
  },
  {
    name: "Entry Price",
    value: "35,000",
  },
  {
    name: "Stop Loss",
    value: "0.00",
  },
  {
    name: "Take Profit",
    value: "0.00",
  },
];

export const sidebarItems = [
  {
    Icon: "/images/sidebar-icons/home.svg",
    Name: "Dashboard",
    Prop: "Hii",
    link: "home",
  },
  {
    Icon: "/images/sidebar-icons/activity.svg",
    Name: "Activity Log",
    link: "activity",
  },
  {
    Icon: "/images/sidebar-icons/autotrade.svg",
    Name: "Auto-Trade",
    link: "auto",
  },
  {
    Icon: "/images/sidebar-icons/deposit.svg",
    Name: "Deposit",
    link: "deposit",
  },
  {
    Icon: "/images/sidebar-icons/withdraw.svg",
    Name: "Withdrawal",
    link: "withdraw",
  },
  {
    Icon: "/images/sidebar-icons/subscription.svg",
    Name: "Subscription",
    link: "subscribe",
  },
  {
    Icon: "/images/sidebar-icons/buy.svg",
    Name: "Buy Bitcoin",
    link: "buy",
  },
  {
    Icon: "/images/sidebar-icons/referral.svg",
    Name: "Referral",
    link: "refer",
  },
  {
    Icon: "/images/sidebar-icons/verify.svg",
    Name: "Verify Account",
    link: "verify",
  },
];

export const historyEntry = [
  {
    type: "Forex",
    currencyPair: "USD/EUR",
    action: "BUY",
    entryPrice: "$47,622",
    stopLoss: "$47,622",
    takeProfit: "$46,544",
    status: "Complete",
  },
  {
    type: "Crypto",
    currencyPair: "BTC/USDT",
    action: "SELL",
    entryPrice: "$42,337",
    stopLoss: "$47,622",
    takeProfit: "$47,622",
    status: "Pending",
  },
  {
    type: "Stock",
    currencyPair: "BTC/USDT",
    action: "SELL",
    entryPrice: "$42,337",
    stopLoss: "$47,622",
    takeProfit: "$47,622",
    status: "Pending",
  },
  {
    type: "Futures",
    currencyPair: "BTC/USDT",
    action: "SELL",
    entryPrice: "$42,337",
    stopLoss: "$47,622",
    takeProfit: "$47,622",
    status: "Pending",
  },
];
