import { Menu, SubText } from "../styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";

const StakingsTable = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [stakings, setStakings] = useState([]);
  const [hasNoStakings, setHasNostakings] = useState(false);

  useEffect(() => {
    async function getStakings() {
      setIsLoading(true);
      const url = "https://adminservice.noblenfts.net/getstakings";

      const config = {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      };

      await fetch(url, config)
        .then(async (response) => {
          const result = await response.json();
          const { data } = result;
          if (data === "empty") {
            setIsLoading(false);
            setHasNostakings(true);
          } else {
            setStakings(data);
            setTimeout(() => {
              setIsLoading(false);
              setHasNostakings(false);
            }, 300);
          }
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    }

    getStakings();
  }, []);

  // useEffect(() => {
  //   const stakingsList = [];
  //   async function getSubs() {
  //     const q = query(collection(db, "stakings"));
  //     const querySnapshot = await getDocs(q);
  //     querySnapshot.forEach((doc) => {
  //       stakingsList.push(doc.data());
  //       setStakings(stakingsList);
  //     });
  //   }

  //   getSubs();
  // }, []);

  const [closedStakings, setClosedStakings] = useState([]);
  const [pendingStakings, setPendingStakings] = useState([]);

  useEffect(() => {
    const closedStakingsList = [];
    const pendingStakingsList = [];

    stakings.forEach((staking) => {
      switch (staking.status) {
        case "open":
          pendingStakingsList.push(staking);
          break;
        case "closed":
          closedStakingsList.push(staking);
          break;
        default:
          break;
      }
    });

    setClosedStakings(closedStakingsList);
    setPendingStakings(pendingStakingsList);
  }, [stakings, pendingStakings, closedStakings]);

  useEffect(() => {
    if (!stakings) {
      setIsLoading(true);
    }

    if (stakings) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [stakings]);

  return (
    <Wrapper>
      <SubText className="top">Stakings</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          {hasNoStakings && (
            <p
              style={{
                padding: "4rem",
                fontWeight: "600",
                color: "#a3a3a3",
                textAlign: "center",
                alignSelf: "center",
              }}
            >
              No stakings yet.{" "}
            </p>
          )}

          {!hasNoStakings && (
            <table className="scrollbar-hidden">
              <thead>
                <tr>
                  <th>NFT</th>
                  <th>Name</th>
                  <th>Duration</th>
                  <th>Days Left</th>
                  <th>ROI</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {pendingStakings.map((staking) => (
                  <tr key={staking.id} className="row">
                    <td>
                      <img
                        src={staking.image}
                        alt={staking.name}
                        width={50}
                        style={{
                          borderRadius: "4px",
                        }}
                      />
                    </td>
                    <td className="id">
                      <p>{staking.name}</p>
                    </td>
                    <td>
                      <p>{staking.duration}</p>
                    </td>
                    <td>
                      <p>{staking.duration - staking.daysRan}</p>
                    </td>
                    <td>
                      <p>{staking.ROI}</p>
                    </td>
                    <td>
                      {staking.status === "open" && (
                        <button className="approved">
                          <p>Running</p>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
                {closedStakings.map((staking) => (
                  <tr key={staking.id} className="row">
                    <td>
                      <img
                        src={staking.image}
                        alt={staking.name}
                        width={50}
                        style={{
                          borderRadius: "4px",
                        }}
                      />
                    </td>
                    <td className="id">
                      <p>{staking.name}</p>
                    </td>
                    <td>
                      <p>{staking.duration}</p>
                    </td>
                    <td>
                      <p>{staking.duration - staking.daysRan}</p>
                    </td>
                    <td>
                      <p>{staking.ROI}</p>
                    </td>
                    <td>
                      {staking.status === "closed" && (
                        <button className="declined">
                          <p>Closed</p>
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .id {
    box-sizing: border-box;

    p {
      text-overflow: ellipsis;
      max-width: 85px;
      overflow: hidden;
    }
  }

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default StakingsTable;
