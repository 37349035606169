import styled from "styled-components";
import { useEffect, useState } from "react";
import { DetailBox, OutlinedButton, BlueButton } from "../styles";
import { useNFTCollection, use } from "@thirdweb-dev/react";
import CircularLoader from "../pages/CircularLoader";
import SingleNFT from "./SingleNFT";

const NFTCards = ({ listings, market }) => {
  const [hiddenCollectionDesc, setHiddenCollectionDesc] = useState(true);
  const [hiddenCollectionDetails, setHiddenCollectionDetails] = useState(true);
  const address = "0x7fCB00bBA3F8287d2F16b4c2ee4D9f5158f9C02c";
  const [singleNft, setSingleNft] = useState(false);
  const [loader, setLoader] = useState(true);
  const [singleListing, setSingleListing] = useState([]);

  useEffect(() => {
    if (listings) {
      setLoader(false);
    }
  }, [listings]);

  const collection = useNFTCollection(
    "0x75E0EfcCbEfe34D35D674A73Ac46eCe3EaeFA098"
  );

  function handleSingleNFT(listing) {
    setSingleListing(listing);
    setSingleNft(true);
  }

  return singleNft ? (
    <Wrapper style={{ position: "relative" }}>
      <button
        style={{ position: "absolute", top: "-50px" }}
        className="actionButton"
        onClick={() => setSingleNft(false)}
      >
        <p className="actionText">Go back</p>
      </button>
      <SingleNFT listing={singleListing} marketplace={market} />
    </Wrapper>
  ) : (
    <>
      {loader ? (
        <CircularLoader />
      ) : (
        <>
          {listings.map((listing) => (
            <Wrapper
              style={{ paddingBottom: "2rem", cursor: "pointer" }}
              onClick={() => handleSingleNFT(listing)}
              key={listing.id}
            >
              <img
                src={listing.asset.image}
                alt="asset"
                className="assetImage"
              />

              <div className="bottom">
                <div className="assetTitle">
                  <div className="cardLeft">
                    <div className="collectionTitle">
                      <p className="assetDecription fontBold">
                        {listing.asset.name}
                      </p>
                    </div>
                  </div>
                  <div className="cardRight">
                    <p>Price</p>
                    <div className="assetPrice">
                      <img src="/asseticons/ETH.svg" className="priceIcon" />
                      <p>{listing.buyoutCurrencyValuePerToken.displayValue}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Wrapper>
          ))}
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  width: 360px !important;
  height: max-content;
  margin-top: 60px;
  justify-self: center;
  background: #131824;
  border-radius: 8px;
  /* place-self: center; */

  .traitsContainer {
    display: grid;
    gap: 0.25rem;
  }

  .traits {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .smallText {
    font-size: 12px;
  }
  .mediumText {
    font-size: 13px;
  }

  .fontSemi {
    font-weight: 600;
  }

  .fontBold {
    font-weight: 700;
  }

  .bottom {
    padding: 1rem;
    display: grid;
    gap: 30px;
  }

  .clickable {
    font-weight: 600;
    color: #1199fa;
    font-size: 14px;
    cursor: pointer;
  }

  .displayNone {
    display: none;
  }

  .collectionImage {
    width: 30px;
    border-radius: 100%;
    object-fit: contain;
  }

  .assetImage {
    width: 100%;
    height: 320px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    object-fit: cover;
  }

  .assetTitle {
    display: flex;
    justify-content: space-between;
  }

  .cardLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-start;
  }

  .cardRight {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 0.25rem;
  }

  .assetPrice {
    display: flex;
    box-sizing: border-box;
    gap: 0.25rem;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    p {
      font-weight: 700;
    }
  }

  .priceIcon {
    height: 16px;
    width: 16px;
  }

  .collectionDetails {
    display: grid;
    gap: 30px;
  }

  .aboutCollection {
    display: grid;
    gap: 0.25rem;
  }

  .title {
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  /* Text Ellipses snippet from Stack Overflow */
  .hidden {
    line-height: 1em;
    max-height: 2em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .aboutCollectionDesc {
  }
  .buttons {
    display: flex;
    gap: 0.5rem;

    .blue {
      color: #1199fa;
    }
  }
`;

export default NFTCards;
