import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import {
  BlueButton,
  BodyText,
  Box,
  Card,
  InfoBox,
  OutlinedBox,
  TitleText,
} from "../styles";
import styled from "styled-components";
// const DepositOptions = ["Pay to Wallet Address", "Pay With Coinbase Commerce", "PayWithMetamask"]

const CloseTradeModal = ({ open }) => {
  const { closeTrade, setCloseTrade } = open;
  const [option, setOption] = useState(false);
  const [coinbase, setCoinbabse] = useState(false);
  const [bitcoin, setBitcoin] = useState(false);

  return (
    <Modal
      open={closeTrade}
      onClose={() => setCloseTrade(false)}
      style={{
        color: "white",
        placeContent: "center",
        display: "flex",
        // backgroundColor: "gray",
      }}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"
    >
      <Card
        style={{
          placeSelf: "center",
          maxWidth: "360px",
        }}
      >
        <div className="top">
          <TitleText>Close EUR/USD Position</TitleText>
          <button className="close" onClick={() => setCloseTrade(false)}>
            close
          </button>
        </div>
        <ModalContent>
          <BodyText>
            You have chosen to close your trade.
            <br />
            To continue, confirm the close. We will attempt to close it at the
            stats below or better.
          </BodyText>
          <OutlinedBox className="box">
            <span className="option">
              <p>P&L</p>
              <p>+5.3</p>
            </span>
          </OutlinedBox>

          <OutlinedBox className="box">
            <span className="option">
              <p>Entry Price</p>
              <p>$1200</p>
            </span>
          </OutlinedBox>

          <BlueButton onClick={() => setCloseTrade(false)}>
            Confirm Close
          </BlueButton>
        </ModalContent>
      </Card>
    </Modal>
  );
};

const ModalContent = styled.div`
  padding: 1.5rem 1.3rem;
  display: grid;
  gap: 1rem;

  .box {
    border-radius: 4px;
    cursor: pointer;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #c9c9c9;

    img {
      width: 20px;
      height: auto;
    }
  }
`;

const ModalOption = styled.div``;

export default CloseTradeModal;
