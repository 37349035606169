import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { AmountDefault, AmountFX, BodyText, NeutralButton } from "../styles";

//current account, asset price, asset, market

//action, type, market, asset, price === entry price,

const TradeForm = ({ type, action, market, asset, price, assetType }) => {
  //   console.log(
  //     "type",
  //     type + "\n",
  //     "action",
  //     action + "\n",
  //     "market",
  //     market + "\n",
  //     "asset",
  //     asset + "\n",
  //     "price",
  //     price + "\n",
  //     "assetType",
  //     assetType + "\n",
  //     "form"
  //   );

  //   const [amount, setAmount] = useState(null);
  const [limitPrice, setLimitPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [triggerPrice, setTriggerPrice] = useState("");
  const [takeProfit, setTakeProfit] = useState("");
  const [trades, setTrades] = useState([]);
  const [cryptoAmountError, setCryptoAmountError] = useState(false);
  const [cryptoLimitPriceError, setCryptoLimitPriceError] = useState(false);
  const [cryptoTriggerPriceError, setCryptoTriggerPriceError] = useState(false);
  const [stockAmountError, setStockAmountError] = useState(false);
  const [forexAmountError, setForexAmountError] = useState(false);
  const [forexStopLossError, setForexStopLossError] = useState(false);
  const [forexTakeProfitError, setForexTakeProfitError] = useState(false);
  const [forexLimitPriceError, setForexLimitPriceError] = useState(false);

  //   useEffect(() => {
  //     console.log("trades", trades);
  //   }, [trades]);
  //   let amount;
  //   let limitprice;
  //   let stoploss;
  //   let trigger;
  //   let tp;
  //   let entryprice === assetPrice
  //   let asset === asset
  //   let market === market
  //   let status === open or close
  //   let time === server time

  //cryptomarket =  amount, done
  //cryptolimit =  amount, limitprice done
  //cryptostop =  amount, limitprice trigger price
  // stockmarket =  amount,
  // forexmarket =  amount(k), Stop Loss, Take Profit
  // forexlimit =  amount(k), sl, tp, limitprice

  const USDBalance = 10;
  const assetPrice = 10000;

  function handleCryptoMarket() {
    const data = {
      type,
      assetType,
      action,
      market,
      asset,
      entryPrice: price,
      amount,
      timestamp: new Date(),
      status: "open",
    };
    console.log(data);
    setAmount("");

    // setTrades((prevState) => [...prevState, data]);
  }
  function handleCryptoLimit() {
    const data = {
      type,
      assetType,
      action,
      market,
      asset,
      entryPrice: price,
      amount,
      limitPrice,
      timestamp: new Date(),
      status: "open",
    };
    setAmount("");
    console.log(amount);
    console.log(data);
    // setTrades((prevState) => [...prevState, data]);
  }
  function handleCryptoStop() {
    const data = {
      type,
      assetType,
      action,
      market,
      asset,
      entryPrice: price,
      //   amount: ,
      limitPrice,
      triggerPrice,
      timestamp: new Date(),
      status: "open",
    };
    setTrades((prevState) => [...prevState, data]);
  }
  function handleStockMarket() {
    const data = {
      type,
      assetType,
      action,
      market,
      asset,
      entryPrice: price,
      //   amount,
      timestamp: new Date(),
      status: "open",
    };
    setTrades((prevState) => [...prevState, data]);
  }
  function handleForexMarket() {
    const data = {
      type,
      assetType,
      action,
      market,
      asset,
      entryPrice: price,
      //   amount,
      stopLoss,
      takeProfit,
      timestamp: new Date(),
      status: "open",
    };
    setTrades((prevState) => [...prevState, data]);
  }
  function handleForexLimit() {
    const data = {
      type,
      assetType,
      action,
      market,
      asset,
      entryPrice: price,
      //   amount,
      stopLoss,
      takeProfit,
      triggerPrice,
      timestamp: new Date(),
      status: "open",
    };
    setTrades((prevState) => [...prevState, data]);
  }

  useEffect(() => {
    setAmount("");
    setLimitPrice("");
    setTriggerPrice("");
    setCryptoAmountError(false);
    setCryptoLimitPriceError(false);
    setCryptoTriggerPriceError(false);
    setStockAmountError(false);
  }, [type]);
  //   errors
  useEffect(() => {
    function checkForErrors(type) {
      switch (type) {
        case "cryptomarket":
          if (amount && amount > USDBalance) {
            setCryptoAmountError(true);
          } else {
            setCryptoAmountError(false);
          }
          return;
        case "cryptolimit":
          if (amount !== null && amount > USDBalance) {
            setCryptoAmountError(true);
          } else {
            setCryptoAmountError(false);
          }

          if (limitPrice !== null && limitPrice > assetPrice) {
            setCryptoLimitPriceError(true);
          } else {
            setCryptoLimitPriceError(false);
          }
          return;
        case "cryptostop":
          if (amount !== null && amount > USDBalance) {
            setCryptoAmountError(true);
          } else {
            setCryptoAmountError(false);
          }

          if (limitPrice !== null && limitPrice > assetPrice) {
            setCryptoLimitPriceError(true);
          } else {
            setCryptoLimitPriceError(false);
          }

          if (triggerPrice !== null && triggerPrice > assetPrice) {
            setCryptoTriggerPriceError(true);
          } else {
            setCryptoTriggerPriceError(false);
          }
          return;
        case "stockmarket":
          if (amount && amount > USDBalance) {
            setStockAmountError(true);
          } else {
            setStockAmountError(false);
          }
          return;
        default:
          return;
      }
    }

    checkForErrors(type);
  }, [type, amount, limitPrice, triggerPrice]);

  //   changes
  function handleAmountChange(e) {
    const value = e.target.value;
    if (value.length > 0) {
      setAmount(value);
    } else {
      setAmount("");
    }
  }

  function handleLimitPriceChange(e) {
    const value = e.target.value;
    if (value.length > 0) {
      setLimitPrice(value);
    } else {
      setLimitPrice(null);
    }
  }

  function handleTriggerPriceChange(e) {
    const value = e.target.value;
    if (value.length > 0) {
      setTriggerPrice(value);
    } else {
      setTriggerPrice(null);
    }
  }

  function handleStopLossChange(e) {
    const value = e.target.value;
    if (value.length > 0) {
      setStopLoss(value);
    } else {
      setStopLoss(null);
    }
  }

  function handleTakeProfitChange(e) {
    const value = e.target.value;
    if (value.length > 0) {
      setTakeProfit(value);
    } else {
      setTakeProfit(null);
    }
  }

  return (
    <>
      {type === "cryptomarket" && (
        <Wrapper>
          <AmountDefault>
            <div className="amountTop">
              <BodyText>Amount</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  value={amount}
                  onChange={handleAmountChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>
            {cryptoAmountError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <NeutralButton
            className="capitalize"
            disabled={cryptoAmountError || !amount}
            onClick={handleCryptoMarket}
            style={{
              backgroundColor: action === "buy" ? "#20bca4" : "#e64b60",
            }}
          >
            {action}
          </NeutralButton>
        </Wrapper>
      )}
      {type === "cryptolimit" && (
        <Wrapper>
          <AmountDefault>
            <div className="amountTop">
              <BodyText>Amount</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleAmountChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>
            {cryptoAmountError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Limit Price</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleLimitPriceChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>
            {cryptoLimitPriceError && (
              <p className="caption">Something is up with your price lol</p>
            )}
          </AmountDefault>

          <NeutralButton
            className="capitalize"
            disabled={
              cryptoAmountError ||
              cryptoLimitPriceError ||
              !limitPrice ||
              !amount
            }
            onClick={handleCryptoLimit}
            style={{
              backgroundColor: action === "buy" ? "#20bca4" : "#e64b60",
            }}
          >
            {action}
          </NeutralButton>
        </Wrapper>
      )}
      {type === "cryptostop" && (
        <Wrapper>
          <AmountDefault>
            <div className="amountTop">
              <BodyText>Amount</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleAmountChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>
            {cryptoAmountError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Limit Price</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleLimitPriceChange}
                />

                <p className="quote">USD</p>
              </div>
            </div>
            {cryptoLimitPriceError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Trigger Price</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleTriggerPriceChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>

            {cryptoTriggerPriceError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <NeutralButton
            className="capitalize"
            // disabled={cryptoAm}
            // onClick={handleTrade}
            style={{
              backgroundColor: action === "buy" ? "#20bca4" : "#e64b60",
            }}
          >
            {action}
          </NeutralButton>
        </Wrapper>
      )}
      {type === "stockmarket" && (
        <Wrapper>
          <AmountDefault>
            <div className="amountTop">
              <BodyText>Amount</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleAmountChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>

            {stockAmountError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <NeutralButton
            className="capitalize"
            // onClick={handleTrade}
            disabled={stockAmountError || !amount}
            style={{
              backgroundColor: action === "buy" ? "#20bca4" : "#e64b60",
            }}
          >
            {action}
          </NeutralButton>
        </Wrapper>
      )}
      {type === "forexmarket" && (
        <Wrapper>
          <AmountFX>
            <div className="amountTop">
              <BodyText>Amount</BodyText>

              <div className="right">
                <input
                  type="number"
                  placeholder="1"
                  onChange={handleAmountChange}
                />
              </div>
            </div>
            <div className="captions">
              <p>Per pip</p>
              <p>Margin</p>
            </div>

            {forexAmountError && <p>error</p>}
          </AmountFX>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Stop Loss</BodyText>
              <div className="right">
                <input type="number" placeholder="0.00" />
                <p className="quote">USD</p>
              </div>
            </div>
            {forexStopLossError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Take Profit</BodyText>
              <div className="right">
                <input type="number" placeholder="0.00" />
                <p className="quote">USD</p>
              </div>
            </div>

            {forexTakeProfitError && <p className="caption">Zero USD</p>}
          </AmountDefault>

          <NeutralButton
            className="capitalize"
            // onClick={handleTrade}
            disabled={
              forexAmountError ||
              forexStopLossError ||
              forexTakeProfitError ||
              !amount
            }
            style={{
              backgroundColor: action === "buy" ? "#20bca4" : "#e64b60",
            }}
          >
            {action}
          </NeutralButton>
        </Wrapper>
      )}
      {type === "forexlimit" && (
        <Wrapper>
          <AmountFX>
            <div className="amountTop">
              <BodyText>Amount</BodyText>

              <div className="right">
                <input
                  type="number"
                  placeholder="1"
                  onChange={handleAmountChange}
                />
              </div>
            </div>

            <div className="captions">
              <p>Per pip</p>
              <p>Margin</p>
            </div>
          </AmountFX>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Limit Price</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleLimitPriceChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>
            <p className="caption">Zero USD</p>
          </AmountDefault>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Stop Loss</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleStopLossChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>
            <p className="caption">Zero USD</p>
          </AmountDefault>

          <AmountDefault>
            <div className="amountTop">
              <BodyText>Take Profit</BodyText>
              <div className="right">
                <input
                  type="number"
                  placeholder="0.00"
                  onChange={handleTakeProfitChange}
                />
                <p className="quote">USD</p>
              </div>
            </div>
            <p className="caption">Zero USD</p>
          </AmountDefault>

          <NeutralButton
            className="capitalize"
            // onClick={handleTrade}
            style={{
              backgroundColor: action === "buy" ? "#20bca4" : "#e64b60",
            }}
          >
            {action}
          </NeutralButton>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 1rem;
`;

export default TradeForm;
