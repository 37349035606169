import { SubText } from "../styles";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../pages/CircularLoader";

const Stakings = ({ id, user }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const subscriptionsList = [];
    async function getSubs() {
      const q = query(
        collection(db, "stakings"),
        where("user", "==", user.id)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        subscriptionsList.push(doc.data());
        setSubscriptions(subscriptionsList);
      });
    }

    getSubs();
  }, [user]);

  const [closedSubscriptions, setClosedSubscriptions] = useState([]);
  const [pendingSubscriptions, setPendingSubscriptions] = useState([]);

  useEffect(() => {
    const closedSubscriptionsList = [];
    const pendingSubscriptionsList = [];

    subscriptions.forEach((sub) => {
      switch (sub.status) {
        case "open":
          pendingSubscriptionsList.push(sub);
          break;
        case "closed":
          closedSubscriptionsList.push(sub);
          break;
        default:
          break;
      }
    });

    setClosedSubscriptions(closedSubscriptionsList);
    setPendingSubscriptions(pendingSubscriptionsList);
  }, [subscriptions, pendingSubscriptions, closedSubscriptions]);

  useEffect(() => {
    if (!subscriptions) {
      setIsLoading(true);
    }

    if (subscriptions) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [subscriptions]);

  useEffect(() => {
    const userList = [];
    async function getUsers() {
      const q = query(collection(db, "users"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        userList.push(doc.data());
        setUsers(userList);
      });
    }

    getUsers();
  }, []);

  useEffect(() => {
    if (!users) {
      setIsLoading(true);
    }

    if (users) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [users]);

  return (
    <Wrapper>
      <SubText className="top">Stakings</SubText>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <table className="scrollbar-hidden">
          <thead>
            <tr>
              <th>User</th>
              <th>Ref</th>
              <th>Crypto</th>
              <th>Duration</th>
              <th>Days Left</th>
              <th>Amount</th>
              <th>ROI</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {pendingSubscriptions.map((sub) => (
              <tr key={sub.ref} className="row">
                <td>
                  {users.map(
                    (user) =>
                      user.id === sub.user && (
                        <p
                          key={sub.ref}
                          style={{ textTransform: "Capitalize" }}
                        >
                          {user.firstname}
                        </p>
                      )
                  )}
                </td>
                <td className="ref">
                  <p>{sub.ref}</p>
                </td>
                <td>
                  <p>{sub.crypto}</p>
                </td>
                <td>
                  <p>{sub.duration}</p>
                </td>
                <td>
                  <p>{sub.duration - sub.daysRan}</p>
                </td>
                <td>
                  <p>{sub.amount} {sub.symbol}</p>
                </td>
                <td>
                  <p>{sub.roi}</p>
                </td>
                <td>
                  {sub.status === "open" && (
                    <button className="approved">
                      <p>Running</p>
                    </button>
                  )}
                </td>
              </tr>
            ))}
            {closedSubscriptions.map((sub) => (
              <tr key={sub.ref} className="row">
                <td>
                  {users.map(
                    (user) =>
                      user.id === sub.user && (
                        <p
                          key={sub.ref}
                          style={{ textTransform: "Capitalize" }}
                        >
                          {user.firstname}
                        </p>
                      )
                  )}
                </td>
                <td className="ref">
                  <p>{sub.ref}</p>
                </td>
                <td>
                  <p>{sub.crypto}</p>
                </td>
                <td>
                  <p>{sub.duration}</p>
                </td>
                <td>
                  <p>{sub.duration - sub.daysRan}</p>
                </td>
                <td>
                  <p>{sub.amount} {sub.symbol}</p>
                </td>
                <td>
                  <p>{sub.roi}</p>
                </td>
                <td>
                  {sub.status === "closed" && (
                    <button className="declined">
                      <p>Closed</p>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  margin-top: 30px;
  background: #131824;
  border-radius: 10px;
  max-width: 100%;

  .pending {
    background: rgba(35, 163, 251, 0.13);
    border-radius: 6px;
    border: none;
    outline: none;

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem 1rem;
      color: #1199fa;
    }
  }

  .declined {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(230, 75, 96, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #e64b60;
    }
  }

  .approved {
    /* border: 0.5px solid #1199fa; */
    border: none;
    border-radius: 6px;
    outline: none;
    background: rgba(32, 188, 164, 0.13);

    p {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      padding: 0.5rem;
      color: #20bca4;
    }
  }

  .asset {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  table {
    display: grid;
    gap: 1rem;
    padding: 1rem;
    overflow-x: auto;
    max-width: 100%;
    overflow-x: auto;

    .name {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      text-transform: capitalize;

      img {
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .ref,
    .email {
      box-sizing: border-box;

      p {
        text-overflow: ellipsis;
        max-width: 85px;
        overflow: hidden;
      }
    }
  }

  thead {
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 700;
    color: #828ea1;
    font-size: 14px;
    line-height: 18px;
  }

  tbody {
    font-weight: 510;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    display: grid;
    gap: 0.7rem;

    .row {
      border-radius: 4px;
      cursor: pointer;
    }

    .row:hover {
      background-color: #1f273a;
    }
  }

  th,
  td {
    width: 144px;
    text-align: left;
  }

  tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    box-sizing: border-box;
  }
`;

export default Stakings;
