import styled from "styled-components";

const CaptionText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
`;

const FeatureBtn = styled.div`
  border-radius: 50%;
  background-color: #19202f;
  height: 56px;
  width: 56px;
  display: flex;
  place-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
    /* padding: 1rem; */
  }
`;

const SmallFeatureBox = styled.div`
  padding: 1rem;
  width: 100%;
  background: #161c2a;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    gap: 1rem;
  }

  .right p {
    font-weight: 600;
    padding: 0.5rem;
    background-color: #403529;
    color: #f7931a;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

const BigFeatureBox = styled.div`
  padding: 1rem;
  background: #161c2a;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .left {
    display: flex;
    gap: 1rem;
  }

  .right p {
    font-weight: 600;
    padding: 0.5rem;
    background-color: #403529;
    color: #f7931a;
    border-radius: 0.25rem;
    cursor: pointer;
  }

  @media screen and (max-width: 1200px) {
    max-width: 100%;
  }
`;

const LiveSwitch = styled.div``;
const PracticeSwitch = styled.div``;

const NotificationText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 227px;
  text-align: left;

  .notification {
    display: flex;
    gap: 1rem;
  }

  .topText {
    font-size: 14px;
    color: #ffffff;
    font-weight: 500;
  }

  .time {
    font-size: 11px;
    color: #c9c9c9;
  }
`;

const DescBox = styled.div`
  display: grid;
  gap: 0.5rem;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #a3a3a3;
    text-align: left;
  }

  div {
    width: 100%;
    box-sizing: border-box;
    outline: none;
  }
`;

const InputText = styled.div`
  display: grid;
  gap: 0.5rem;

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #a3a3a3;
  }

  input {
    width: 100%;
    background: #1f273a;
    border-radius: 8px;
    padding: 1rem;
    box-sizing: border-box;
    outline: none;
    font-size: 16px;
    border: 1px solid transparent;

    &:focus {
      border: 1px solid #83a3ed;
    }
  }

  /* input:placeholder{

  } */
`;

const DropDownBox = styled.div`
  display: grid;
  gap: 0.5rem;

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #a3a3a3;
  }

  select {
    width: 100%;
    outline: none;
    background: #1f273a;
    border-radius: 8px;
    padding: 1rem;
    box-sizing: border-box;
    border: none;
    color: white;
  }

  /* input:placeholder{

  } */
`;

const DropDownIcon = styled.div`
  display: grid;
  gap: 0.5rem;

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #a3a3a3;
  }

  option {
    display: flex;
  }

  .option {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    background: #1f273a;
    border-radius: 8px;
    padding: 1rem;
    box-sizing: border-box;
    border: none;
    color: white;
  }

  select {
    background-color: transparent;
    border: none;
    color: white;
    width: 100%;
    outline: none;
  }

  option {
    font-size: 600;
  }

  img {
    width: 16px;
    height: 16px;
  }

  .arrow {
    border: 2px solid #c9c9c9;
  }
`;

const InputNumber = styled.div`
  display: grid;
  gap: 0.5rem;
  width: 100%;

  label {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #a3a3a3;
  }

  input {
    width: 100%;
    background: transparent;
    border: 1px solid #1f273a;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1rem;
    width: 100%;
    color: white;
    outline: none;
    font-size: 16px;

    &:focus {
      border: 1px solid #83a3ed;
    }
  }
`;

const BlueButton = styled.button`
  width: 100%;
  padding: 1rem;
  display: flex;
  place-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  background-color: #1199fa;
  color: white;
  font-weight: 600;
`;

const NeutralButton = styled.button`
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  background-color: #1f273a;
  color: white;
  font-weight: 600;
  transition: background-color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:disabled {
    cursor: not-allowed;
    /* pointer-events: none; */
  }
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 14px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  color: white;
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateX(20px);
  }

  .active {
    background-color: #1199fa;
    border-radius: 8px;
  }
`;

const ProfileItem = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 18px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  background-color: #1f273a;
  transition: transform 300ms cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateX(20px);
  }

  .active {
    background-color: #1f273a;
    border-radius: 8px;
  }
`;

const ProfileCard = styled.div`
  position: absolute;
  top: 35px;
  right: 0;
  background: #131824;
  border-radius: 10px;
  padding: 1rem;
  width: 159px;
  gap: 0.5rem;
  display: grid;

  .options {
    display: grid;
    gap: 0.5rem;
    font-weight: 500;
  }

  .options p {
    padding: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .options p:hover {
    background-color: #1f273a;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 4px;
  color: white;
  /* background: #161c2a; */
  /* background-color: #19202f; */
  background-color: #1f273a;

  button {
    padding: 0.8rem;
    border: none;
    border-radius: 4px;
    color: white;
    text-transform: uppercase;
    display: flex;
    font-weight: 700;
    place-content: center;
    width: 100%;
    cursor: pointer;
    height: 100%;
    transition: background-color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .action {
    position: relative;
    cursor: pointer;
    display: flex;
    width: 100%;
    background-color: transparent;
  }

  .buy {
    background-color: #20bca4;
  }

  .sell {
    background-color: #e64b60;
  }
`;

const Card = styled.div`
  position: relative;
  background: #131824;
  border-radius: 10px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;

  .top {
    top: 0;
    width: 100%;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const FeatureText = styled.div`
  text-align: left;

  .topText {
    font-weight: 500;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #c9c9c9;
  }

  .bottomText {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }

  .blue {
    color: #1199fa;
  }

  .red {
    color: #e64b60;
  }
`;

const RedButton = styled.button`
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  background-color: #e64b60;
  color: white;
  font-weight: 600;
`;

const InfoBox = styled.div`
  padding: 1rem;
  max-width: 325px;
  color: white;
`;

const OutlinedButton = styled.button`
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid #1f273a;
  font-weight: 600;

  &:hover {
    background: #161c2a;
  }

  .blue {
    color: #1199fa;
    background-color: transparent;
  }

  .red {
    color: #e64b60;
  }
`;

const TitleText = styled.p`
  font-weight: 600;
  font-size: 20px;
`;

const SubText = styled.p`
  font-weight: 600;
  color: #a3a3a3;
`;

const Menu = styled.div`
  right: 0;
  top: 35px;
  z-index: 100;
  width: max-content;
  max-height: 100vh;
  overflow-y: auto;
  position: absolute;
  background: #131824;
  border-radius: 10px;
  display: grid;
  justify-content: space-between;
  text-align: center;

  .top {
    position: sticky;
    top: 0;
    width: 100%;
    height: max-content;
    background-color: #19202f;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .mid {
    display: grid;
    gap: 1rem;
    padding: 1rem;
  }

  .notificationBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    .left {
      display: flex;
      gap: 1rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      align-items: center;
    }

    .ex {
      opacity: 0;
      margin: 0 1rem;
      height: 16px;
      width: 16px;
    }
  }

  .notificationBox:hover {
    background-color: #161c2a;

    .ex {
      opacity: 1;
    }
  }

  .bottom {
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
  }
`;

const OutlinedBox = styled.div`
  width: 100%;
  border: 1px solid #1f273a;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: transparent;
  outline: none;
  color: #fff;
  cursor: pointer;
  padding: 1rem;
`;

const BodyText = styled.p`
  color: #c9c9c9;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
`;

const SmallText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const WarningText = styled(SmallText)`
  color: #e64b60;
`;

const PlaceHolderText = styled(SmallText)`
  color: rgba(255, 255, 255, 0.5);
`;

const ButtonSmall = styled.button`
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 0.25rem;
  display: flex;
  place-content: center;
  cursor: pointer;
  box-sizing: border-box;
`;

const ButtonLarge = styled.button`
  width: 100%;
  padding: 0.8rem;
  cursor: pointer;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
`;

const Content = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const Box = styled.div`
  max-width: 360px;
  background: #131824;
`;

const Search = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border: 1px solid #1f273a;
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
  color: white;
  cursor: pointer;

  input {
    border: none;
    outline: none;
    font-size: 16px;
    background-color: transparent;
    color: white;
    cursor: pointer;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

/* background: #161c2a; */
/* background-color: #19202f; */
// background-color: #1f273a;

// TradeInputText
// TradeInputNumber
// TradeInputIncrement?

const TradeDropDownBox = styled.div`
  display: flex;
  width: 100%;
  background-color: #1f273a;
  border-radius: 4px;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;

  .label {
  }

  select {
    /* display: flex; */
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
  }

  .types {
    text-transform: capitalize;
  }
`;

const Switches = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  border-radius: 4px;
  gap: 1rem;

  button {
    padding: 0.5rem 0;
    border: none;
    border-radius: 4px;
    color: #a9a9a9;
    cursor: pointer;
    font-weight: 600;
    background-color: #1f273a;
    text-transform: capitalize;
    display: flex;
    place-content: center;
    width: 100%;
    height: 100%;
    transition: background-color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .active {
    color: white;
    background-color: #1199fa;
  }
`;

// TradeInputText
// TradeInputNumber
// TradeInputIncrement?

const TradeInputText = styled.div`
  display: flex;
  width: 100%;
  background-color: #1f273a;
  border-radius: 4px;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box;

  .label {
  }

  input {
    /* display: flex; */
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
  }
`;

const AmountDefault = styled.div`
  display: grid;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  align-items: center;

  .red {
    color: red;
  }

  .amountTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(31, 39, 58, 0.2);
    border: 1px solid #1f273a;
    border-radius: 4px;
    padding: 1rem;
    font-weight: 400;

    p {
      color: #c9c9c9;
    }
  }

  .right {
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 16px;

    input[type="number"] {
      background-color: transparent;
      border: none;
      color: white;
      box-sizing: border-box;
      font-size: 16px;
      text-transform: capitalize;
      text-align: right;
      outline: none;
      /* padding-left: 1rem; */
    }

    input::placeholder {
      font-size: 16px;
      color: #858585;
    }

    .quote {
      color: #858585;
    }
  }

  .caption {
    /* display: none; */
    padding: 0.5rem 1rem;
    background-color: antiquewhite;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

const AmountFX = styled.div`
  display: grid;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: center;

  .amountTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1f273a;
    border-radius: 4px;
    padding: 1rem;
    font-weight: 500;

    p {
      color: #c9c9c9;
    }
  }

  .right {
    display: flex;
    align-items: center;

    input[type="number"] {
      background-color: transparent;
      border: none;
      color: white;
      box-sizing: border-box;
      text-transform: capitalize;
      text-align: right;
      outline: none;
    }

    select {
      background-color: transparent;
      width: 16px;
      font-weight: bolder;
      color: #858585;
      border: none;
      outline: none;
    }
  }

  .captions {
    display: none;
    /* display: flex; */
    padding: 1rem;
    justify-content: space-between;
    background-color: antiquewhite;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

const PageSwitcher = styled.div`
  border: 1px solid white;
  max-width: max-content;
  /* padding: 1rem; */
  border: 1px solid rgba(216, 216, 216, 0.2);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 2px;

  button {
    padding: 10px 14px;
    background-color: transparent;
    color: #8b8b8b;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .active {
    background: #2b2f37;
    color: white;
    border-radius: 4px;
  }
`;

const NFTCard = styled.div`
  max-width: 361px;
  height: max-content;
  background: #131824;
  border-radius: 8px;
  place-self: center;

  .traits {
    display: grid;
    gap: 0.25rem;
  }

  .smallText {
    font-size: 12px;
  }
  .mediumText {
    font-size: 13px;
  }

  .fontSemi {
    font-weight: 600;
  }

  .fontBold {
    font-weight: 700;
  }

  .bottom {
    padding: 1rem;
    display: grid;
    gap: 30px;
  }

  .clickable {
    font-weight: 600;
    color: #1199fa;
    font-size: 14px;
    cursor: pointer;
  }

  .displayNone {
    display: none;
  }

  .collectionImage {
    width: 30px;
    border-radius: 100%;
    object-fit: contain;
  }

  .assetImage {
    width: 100%;
    height: 320px;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    object-fit: cover;
  }

  .assetTitle {
    display: flex;
    justify-content: space-between;
  }

  .cardLeft {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-start;
  }

  .cardRight {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 0.25rem;
  }

  .assetPrice {
    display: flex;
    box-sizing: border-box;
    gap: 0.25rem;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    p {
      font-weight: 700;
    }
  }

  .priceIcon {
    height: 16px;
    width: 16px;
  }

  .collectionDetails {
    display: grid;
    gap: 30px;
  }

  .aboutCollection {
    display: grid;
    gap: 0.25rem;
  }

  .title {
  }

  .description {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  /* Text Ellipses snippet from Stack Overflow */
  .hidden {
    line-height: 1em;
    max-height: 2em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .aboutCollectionDesc {
  }
  .buttons {
    display: flex;
    gap: 0.5rem;

    .blue {
      color: #1199fa;
    }
  }
`;

const DetailBox = styled.div`
  display: grid;
  background: rgba(31, 39, 58, 0.2);
  border: 1px solid #1f273a;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
  max-width: max-content;
  padding-right: 1.5rem;
  gap: 0.25rem;

  .detailTitle {
    color: #1199fa;
    font-size: 13px;
  }

  .detailDesc {
    font-size: 16px;
    font-weight: 600;
  }
`;

// const SubscribeCard = styled.div``

export {
  BodyText,
  SubText,
  TitleText,
  WarningText,
  PlaceHolderText,
  ButtonLarge,
  ButtonSmall,
  Box,
  Content,
  OutlinedBox,
  Menu,
  Search,
  FeatureBtn,
  FeatureText,
  BigFeatureBox,
  SmallFeatureBox,
  Card,
  NotificationText,
  OutlinedButton,
  ProfileCard,
  InputText,
  ProfileItem,
  InputNumber,
  BlueButton,
  DropDownBox,
  DropDownIcon,
  NeutralButton,
  DescBox,
  RedButton,
  InfoBox,
  ActionButtons,
  TradeDropDownBox,
  Switches,
  TradeInputText,
  AmountFX,
  AmountDefault,
  PageSwitcher,
  NFTCard,
  DetailBox,
};
