import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";
// import { ThirdWebProvider, ChainId } from "@thirdweb-dev/react";
import Provider from "./context/userContext";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Provider>
    <App />
  </Provider>
  // </React.StrictMode>
);
