import styled from "styled-components";
import { Header, Sidebar } from "../components/index";
import { useContext, useEffect, useState } from "react";
import { TitleText, SubText, Content } from "../styles";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { auth, db } from "../firebase/firebase";
import { useMediaQuery } from "@mui/material";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import toast from "react-hot-toast";
import { userContext } from "../context/userContext";
import StakingCard from "../components/StakingCard";
import StakeHistory from "../usertables/StakeHistory";

const Staking = () => {
  const [menuSmall, setMenuSmall] = useState(false);
  const mobile = useMediaQuery("(max-width: 800px)");
  const [isOpen, setIsOpen] = useState(mobile ? false : true);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [plans, setPlans] = useState([]);
  const { userData } = useContext(userContext);

  useEffect(() => {
    async function fetchPlans() {
      const docRef = doc(db, "admin", "stakings");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPlans(Object.values(docSnap.data()));
        setLoader(false);
      } else {
        console.log("No such document!");
      }
    }

    fetchPlans();
  }, []);

  useEffect(() => {
    if (!loading && user && userData.blocked) {
      navigate("/login");
      toast.error("Your account has been banned.");
    }

    if (!loading && user && !userData.blocked) {
      setLoader(false);
    }

    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate, userData]);

  // useEffect(() => {
  //   if (user) {
  //     onSnapshot(doc(db, "users", user.uid), (doc) => {
  //       const data = doc.data();

  //       if (data && !data.verified) {
  //         navigate("/newverify");
  //         toast.error("Verify your account to continue.");
  //       }
  //     });
  //   }
  // }, [user]);

  // async function AddDB() {
  //   await setDoc(doc(db, "admin", "stakings"), {
  //     Ethereum: {
  //       name: "Ethereum",
  //       symbol: "ETH",
  //       details: [
  //         {
  //           name: "MIMIMUM",
  //           value: 3,
  //         },
  //         {
  //           name: "MAXIMUM",
  //           value: 320,
  //         },
  //         {
  //           name: "CYCLE",
  //           value: "Daily",
  //         },
  //       ],
  //     },
  //     Solana: {
  //       name: "Solana",
  //       symbol: "SOL",
  //       details: [
  //         {
  //           name: "MIMIMUM",
  //           value: 10,
  //         },
  //         {
  //           name: "MAXIMUM",
  //           value: 73,
  //         },
  //         {
  //           name: "CYCLE",
  //           value: "Daily",
  //         },
  //       ],
  //     },
  //     Polygon: {
  //       name: "Polygon",
  //       symbol: "MATIC",
  //       details: [
  //         {
  //           name: "MIMIMUM",
  //           value: 1,
  //         },
  //         {
  //           name: "MAXIMUM",
  //           value: 1000,
  //         },
  //         {
  //           name: "CYCLE",
  //           value: "Daily",
  //         },
  //       ],
  //     },
  //     Tether: {
  //       name: "Tether",
  //       symbol: "USDT",
  //       details: [
  //         {
  //           name: "MIMIMUM",
  //           value: 500,
  //         },
  //         {
  //           name: "MAXIMUM",
  //           value: 1000000,
  //         },
  //         {
  //           name: "CYCLE",
  //           value: "Daily",
  //         },
  //       ],
  //     },
  //     Avalanche: {
  //       name: "Avalanche",
  //       symbol: "AVAX",
  //       details: [
  //         {
  //           name: "MIMIMUM",
  //           value: 13,
  //         },
  //         {
  //           name: "MAXIMUM",
  //           value: 500,
  //         },
  //         {
  //           name: "CYCLE",
  //           value: "Daily",
  //         },
  //       ],
  //     },
  //   }).then(() => {
  //     console.log("done");
  //   });
  // }

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <Wrapper>
          <Sidebar
            menu={[menuSmall, setMenuSmall]}
            active="Staking"
            open={[isOpen, setIsOpen]}
          />
          <Main>
            <Header
              menu={[menuSmall, setMenuSmall]}
              open={[isOpen, setIsOpen]}
            />
            <Content className="content">
              <TitleText>Staking</TitleText>
              <ContentWrapper>
                <SubText>Pools</SubText>
                <ContentContainer>
                  {plans.map((plan) => (
                    <StakingCard
                      key={plan.name}
                      planDetails={plan}
                      name={plan.name}
                      symbol={plan.symbol}
                    />
                  ))}
                </ContentContainer>
              </ContentWrapper>

              {/* <button className="actionButton" onClick={() => AddDB()}>
                <p className="actionText">ADD DB</p>
              </button> */}

              <StakeHistory user={user} />
              <br />
              <br />
            </Content>
          </Main>
        </Wrapper>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  background: #0e121b;
  color: white;
  width: 100vw;
  height: 100vh;
  /* overflow: auto; */
`;

const Main = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100%;
  overflow-y: auto;

  .content {
    max-width: 100%;
    box-sizing: border-box;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  box-sizing: border-box;
  gap: 80px;
  height: fit-content;
  margin-top: 50px;
  padding-bottom: 60px;
  /* height: 100%; */

  label {
    color: #a3a3a3;
    font-weight: 600;
  }

  .bottomBox {
    display: grid;
    gap: 0.5rem;
  }

  .contentBox {
    border-radius: 0.5rem;
    color: white;
    cursor: pointer;
    text-align: left;
    border: none;
  }

  .inputBox {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #1f273a;
    background-color: transparent;
    outline: none;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
    padding: 1rem;
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  width: fit-content;
  flex-wrap: wrap;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    display: grid;
    grid-template-columns: auto;
    justify-self: center;
    place-content: center;
  }
`;

export default Staking;
